import {faFile, faFilePdf, faFileWord,
  faFileExcel, faFilePowerpoint, faFileImage,
  faExclamationTriangle, IconDefinition} from '@fortawesome/free-solid-svg-icons';
import {Subscription} from 'rxjs';

/**
 * Gets the file size in kilobytes.
 * The number is formatted with commas (thousands separator)
 * and a 'KB' suffix is added for easier readability.
 *
 * @param size size in bytes to format
 * @return string with the formatted file size
 */
function toKilobytesString(size: number): string {
  if (size <= 0) {
    return '0 KB';
  } else if (size <= 1024) {
    return '1 KB';
  } else {
    return Math.round(size / 1024).toLocaleString() + ' KB';
  }
}

/** The maximum size of each file in bytes. */
export const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10 MB

/**
 * The maximum size of each file in kilobytes.
 * The number is formatted with commas (thousands separator)
 * and a 'KB' suffix is added for easier readability.
 */
export const MAX_FILE_SIZE_STRING = toKilobytesString(MAX_FILE_SIZE);

/** The maximum number of files that may be added to each report. */
export const MAX_FILE_COUNT = 5;

export const FILE_TYPES = new Map([
  ['pdf', {name: 'application/pdf', icon: faFilePdf}],
  ['xls', {name: 'application/vnd.ms-excel', icon: faFileExcel}],
  ['xlsx', {name: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', icon: faFileExcel}],
  ['doc', {name: 'application/msword', icon: faFileWord}],
  ['docx', {name: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', icon: faFileWord}],
  ['ppt', {name: 'application/vnd.ms-powerpoint', icon: faFilePowerpoint}],
  ['pptx', {name: 'application/vnd.openxmlformats-officedocument.presentationml.presentation', icon: faFilePowerpoint}],
  ['jpeg', {name: 'image/jpeg', icon: faFileImage}],
  ['jpg', {name: 'image/jpeg', icon: faFileImage}],
  ['gif', {name: 'image/gif', icon: faFileImage}],
  ['png', {name: 'image/png', icon: faFileImage}],
]);

/** Set of approved MIME_TYPES for uploading. */
export const MIME_TYPES = new Set(Array.from(FILE_TYPES.values()).map(v => v.name));

/**
 * A map containing the approved MIME_TYPES for uploading along
 * with the icon that should be displayed in the UI.
 */
export const ICON_MAP = new Map(Array.from(FILE_TYPES.values()).map(v => [v.name, v.icon]));

export class Attachment {

  attachmentId: number;
  readonly blob: Blob;
  readonly name: string;
  readonly size: number;
  readonly type: string;
  readonly lastModified: number;
  readonly isSizeValid: boolean;
  readonly isTypeValid: boolean;
  thumbnail: string;
  bytesUploaded = 0;
  status: 'NEW'|'PENDING'|'UPLOADING'|'COMPLETE'|'FAILED';
  signature: string;
  private _subscription: Subscription;

  constructor(file: Attachment|File) {
    this.attachmentId = (file as Attachment).attachmentId;
    this.blob = file instanceof Blob ? file : undefined;
    this.name = file.name;
    this.size = file.size;
    this.type = file.type;
    this.lastModified = file.lastModified;

    // IE11 workaround. IE only has 'lastModifiedDate'.
    if (!this.lastModified && (file as any).lastModifiedDate) {
      this.lastModified = (file as any).lastModifiedDate.getTime();
    }

    // Validate
    this.isSizeValid = this.size <= MAX_FILE_SIZE;
    this.isTypeValid = MIME_TYPES.has(this.type);

    // If Blob is provided, then this is a newly attached local file.
    // Otherwise, it's a remote file loaded from a previously created report.
    if (this.blob) {
      this.generateThumbnail(this.blob).then(t => this.thumbnail = t);
      this.status = 'NEW';
    } else {
      this.status = 'COMPLETE';
    }
  }

  /**
   * Specifies whether the attachment has a valid size and type.
   * An attachment is still considered valid even if uploading
   * is incomplete or failed.
   */
  get isValid(): boolean {
    return this.isSizeValid && this.isTypeValid;
  }

  /**
   * Determines if the attachment has errors. This includes
   * both validation errors and whether the upload has failed
   * or not.
   */
  get hasErrors(): boolean {
    return !this.isValid || this.isFailed;
  }

  get icon(): IconDefinition {
    return this.hasErrors ? faExclamationTriangle : ICON_MAP.get(this.type) || faFile;
  }

  /**
   * Specifies whether the attachment is in 'NEW' status.
   * A 'NEW' attachment is one which has just been added,
   * but uploading has not started.
   */
  get isNew(): boolean {
    return this.status === 'NEW';
  }

  /**
   * Specifies whether the attachment is in 'PENDING' status.
   * A 'PENDING' attachment is one which is currently waiting
   * to receive an S3 URL from the Attachment Service before
   * uploading can begin.
   */
  get isPending(): boolean {
    return this.status === 'PENDING';
  }

  /**
   * Specifies whether the attachment is in 'UPLOADING' status.
   * An 'UPLOADING' attachment is one which has already received
   * an S3 URL from the Attachment Service and is in the process
   * of uploading to S3.
   */
  get isUploading(): boolean {
    return this.status === 'UPLOADING';
  }

  /**
   * Specifies whether the attachment is in 'COMPLETE' status.
   * A 'COMPLETE' attachment is one which has finished uploading
   * successfully.
   */
  get isComplete(): boolean {
    return this.status === 'COMPLETE';
  }

  /**
   * Specifies whether the attachment is in 'FAILED' status.
   * A 'FAILED' attachment is one which attempted to upload,
   * but failed to do so. The failure may have happened either
   * during the request for an S3 URL or during the actual
   * upload process.
   */
  get isFailed(): boolean {
    return this.status === 'FAILED';
  }

  /**
   * Specifies whether an attachment is in progress.
   * An attachment is considered in progress if it has a status of
   * 'NEW', 'PENDING', or 'UPLOADING'. This method is used to disable
   * the next button so that the user doesn't try to navigate away
   * from the page while an upload is still occurring.
   */
  get inProgress(): boolean {
    return this.isNew || this.isPending || this.isUploading;
  }

  /**
   * Obtains a string representing the total progress of the
   * upload. Example: '529 KB / 1,780 KB'.
   */
  get progressString(): string {
    return toKilobytesString(this.bytesUploaded) + ' / '
         + toKilobytesString(this.size);
  }

  get formattedSize(): string {
    return toKilobytesString(this.size);
  }

  set subscription(subscription: Subscription) {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
    this._subscription = subscription;
  }

  unsubscribe(): void {
    if (this._subscription) {
      this._subscription.unsubscribe();
      this._subscription = undefined;
    }
  }

  equals(other: Attachment): boolean {
    return other != null
        && this.name === other.name
        && this.type === other.type
        && this.size === other.size
        && this.lastModified === other.lastModified;
  }

  /**
   * Generates a thumbnail for an image file.
   *
   * This is done for performance reasons. Large image files may
   * lag slightly when previewing the image. It will also cut
   * down on excessive calls to S3 to download the image if the
   * preview is being generated from an already uploaded file.
   * The thumbnail can be generated once and cached for quick
   * access on mouseover again.
   *
   * This method takes an argument specifying how large (in pixels)
   * the largest dimension should be. For example, a 3840 x 2160
   * image has width as the largest dimension. The thumbnail will
   * limit width to that maximum size and scale the height proportionately.
   * We need to check whether width or height is larger so that
   * images with odd proportions don't create oversized thumbnails.
   * If the original image is smaller than dimensionSize, then the
   * original image's width and height are kept.
   *
   * @param blob
   *    The blob containing the original image data.
   * @param dimensionSize
   *    Size in pixels of the largest dimension. Default is 500
   * @param quality
   *    Specifies how high quality the resulting thumbnail should be
   *    with 0 as lowest and 1.0 as maximum quality. Default is 0.8
   */
  private async generateThumbnail(blob: Blob,
                                  dimensionSize: number = 500,
                                  quality: number = 0.8): Promise<string> {
    if (blob.type !== 'image/jpeg'
        && blob.type !== 'image/gif'
        && blob.type !== 'image/png') {
      return new Promise<string>(undefined);
    }
    return new Promise<string>(resolve => {
      const img = new Image();
      img.onload = () => {
        const maxDimension = Math.max(img.width, img.height);
        const scale = dimensionSize > maxDimension ? 1.0 : dimensionSize / maxDimension;
        const canvas = document.createElement('canvas');
        canvas.width = img.width * scale;
        canvas.height = img.height * scale;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        resolve(ctx.canvas.toDataURL(blob.type, quality));
      };
      const reader = new FileReader();
      reader.onload = (r: any) => img.src = r.target.result;
      reader.readAsDataURL(blob); // convert to base64 string
    });
  }

  toJSON() {
    return {
      attachmentId: this.attachmentId,
      name: this.name,
      size: this.size,
      type: this.type,
      signature: this.signature
    };
  }
}
