import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { AppComponent } from './app.component';
import { NcuiModule } from 'common-ui-components';
import { HttpClientModule } from '@angular/common/http';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { JurisdictionsComponent } from './jurisdictions/jurisdictions.component';
import { ReportingPersonComponent } from './reporting-person/reporting-person.component';
import { FraudTypeComponent } from './fraud-type/fraud-type.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { SubmitReportComponent } from './submit-report/submit-report.component';
import { routing, appRoutingProviders } from './app.routing';
import { SearchFraudComponent } from './search-fraud/search-fraud.component';
import { SwitchCocodeComponent } from './switch-cocode/switch-cocode.component';
import { SuspectsComponent } from './suspects/suspects.component';
import { NavigationComponent } from './shared/navigation/navigation.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SuspectDetailComponent } from './suspects/suspect-detail/suspect-detail.component';
import { NotOnlyWhitespaceDirective } from './shared/validation/not-only-whitespace.directive';
import {BsDatepickerModule, BsModalService, ModalModule, PopoverModule, ProgressbarModule} from 'ngx-bootstrap';
import { AkaDetailComponent } from './suspects/aka-detail/aka-detail.component';
import { ContactElectronicInfoComponent } from './shared/contact-electronic-info/contact-electronic-info.component';
import { AddressComponent } from './shared/address/address.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InvestigationSummaryComponent } from './investigation-summary/investigation-summary.component';
import { ClaimInformationComponent } from './claim-information/claim-information.component';
import { EvidenceTypeComponent } from './evidence-type/evidence-type.component';
import { NoFutureDateDirective } from './shared/validation/no-future-date.directive';
import { OtherAgenciesComponent } from './other-agencies/other-agencies.component';
import { OtherAgencyDetailsComponent } from './other-agencies/other-agency-details/other-agency-details.component';
import { NgxCurrencyModule } from 'ngx-currency';
import { ConfirmLeaveComponent } from './confirm-leave/confirm-leave.component';
import { AttachmentsComponent } from './attachments/attachments.component';
import {OAuthModule} from 'angular-oauth2-oidc';
import {environment} from '../environments/environment';
import { TimeoutComponent } from './timeout/timeout.component';
import {NgxLoadingModule} from 'ngx-loading';
import {DummyComponent} from './testing/dummy.component';
import {RouterLinkStubDirective} from './testing/router-stubs';
import { LandingComponent } from './landing/landing.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { FraudDescriptionComponent } from './fraud-description/fraud-description.component';
import { HtmlViewComponent } from './html-view/html-view.component';
import { UserSessionInactivePopupComponent } from './shared/user-session-inactive-popup/user-session-inactive-popup.component';
import { NgIdleModule } from '@ng-idle/core';
import { SessionService } from './shared/session.service';

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    NotOnlyWhitespaceDirective,
    JurisdictionsComponent,
    ReportingPersonComponent,
    FraudTypeComponent,
    WelcomeComponent,
    SubmitReportComponent,
    SearchFraudComponent,
    SwitchCocodeComponent,
    SuspectsComponent,
    SuspectDetailComponent,
    AkaDetailComponent,
    ContactElectronicInfoComponent,
    AddressComponent,
    AddressComponent,
    InvestigationSummaryComponent,
    ClaimInformationComponent,
    EvidenceTypeComponent,
    NoFutureDateDirective,
    ConfirmLeaveComponent,
    OtherAgenciesComponent,
    OtherAgencyDetailsComponent,
    AttachmentsComponent,
    TimeoutComponent,
    DummyComponent,
    RouterLinkStubDirective,
    LandingComponent,
    TermsOfUseComponent,
    FraudDescriptionComponent,
    HtmlViewComponent,
    UserSessionInactivePopupComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NcuiModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [environment.apiUrl],
        sendAccessToken: true
      }
    }),
    FontAwesomeModule,
    NgxCurrencyModule,
    NgxLoadingModule.forRoot({}),
    routing,
    AccordionModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    PopoverModule.forRoot(),
    ProgressbarModule.forRoot(),
    NgIdleModule.forRoot(),
  ],
  entryComponents: [ConfirmLeaveComponent, TimeoutComponent, UserSessionInactivePopupComponent],
  providers: [
    appRoutingProviders,
    BsModalService,
    SessionService
  ],
  exports: [NotOnlyWhitespaceDirective, NoFutureDateDirective],
  bootstrap: [AppComponent]
})
export class AppModule { }
