import {FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';

/**
 * @description
 * Applied to a FormGroup, this will validate that exactly
 * one of the listed FormControls contains a value.
 *
 * @usageNotes
 * ```typescript
 * this.formGroup = this.formBuilder.group({
 *   email: [''],
 *   phoneNumber: [''],
 *   faxNumber: [''],
 * }, exactlyOneRequiredValidator('email', 'phoneNumber', 'faxNumber'));
 * ```
 *
 * @param formNames a list of FormControls by name
 */
export function exactlyOneRequiredValidator(...formNames: string[]): ValidatorFn {
  return (control: FormGroup): ValidationErrors|null => {

    // Map the formNames to formControls, excluding any that don't exist.
    const forms = formNames.map(f => control.get(f)).filter(f => !!f);

    // If exactly one of the forms has a value,
    // remove the "exactlyOneRequired" error from any forms
    // which have it.
    if (forms.filter(f => f.value).length === 1) {
      forms.filter(f => f.errors && f.errors.exactlyOneRequired).forEach(f => {
        delete f.errors.exactlyOneRequired;
        f.updateValueAndValidity();
      });
      return null;
    } else {
      forms.forEach(f => f.setErrors({'exactlyOneRequired': true}));
      return {'exactlyOneRequired': true};
    }
  };
}
