<div class="row">
  <div class="col-2">
    <div class="row">
      <app-navigation [visibleItems]="visibleTabs"></app-navigation>
    </div>
  </div>
  <div class="col-10 pl-sm-5">
      <div class="form-group row text-center">
        <h2>Involved Entity/Fraud Suspect</h2>
      </div>
      <div id="startNewSuspect" class="form-group row">
        <div class="col-sm-8">Click the New button to add an Involved Entity/Fraud Suspect</div>
      </div>

      <div id="existingSuspects" *ngIf="suspects && suspects.length > 0" class="form-group row">
        <div class="col-sm-6 text-sm-center">OR</div>
        <br>
        <div class="col-sm-10">Select an existing Entity to update or add aliases or delete that record</div>
        <br>
        <br>

        <div class="col-sm-12">
          <ul class="nav flex-column">
            <li *ngFor="let suspect of suspects; let idx = index" class="nav-item">
              <div class="form-group row">
                <div class="col-sm-8"><a class="text-primary" [routerLink]="['./suspect', idx]">{{displaySuspectName(suspect.entityType, suspect.firstName, suspect.middleName, suspect.lastName, suspect.businessName)}}</a>
                </div>
                <div class="col-sm-2">
                  <button type="button" class="btn btn-secondary" (click)="removeSuspect(idx)" >
                    <fa-icon [icon]="faTrash"></fa-icon>
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <!--<ng-template #service_template>--><!--</ng-template>-->
        </div>
      </div>

    <div class="form-group row">
      <div class="col-sm-1">
        <button type="button" id="mainPage" (click)="startNewSuspect()" [disabled]="suspects && suspects.length >= 10" class="btn btn-success .navbar-right"> New </button>
      </div>
      <div class="col-sm-4 text-sm-center">
        <button type="button" id="suspectsLandingButton"  [disabled]="!suspects || suspects.length === 0"(click)="next()" class="btn btn-success .navbar-right">
          Next
        </button>
      </div>
    </div>
  </div>
</div>
