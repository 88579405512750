<div class="row">
  <div class="col-2">
    <div class="row">
      <ul>
        <li><a class="btn btn-default" routerLink="/welcome">Welcome</a></li>
        <li class="btn btn-primary active">Switch Cocode</li>
      </ul>
    </div>
  </div>
  <div class="col-10">

        <h4>Switch to Different Cocode</h4>
        <form  >


          <div class="form-group row">
            <div class="col">
              <button type="button" id="submitReportButton" class="btn btn-success .navbar-right" >Submit</button>
            </div>
          </div>
        </form>
  </div>
</div>

