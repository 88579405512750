import { Injectable } from '@angular/core';
import { Resolve, Router, ActivatedRoute, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { InsuranceDecode } from '../insurance-decode';
import { CommonService } from '../common.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InsuranceDecodeResolverService implements Resolve<InsuranceDecode[]> {

  constructor(private commonService: CommonService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<InsuranceDecode[]> | Observable<never> {
    return this.commonService.getInsuranceDecodes();
  }
}
