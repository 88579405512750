import {Address} from '../shared/address.model';
import {Vehicle} from './vehicle.model';
import {Employer} from './employer.model';


export class BaseParty {

    public partyId: number;
    public firstName: string;
    public middleName: string;
    public lastName: string;
    public gender: string;
    public ssn: string;
    public birthDate: string;
    public driverLicenseNumber: string;
    public driverLicenseState: string;
    public occupation: string;
    public tin: string;
    public ein: string;
    public npi: string;
    public email: string;
    public businessName: string;
    public address: Address;
    public homePhone: string;
    public cellPhone: string;
    public busPhone: string;
    public busPhoneExt: string;
    public fax: string;

    public vehicle: Vehicle;
    public employer: Employer;

    constructor() { }
}
