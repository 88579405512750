<div class="row" xmlns="http://www.w3.org/1999/html">
  <div class="col-2">
    <div class="row">
      <app-navigation [visibleItems]="visibleTabs"></app-navigation>
    </div>
  </div>
  <div class="col-10 pl-sm-5">
    <div class="row">
      <div class="col text-center">
        <h2>Involved Entity/Fraud Suspect Details</h2>
        <!--<div class="alert alert-danger row" *ngIf="errorMessage">{{errorMessage}}</div>-->
        <p>Fields marked with an <span class="red-text">*</span> are required</p>
        <p>Provide the requested information about the Entity or Person suspected of Fraud.</p>
      </div>
    </div>
      <form *ngIf="detailForm" [formGroup]="detailForm">
        <div class="form-group row" >
          <label class="col-form-label col-sm-4 text-right">Entity suspected of fraud is: <span class="red-text">*</span></label>
          <div class="col-sm-8">
            <input id="businessEntity" (click)="setToEntityType('Business')" type="radio" value="Business" formControlName="entityType" /> Business
            <input id="personEntity" (click)="setToEntityType('Person')" type="radio" value="Person" formControlName="entityType" /> Individual
            <div *ngIf="formErrors.entityType" class="alert alert-danger">
              {{formErrors.entityType}}
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="partyType" class="col-form-label col-sm-4 text-right">Party Type: <span class="red-text">*</span></label>
          <div class="col-sm-8">
            <select title="Party Type" [formControl]="detailForm.controls['partyType']" (change)="changePartyOtherDescAttribute()" required class="form-control" id="partyType" name="partyType">
              <option *ngFor="let pDecode of partyDecodes" [value]="pDecode.partyCode">{{pDecode.partyName}}</option>
            </select>
            <div *ngIf="formErrors.partyType" class="alert alert-danger">
              {{formErrors.partyType}}
            </div>
          </div>
        </div>
        <div *ngIf="'OTHER' === detailForm.controls['partyType'].value" class="form-group row">
          <label for="partyTypeOtherDesc" class="col-form-label col-sm-4 text-right">Description if Other: <span class="red-text">*</span></label>
          <div class="col-sm-8">
            <input type="text" title="Party Other Desc" [formControl]="detailForm.controls['partyTypeOtherDesc']" required class="form-control" id="partyTypeOtherDesc" name="partyTypeOtherDesc" />
            <div *ngIf="formErrors.partyTypeOtherDesc" class="alert alert-danger">
              {{formErrors.partyTypeOtherDesc}}
            </div>
          </div>
        </div>

        <div *ngIf="this.detailForm.get('entityType').value">

          <accordion>
            <accordion-group (isOpenChange)="suspectInfoCollapseFlag = !suspectInfoCollapseFlag">
              <span accordion-heading id="suspectSpan">
                  <fa-icon [icon]="suspectInfoCollapseFlag ? faPlus : faMinus"></fa-icon>&nbsp;Suspect Information <span class="red-text">*</span> Contains Required Fields
                </span>

        <div class="form-group row">
          <div class="col-sm-11 ml-auto">

            <div class="form-group row">
              <label for="subjectOfInvestigation" class="col-form-label col-sm-3 text-right">Subject of Investigation <span class="red-text">*</span></label>
              <div class="col-sm-9">
                <select title="Subject of Investigation" formControlName="subjectOfInvestigation" required class="form-control" id="subjectOfInvestigation" name="subjectOfInvestigation">
                  <option [ngValue]="null" disabled hidden>Choose One</option>
                  <option value="Y">Yes</option>
                  <option value="N">No</option>
                  <option value="U">Unknown</option>
                </select>
              </div>
            </div>

            <div *ngIf="this.detailForm.get('entityType').value === 'Business'" class="form-group row">
              <label for="businessName" class="col-form-label col-sm-3 text-right">Business Name: <span class="red-text">*</span></label>
              <div class="col-sm-9">
                <input type="text" title="Business Name" [formControl]="detailForm.controls['businessName']" required class="form-control" id="businessName" name="businessName" />
                <div *ngIf="formErrors.businessName" class="alert alert-danger">
                  {{formErrors.businessName}}
                </div>
              </div>
            </div>







            <div *ngIf="this.detailForm.get('entityType').value === 'Person'" class="form-group row">
            <label for="firstName" class="col-form-label col-sm-3 text-right">First Name: <span class="red-text">*</span></label>
            <div class="col-sm-9">
            <input type="text" title="First Name" [formControl]="detailForm.controls['firstName']"  class="form-control" id="firstName" name="firstName" />
            <div *ngIf="formErrors.firstName" class="alert alert-danger">
            {{formErrors.firstName}}
            </div>
            </div>
            </div>
            <div *ngIf="this.detailForm.get('entityType').value === 'Person'" class="form-group row">
            <label for="middleName" class="col-form-label col-sm-3 text-right">Middle Name:</label>
            <div class="col-sm-9">
            <input type="text" title="Middle Name" [formControl]="detailForm.controls['middleName']"  class="form-control" id="middleName" name="middleName" />
              <div *ngIf="formErrors.middleName" class="alert alert-danger">
                {{formErrors.middleName}}
              </div>
            </div>
            </div>

            <div *ngIf="this.detailForm.get('entityType').value === 'Person'" class="form-group row">
            <label for="lastName" class="col-form-label col-sm-3 text-right">Last Name: <span class="red-text">*</span></label>
            <div class="col-sm-9">
            <input type="text" title="Last Name" [formControl]="detailForm.controls['lastName']"  class="form-control" id="lastName" name="lastName" />
            <div *ngIf="formErrors.lastName" class="alert alert-danger">
            {{formErrors.lastName}}
            </div>
            </div>
            </div>

            <div *ngIf="this.detailForm.get('entityType').value === 'Person'" class="form-group row">
            <label class="col-form-label col-sm-3 text-right">Gender:</label>
            <div class="col-sm-9">
            <input id="gMale" type="radio" value="M" formControlName="gender"> Male
            <input id="gFemale" type="radio" value="F" formControlName="gender"> Female
            <input id="gUnknown" type="radio" value="U" formControlName="gender"> Unknown
            </div>
            </div>

            <div *ngIf="this.detailForm.get('entityType').value === 'Person'" class="form-group row">
            <label for="ssn" class="col-form-label col-sm-3 text-right">SSN:</label>
            <div class="col-sm-9">
            <input type="text" title="Social Security Number" [formControl]="detailForm.controls['ssn']" (input)="formatSsn()" placeholder="XXX-XX-XXXX" class="form-control" id="ssn" name="ssn" />
            <div *ngIf="formErrors.ssn" class="alert alert-danger">
            {{formErrors.ssn}}
            </div>
            </div>
            </div>

            <div *ngIf="this.detailForm.get('entityType').value === 'Person'" class="form-group row">
              <label for="birthDate" class="col-form-label col-sm-3 text-right">Date of Birth:</label>
              <div class="col-sm-7">
                <input id="birthDate" type="text" class="form-control mb-3" [minDate]="minDate" [maxDate]="maxDate" [bsConfig]="bsConfig" placeholder="MM/DD/YYYY" bsDatepicker formControlName="birthDate" #d="bsDatepicker" triggers=""/>
                <pre *ngIf="detailForm.value.birthDate" class="code-preview">{{detailForm.value.birthDate | date}}</pre>
              </div>
              <div class="col-sm-2" style="padding-left: 0">
                <button type="button" class="btn btn-secondary" (click)="d.toggle()"  [attr.aria-expanded]="d.isOpen">
                  <fa-icon [icon]="faCalendar"></fa-icon>
                </button>
              </div>
            </div>

            <div *ngIf="this.detailForm.get('entityType').value === 'Person' && isIndustry" class="form-group row">
              <label for="driverLicenseNumber" class="col-form-label col-sm-3 text-right">Driver License Number:</label>
              <div class="col-sm-4">
                <input type="text" title="Driver License Number" [formControl]="detailForm.controls['driverLicenseNumber']"  class="form-control" id="driverLicenseNumber" #driverLicenseNumber name="driverLicenseNumber" />
                <div *ngIf="formErrors.driverLicenseNumber" class="alert alert-danger">
                {{formErrors.driverLicenseNumber}}
                </div>
              </div>
              <div>
                <select title="Driver License State" [required]="driverLicenseNumber.value.length>0" [formControl]="detailForm.controls['driverLicenseState']" class="form-control" id="driverLicenseState" name="driverLicenseState">
                  <option [ngValue]='' disabled hidden>Choose One</option>
                  <option *ngFor="let juris of jurisdictions" [value]="juris.jurisdictionId">{{juris.jurisdictionId}}</option>
                </select>
              </div>
            </div>

            <div *ngIf="this.detailForm.get('entityType').value === 'Person'" class="form-group row">
            <label for="occupation" class="col-form-label col-sm-3 text-right">Occupation:</label>
            <div class="col-sm-9">
            <input type="text" title="Occupation" [formControl]="detailForm.controls['occupation']"  class="form-control" id="occupation" name="occupation" />
              <div *ngIf="formErrors.occupation" class="alert alert-danger">
                {{formErrors.occupation}}
              </div>
            </div>
            </div>

            <div *ngIf="this.detailForm.get('entityType').value === 'Person' && isIndustry" class="form-group row">
            <label for="tin" class="col-form-label col-sm-3 text-right">TIN:</label>
            <div class="col-sm-9">
            <input type="text" title="TIN" [formControl]="detailForm.controls['tin']"  class="form-control" id="tin" name="tin" />
              <div *ngIf="formErrors.tin" class="alert alert-danger">
                {{formErrors.tin}}
              </div>
            </div>
            </div>

            <div *ngIf="this.detailForm.get('entityType').value === 'Person' && isIndustry" class="form-group row">
            <label for="ein" class="col-form-label col-sm-3 text-right">EIN:</label>
            <div class="col-sm-9">
            <input type="text" title="EIN" [formControl]="detailForm.controls['ein']"  class="form-control" id="ein" name="ein" />
              <div *ngIf="formErrors.ein" class="alert alert-danger">
                {{formErrors.ein}}
              </div>
            </div>
            </div>

            <div *ngIf="this.detailForm.get('entityType').value === 'Person' && isIndustry" class="form-group row">
            <label for="npi" class="col-form-label col-sm-3 text-right">NPI:</label>
            <div class="col-sm-9">
            <input type="text" title="NPI" [formControl]="detailForm.controls['npi']"  class="form-control" id="npi" name="npi" />
              <div *ngIf="formErrors.npi" class="alert alert-danger">
                {{formErrors.npi}}
              </div>
            </div>
            </div>
            <app-address
              [formGroup]="detailForm"
              [requiredFields]="['addressType','address1','city','state','zip']"
              [errorMessages]="validationService.getValidationMessages()"
              [addressDecodes]="addressDecodes"
              [jurisdictions]="jurisdictions">
            </app-address>
            <fieldset>
              <legend>Contact Info</legend>
              <app-contact-electronic-info
                [formGroup]="detailForm"
                [errorMessages]="validationService.getValidationMessages()">
              </app-contact-electronic-info>
            </fieldset>
          </div>
        </div>
            </accordion-group>
            <accordion-group *ngIf="this.detailForm.get('entityType').value === 'Person' && isIndustry" (isOpenChange)="employerInfoCollapseFlag = !employerInfoCollapseFlag">
              <span accordion-heading id="employerSpan">
                  <fa-icon [icon]="employerInfoCollapseFlag ? faPlus : faMinus"></fa-icon>&nbsp;Suspect Employer Information
                </span>
            <div class="col-sm-11 ml-auto">
              <div class="form-group row">
                <label for="employerName" class="col-form-label col-sm-3 text-right">Employer Name:</label>
                <div class="col-sm-9">
                  <input type="text" title="Employer Name" [formControl]="detailForm.controls['employerName']"  class="form-control" id="employerName" name="employerName" />
                  <div *ngIf="formErrors.employerName" class="alert alert-danger">
                    {{formErrors.employerName}}
                  </div>
                </div>
              </div>
              <app-address
                [isAddressTypeHidden]="true"
                [prefix]="'employer'"
                [formGroup]="detailForm"
                [errorMessages]="validationService.getValidationMessages()"
                [addressDecodes]="addressDecodes"
                [jurisdictions]="jurisdictions">
              </app-address>
              <div class="form-group row">
                <label for="employerPhone" class="col-form-label col-sm-3 text-right">Business Phone:</label>
                <div class="col-sm-4">
                  <input type="text" title="Employer Phone" [formControl]="detailForm.controls['employerPhone']"  class="form-control" id="employerPhone" name="employerPhone" />
                  <div *ngIf="formErrors.employerPhone" class="alert alert-danger">
                    {{formErrors.employerPhone}}
                  </div>
                </div>
                <label for="employerPhoneExt" class="col-form-label col-sm-1 text-right" style="padding: 0">Ext:</label>
                <div class="col-sm-4">
                  <input type="text" title="Employer Phone Extention" [formControl]="detailForm.controls['employerPhoneExt']"  class="form-control" id="employerPhoneExt" name="employerPhoneExt" />
                  <div *ngIf="formErrors.employerPhoneExt" class="alert alert-danger">
                    {{formErrors.employerPhoneExt}}
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label for="employerFax" class="col-form-label col-sm-3 text-right">Fax Number:</label>
                <div class="col-sm-9">
                  <input type="text" title="Employer Fax" [formControl]="detailForm.controls['employerFax']"  class="form-control" id="employerFax" name="employerFax" />
                  <div *ngIf="formErrors.employerFax" class="alert alert-danger">
                    {{formErrors.employerFax}}
                  </div>
                </div>
              </div>
            </div>
            </accordion-group>

<!--aka section-->
          <accordion-group *ngIf="disableEntityType" (isOpenChange)="aliasInfoCollapseFlag = !aliasInfoCollapseFlag">
              <span accordion-heading id="aliasSpan">
                  <fa-icon [icon]="aliasInfoCollapseFlag ? faPlus : faMinus"></fa-icon>&nbsp;Alias
                </span>
          <div class="col-sm-11 ml-auto">
            <div id="startNewAka" class="form-group row">
              <div class="col-sm-10">Click the New button to add an alias</div>
              <div class="col-sm-2 ml-auto">
                <button type="button" id="mainPage" (click)="startNewAlias()" [disabled]="akas && akas.length >= 20"
                        class="btn btn-success .navbar-right"> New </button>
              </div>
            </div>

            <div id="existingAkas" *ngIf="akas && akas.length > 0" class="form-group row">
              <div class="col-sm-12 text-left">
                <p class="text-center">OR</p>
                <p>Click on a link for an existing alias to update or remove that record</p>
              </div>

              <div class="col-sm-12 text-left">
                <ul class="nav flex-column">
                  <li *ngFor="let aka of akas; let idx = index" class="nav-item" >
                    <div class="form-group row">
                      <div class="col-sm-8"><a class="text-primary" [routerLink]="['./aka', this.detailForm.get('entityType').value, idx]">{{displayAkaName(aka.firstName, aka.middleName, aka.lastName, aka.businessName)}}</a>
                      </div>
                      <div class="col-sm-2">
                        <button type="button" class="btn btn-secondary" (click)="removeAka(idx)" >
                          <fa-icon [icon]="faTrash"></fa-icon>
                        </button>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          </accordion-group>
<!--vehicle section-->
          <accordion-group *ngIf="isIndustry" (isOpenChange)="vehicleInfoCollapseFlag = !vehicleInfoCollapseFlag">
              <span accordion-heading id="vehicleSpan">
                  <fa-icon [icon]="vehicleInfoCollapseFlag ? faPlus : faMinus"></fa-icon>&nbsp;Vehicle Information
                </span>
        <div class="form-group row">
          <div *ngIf="!vehicleInfoCollapseFlag" class="col-sm-11 ml-auto">
            <div class="form-group row">
              <label for="vehicleYear" class="col-form-label col-sm-3 text-right">Vehicle Year:</label>
              <div class="col-sm-9">
                <input type="text" title="Vehicle Year" [formControl]="detailForm.controls['vehicleYear']"  class="form-control" id="vehicleYear" name="vehicleYear" />
                <div *ngIf="formErrors.vehicleYear" class="alert alert-danger">
                  {{formErrors.vehicleYear}}
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label for="vehicleMake" class="col-form-label col-sm-3 text-right">Vehicle Make:</label>
              <div class="col-sm-9">
                <input type="text" title="Vehicle Make" [formControl]="detailForm.controls['vehicleMake']"  class="form-control" id="vehicleMake" name="vehicleMake" />
                <div *ngIf="formErrors.vehicleMake" class="alert alert-danger">
                  {{formErrors.vehicleMake}}
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label for="vehicleModel" class="col-form-label col-sm-3 text-right">Vehicle Model:</label>
              <div class="col-sm-9">
                <input type="text" title="Vehicle Model" [formControl]="detailForm.controls['vehicleModel']"  class="form-control" id="vehicleModel" name="vehicleModel" />
                <div *ngIf="formErrors.vehicleModel" class="alert alert-danger">
                  {{formErrors.vehicleModel}}
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label for="vehicleVin" class="col-form-label col-sm-3 text-right">VIN:</label>
              <div class="col-sm-9">
                <input type="text" title="VIN" [formControl]="detailForm.controls['vehicleVin']"  class="form-control" id="vehicleVin" name="vehicleVin" />
                <div *ngIf="formErrors.vehicleVin" class="alert alert-danger">
                  {{formErrors.vehicleVin}}
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label for="vehicleLicPlate" class="col-form-label col-sm-3 text-right">License Plate:</label>
              <div class="col-sm-5">
                <input type="text" title="License Plate" [formControl]="detailForm.controls['vehicleLicPlate']"  class="form-control" id="vehicleLicPlate" name="vehicleLicPlate" />
                <div *ngIf="formErrors.vehicleLicPlate" class="alert alert-danger">
                  {{formErrors.vehicleLicPlate}}
                </div>
              </div>
              <div class="col-sm-4">
                <select title="Vehicle License State"  [formControl]="detailForm.controls['vehicleLicState']"   class="form-control" id="vehicleLicState" name="vehicleLicState">
                  <option [ngValue]='' disabled hidden>Choose One</option>
                  <option *ngFor="let juris of jurisdictions" [value]="juris.jurisdictionId">{{juris.jurisdictionId}}</option>
                </select>
                <div *ngIf="formErrors.vehicleLicState" class="alert alert-danger">
                  {{formErrors.vehicleLicState}}
                </div>
              </div>
            </div>
          </div>
        </div>
          </accordion-group>

<!--reported injury section-->
          <accordion-group (isOpenChange)="reportedInjuryCollapseFlag = !reportedInjuryCollapseFlag" *ngIf="isIndustry">
              <span accordion-heading id="injurySpan">
                  <fa-icon [icon]="reportedInjuryCollapseFlag ? faPlus : faMinus"></fa-icon>&nbsp;Reported Injury
                </span>
        <div class="form-group row">
          <div *ngIf="!reportedInjuryCollapseFlag" class="col-sm-11 ml-auto">
            <textarea type="text" title="Reported Injury" formControlName="reportedInjury" #reportedInjury class="form-control" maxLength="1000" id="reportedInjury" name="reportedInjury" ></textarea>
            <small class="form-text text-muted"><code>{{1000 - reportedInjury.value?.length}}</code> of <code>1000</code> characters remains</small>
            <div *ngIf="formErrors.reportedInjury" class="alert alert-danger">
              {{formErrors.reportedInjury}}
            </div>
          </div>
        </div>
          </accordion-group>
  <!--comments section      -->
          <accordion-group (isOpenChange)="commentsCollapseFlag = !commentsCollapseFlag">
              <span accordion-heading id="commentsSpan">
                  <fa-icon [icon]="commentsCollapseFlag ? faPlus : faMinus"></fa-icon>&nbsp;Comments
                </span>
        <div class="form-group row">
          <div *ngIf="!commentsCollapseFlag" class="col-sm-11 ml-auto">
            <textarea type="text" title="Comments" formControlName="comments" #comments class="form-control" maxLength="1000" id="comments" name="comments" ></textarea>
            <small class="form-text text-muted"><code>{{1000 - comments.value?.length}}</code> of <code>1000</code> characters remains</small>
            <div *ngIf="formErrors.comments" class="alert alert-danger">
              {{formErrors.comments}}
            </div>
          </div>
        </div>
          </accordion-group>
          </accordion>
<!--action section-->
        <div class="alert alert-danger row" *ngIf="errorMessage">{{errorMessage}}</div>
          <br/>
        <div class="form-group row">
          <div class="col-sm-3 text-right">
            <button type="button" id="CancelSuspectButton" (click)="cancelSuspect(detailForm.value)" class="btn btn-success .navbar-right"> Cancel </button>
          </div>
          <div *ngIf="idxString === null" class="col-sm-3 text-right">
            <button type="button" id="addSuspectButton" (click)="addSuspect(detailForm.value)" class="btn btn-success .navbar-right" [disabled]="!detailForm.valid"> Add </button>
          </div>
          <div *ngIf="idxString" class="col-sm-3 text-right">
            <button type="button" id="updateSuspectButton" (click)="addSuspect(detailForm.value)" class="btn btn-success .navbar-right" [disabled]="!detailForm.valid"> Update </button>
          </div>
        </div>
        </div>
    </form>
  </div>
</div>
