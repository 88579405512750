<div class="row">
  <div class="col-2">
    <div class="row">
      <app-navigation [visibleItems]="visibleTabs"></app-navigation>
    </div>
  </div>

  <div class="col-10">

    <h2>Description of Suspected Fraud</h2>

    <div *ngIf="errorMessage" class="row alert alert-danger">{{errorMessage}}</div>

    <div *ngIf="form" class="row">
      <div class="col-12">
        <div [formGroup]="form">
          <div formGroupName="lossDetails">
            <div class="form-group row">
              <label for="lossDate" class="col-sm-4 col-form-label text-right">
                Exact Date of Incident if known:
              </label>
              <div class="col-sm-4">
                <input id="lossDate" title="Complete Date" type="text" class="form-control ng-invalid"
                       [bsConfig]="bsConfig" placeholder="MM/DD/YYYY" bsDatepicker formControlName="lossDate" #d="bsDatepicker" triggers=""/>
                <div id="lossDateError" *ngIf="shouldShowError(lossDate)" class="alert alert-danger">
                  <div *ngIf="lossDate.errors">
                    <div *ngFor="let error of errors(lossDate)">{{error}}</div>
                  </div>
                </div>
              </div>
              <div class="col-sm-2" style="padding-left: 0">
                <button id="exactDateCalendarButton" type="button" class="btn btn-secondary"
                        (click)="d.toggle()" [attr.aria-expanded]="d.isOpen">
                  <fa-icon [icon]="faCalendar"></fa-icon>
                </button>
              </div>
            </div>

            <div class="form-group row">
              <label for="approxLossDate" style="padding-top: 0" class="col-sm-4 col-form-label text-right">
                Approximate Date of Incident if exact date is unknown:
              </label>
              <div class="col-sm-5">
                <input id="approxLossDate" name="approxLossDate" formControlName="approxLossDate" type="text"
                       class="form-control ng-invalid" autocomplete="off"/>
                <div id="approxLossDateError" *ngIf="shouldShowError(approxLossDate)" class="alert alert-danger">
                  <div *ngIf="approxLossDate.errors">
                    <div *ngFor="let error of errors(approxLossDate)">{{ error }}</div>
                  </div>
                </div>
              </div>
            </div>


            <div id="lossDetailsError" *ngIf="lossDetails.errors?.exactlyOneRequired && (lossDetails.dirty || lossDetails.touched)"
                 class="alert alert-danger">
              Either the exact date of the incident or an approximate date of the incident is required, but not both.
            </div>
          </div>

          <div formGroupName="financialDetails">
            <div class="form-group row">
              <label for="suspectedFraudAmt" class="col-sm-4 col-form-label text-right">Suspected Fraud Amount:</label>
              <div class="col-sm-6">
                <input id="suspectedFraudAmt" currencyMask [options]="{ prefix: '$ ', allowNegative: 'false' , thousands: ',', nullable: 'true', precision: '0'}" name="suspectedFraudAmt" formControlName="suspectedFraudAmt" type="text" class="form-control" autocomplete="off"/>
                <div id="suspectedFraudAmtError" *ngIf="shouldShowError(suspectedFraudAmt)" class="alert alert-danger">
                  <div *ngIf="suspectedFraudAmt.errors">
                    <div *ngFor="let error of errors(suspectedFraudAmt)">{{error}}</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label for="lossRangeId" class="col-sm-4 col-form-label text-right">Potential Loss Amount:</label>
              <div class="col-sm-5">
                <select title="Potential Loss Amount" formControlName="lossRangeId" class="form-control" id="lossRangeId" name="lossRangeId">
                  <option [ngValue]="null" >[Select Loss Range]</option>
                  <option value="1">$1 - $5,000 </option>
                  <option value="2">$5,001 - $25,000</option>
                  <option value="3">$25,001 - $75,000</option>
                  <option value="4">$75,000+</option>
                  <option value="99">Unable to estimate</option>
                </select>
              </div>
            </div>

            <div id="financialDetailsError" *ngIf="financialDetails.errors?.exactlyOneRequired && (financialDetails.dirty || financialDetails.touched)"
                 class="alert alert-danger">
              Either suspected fraud amount or potential loss amount is required, but not both.
            </div>
          </div>

          <div class="form-group row">
            <label for="overview" class="control-label col-sm-12 text-left">Please provide an overview of the fraud activity(4000 characters maximum)</label>
            <textarea rows="8" type="text" title="Fraud Overview" formControlName="overview"  class="form-control" id="overview" name="overview" ></textarea>
            <p id="remainingCount" class="form-text text-muted col-sm-12"><code>{{overviewLength}}</code> of <code>4000</code> characters</p>
            <div id="overviewError" *ngIf="shouldShowError(overview)" class="alert alert-danger">
              <div *ngIf="overview.errors">
                <div *ngFor="let error of errors(overview)">{{ error }}</div>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-lg-12 text-center">
              <button type="button" id="fraudDescriptionSaveButton" [disabled]="hasError()" (click)="save()" class="btn btn-success .navbar-right" >Next</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
