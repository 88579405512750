// import {ValidatorFn, AbstractControl, FormGroup, ValidationErrors} from '@angular/forms';
import {ValidatorFn, FormGroup, ValidationErrors} from '@angular/forms';
import * as moment from 'moment';

export const searchFraudValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  const submitDateFrom = control.get('submitDateFrom');
  const submitDateTo = control.get('submitDateTo');
  if (submitDateFrom.value === null || submitDateFrom.value === undefined || submitDateTo.value === null || submitDateTo.value === undefined) {
    return null;
  }

  if (!(submitDateFrom.value instanceof Date) || !(submitDateFrom.value instanceof Date)) {
    return null;
  }
  const isValid = moment(submitDateFrom.value).isSameOrBefore(moment(submitDateTo.value));
  return isValid ? null : { 'beingEndDates': true };
};

