import {Component, OnInit, ViewChild} from '@angular/core';
import {CommonService} from '../shared/common.service';
import {faCheckCircle, faExclamationCircle, faFilePdf, faCircle} from '@fortawesome/free-solid-svg-icons';
import {Router} from '@angular/router';
import {BsModalService, ModalDirective, ModalOptions} from 'ngx-bootstrap';
import {OfrsReportKey} from '../shared/ofrs-report-key.model';
import {Observable, Subject} from 'rxjs';
import {Jurisdiction} from '../shared/jurisdiction.model';
import {ConfirmLeaveComponent} from '../confirm-leave/confirm-leave.component';
import {OfrsReport} from '../shared/ofrs-report.model';

@Component({
  selector: 'app-submit-report',
  templateUrl: './submit-report.component.html',
  styleUrls: ['./submit-report.component.css']
})
export class SubmitReportComponent implements OnInit {
  visibleTabs: string[];
  errorMessage: string;
  jurisdictions: Jurisdiction[] = [];
  ofrsReportKey: OfrsReportKey;
  isIndustry: boolean;
  ofrsReport: OfrsReport;

  @ViewChild('printReportModal', {static: false}) printReportModal: ModalDirective;
  faCheckCircle = faCheckCircle;
  faExclamationCircle = faExclamationCircle;
  faFilePdf = faFilePdf;
  faCircle = faCircle;
  isSubmitting = false;
  isPdfDownloading = false;
  reportId = 0;
  transactionId = 0;

  constructor(private commonService: CommonService,
              private router: Router, private modalService: BsModalService) {
    this.visibleTabs = this.commonService.getAvailableTabs().slice();
    this.ofrsReport = this.commonService.getOfrsReport();
    this.isIndustry = this.commonService.getOfrsReport().ofrsReportKey.reportType === 'I';
  }

  ngOnInit() {
    if (this.visibleTabs.indexOf('submitReportNav') === -1) {
      this.visibleTabs.push('submitReportNav');
    }
    const selectedStates = this.commonService.getOfrsReport().states;
    this.commonService.getJurisdictions().subscribe((data: Jurisdiction[]) => {
      for (const j of data) {
        if (selectedStates.indexOf(j.jurisdictionId) >= 0) {
          this.jurisdictions.push(j);
        }
      }
    });
  }
  htmlview(): void {
    localStorage.setItem('state', JSON.stringify(this.ofrsReport));
    window.open(this.router.parseUrl('/html-view').toString(), '_blank');
  }
  save(): void {
    this.commonService.setAvailableTabs(this.visibleTabs);
    const mos = new ModalOptions();
    mos.initialState = {
      title: 'Are you sure?',
      msg: 'By submitting this report you are alerting the Jurisdictions - Are you sure?',
      yesBtnName: 'Yes',
      noBtnName: 'No'
    };
    const subject = new Subject<boolean>();
    const modal = this.modalService.show(ConfirmLeaveComponent,  mos);
    modal.content.subject = subject;
    modal.content.subject.asObservable().subscribe(value1 => {
      if (value1 === true) {
        this.isSubmitting = true;
        console.log(JSON.stringify(this.commonService.getOfrsReport()));
        this.commonService.postOfrsReport(this.commonService.getOfrsReport()).subscribe(
          response => {
            this.isSubmitting = false;
            this.ofrsReportKey = response;
            this.commonService.getOfrsReport().setOfrsReportKey(this.ofrsReportKey);
            this.reportId = response.reportId;
            this.transactionId = response.transactionId;
            // this.commonService.setAvailableTabs(['welcome']);
            this.printReportModal.show();
            }, error => {
            this.errorMessage = error.message;
            this.isSubmitting = false;
          }
        );
      } else {
        return;
      }}
    );
  }

  downloadPDF(): void {
    this.isPdfDownloading = true;
    this.commonService.downloadPDFReport(this.transactionId).subscribe(blob => {
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(blob);
      a.download = `ofrs-report-${this.reportId}.pdf`;
      a.click();
    }, error => {
      console.error(error);
    }, () => {
      this.isPdfDownloading = false;
    });
  }

  close(): void {
    this.commonService.resetOfrsReport();
    this.router.navigate(['']);
  }
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }

}
