import { Component, OnInit } from '@angular/core';
import {OtherAgencyDetails} from '../shared/other-agency-details.model';
import {faTrash } from '@fortawesome/free-solid-svg-icons';
import {CommonService} from '../shared/common.service';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup} from '@angular/forms';
import {BsModalService, ModalOptions} from 'ngx-bootstrap';
import {Subject} from 'rxjs';
import {ConfirmLeaveComponent} from '../confirm-leave/confirm-leave.component';


@Component({
  selector: 'app-other-agencies',
  templateUrl: './other-agencies.component.html',
  styleUrls: ['./other-agencies.component.css']
})
export class OtherAgenciesComponent implements OnInit {
  otherAgencies: OtherAgencyDetails[];
  noOtherAgenciesFlag: boolean;
  visibleTabs: string[];
  faTrash = faTrash;
  canNavigateWithoutConfirm  = false;
  agencyIndex: number;
  formGroup: FormGroup;

  constructor(private commonService: CommonService, private formBuilder: FormBuilder, private modalService: BsModalService, private router: Router) {
    this.visibleTabs = this.commonService.getAvailableTabs().slice();
    this.otherAgencies = this.commonService.getOfrsReport().otherAgencies;
    this.noOtherAgenciesFlag = this.commonService.getOfrsReport().ofrsReportKey.reportId != null
                  && (this.otherAgencies == null || this.otherAgencies.length === 0) ? true : false;
  }

  ngOnInit() {
    if (this.visibleTabs.indexOf('otherAgenciesNav') === -1) {
      this.visibleTabs.push('otherAgenciesNav');
    }
    this.createOtherAgenciesForm();
  }

  createOtherAgenciesForm(): void {
    this.formGroup = this.formBuilder.group({
      noneOtherAgencies: [this.noOtherAgenciesFlag && (this.otherAgencies == null || this.otherAgencies.length === 0), ]
    });
    if (this.otherAgencies !== null && this.otherAgencies.length > 0) {
      this.formGroup.get('noneOtherAgencies').disable();
    }
  }

  newOtherAgency(): void {
    this.router.navigate(['other-agencies/other-agency']);
  }

  next(): void {
    /*this.commonService.getOfrsReport().noOtherAgenciesFlag = this.noOtherAgenciesFlag;*/
    this.commonService.setAvailableTabs(this.visibleTabs);
    this.router.navigate(['submit-report']);
  }

  checkboxStateChanged(state: boolean): void {
    this.noOtherAgenciesFlag = state;
  }

  removeOtherAgencyDetails(agencyIndex: number) {
    const mos = new ModalOptions();
    mos.initialState = {
      // title: 'Are you sure?',
      msg: 'Are you sure you want to remove this Agency?',
      yesBtnName: 'Yes',
      noBtnName: 'No'
    };
    const subject = new Subject<boolean>();
    const modal = this.modalService.show(ConfirmLeaveComponent,  mos);
    modal.content.subject = subject;
    modal.content.subject.asObservable().subscribe(value1 => {
      if (value1 === true) {
        this.canNavigateWithoutConfirm = true;
        this.otherAgencies.splice(+this.agencyIndex, 1);
        this.commonService.getOfrsReport().otherAgencies = this.otherAgencies;
        if (this.otherAgencies === null || this.otherAgencies.length === 0) {
          this.formGroup.get('noneOtherAgencies').enable();
        } else {
          this.formGroup.get('noneOtherAgencies').disable();
        }
        this.router.navigate(['other-agencies']);
      } else {
        return;
      }}
    );
  }

}

