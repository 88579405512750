import {Company} from './company.model';

export class User {
  loginId: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  address1?: string;
  address2?: string;
  city?: string;
  zip?: string;
  county?: string;
  state?: string;
  title?: string;
  department?: string;
  email?: string;
  busPhone?: string;
  homePhone?: string;
  cellPhone?: string;
  busPhoneExt?: string;
  fax?: string;
  roles: string[];
  companies: Company[];

  constructor(obj?: any) {
    Object.assign(this, obj);
  }

  get allCocodes(): number[] {
    const cocodes = [];
    for (const company of this.companies) {
      if (company.cocode) {
        cocodes.push(company.cocode);
      }
    }
    return cocodes;
  }

}
