import { Component, OnInit } from '@angular/core';
import {OtherAgencyDetails} from '../../shared/other-agency-details.model';
import {CommonService} from '../../shared/common.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {notOnlyWhitespaceValidator} from '../../shared/validation/not-only-whitespace.directive';
import {numericValidator} from '../../shared/validation/numeric-validator';
import {Jurisdiction} from '../../shared/jurisdiction.model';
import {Address} from '../../shared/address.model';
import {OfrsIndustryValidationService} from '../../shared/validation/validation.service';
import {AgencyTypeDecode} from '../../shared/agencyType-decode.model';
import {OfrsReport} from '../../shared/ofrs-report.model';
import {Observable} from 'rxjs';
import {BsModalService} from 'ngx-bootstrap';
import {charLength} from '../../shared/validation/char-length-validator';

@Component({
  selector: 'app-other-agency-details',
  templateUrl: './other-agency-details.component.html',
  styleUrls: ['./other-agency-details.component.css']
})
export class OtherAgencyDetailsComponent implements OnInit {
  formGroup: FormGroup;
  canNavigateWithoutConfirm  = false;
  detailForm: FormGroup;
  form: FormGroup;
  jurisdictions: Jurisdiction[];
  agencyTypeDecodes: AgencyTypeDecode[];
  errorMessage: string;
  address: Address;
  stored = false;
  otherAgencies: OtherAgencyDetails[];
  agencyIndex: number;
  alphaDashPeriodPattern = '^[a-zA-Z .\'-]+$';
  phonePattern = '^\\(?([0-9]{3})\\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$';
  zipPattern = '^\\d{5}(?:[-]\\d{4})?$';
  alphaDashPattern = '^[a-zA-Z-]+$';
  alphaNumericPattern = '^[a-zA-Z0-9]+$';
  workingDetailsIndex = 0;
  workingDetails: OtherAgencyDetails;
  visibleTabs: string[];

  constructor(
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    public validationService: OfrsIndustryValidationService,
    private destinationRouter: ActivatedRoute,
    private router: Router,
    private modalService: BsModalService
  ) {
    this.visibleTabs = this.commonService.getAvailableTabs().slice();
  }

  ngOnInit() {
    if (this.visibleTabs.indexOf('otherAgenciesNav') === -1) {
      this.visibleTabs.push('otherAgenciesNav');
    }
    this.destinationRouter.data.subscribe(
      (data: {
        ofrsReport: OfrsReport
      }) => {
      if (data.ofrsReport.otherAgencies != null) {
        this.otherAgencies = data.ofrsReport.otherAgencies;
      } else {
        this.router.navigate(['welcome']);
      }
    });
    if (this.destinationRouter.snapshot.paramMap.get('idx') && this.destinationRouter.snapshot.paramMap.get('idx') != null) {
      this.agencyIndex = +this.destinationRouter.snapshot.paramMap.get('idx');
    }
    // Set existing user
    if ( this.agencyIndex >= 0 ) {
      this.workingDetailsIndex = this.agencyIndex;
      this.workingDetails = this.otherAgencies[this.workingDetailsIndex];
      if (this.workingDetails.address == null) {
        this.workingDetails.address = new Address();
      }
    } else {
      this.workingDetails = new OtherAgencyDetails();
    }
    this.getAgencyTypes();
    this.getJurisdictions();
    this.createDetailsForm();
  }

  private getAgencyTypes() {
    this.commonService.getAgencyTypes()
      .subscribe(data => {
        this.agencyTypeDecodes = data;
      });
  }

  private getJurisdictions(): void {
    this.commonService.getJurisdictions()
      .subscribe(data => this.jurisdictions = data);
  }

  createDetailsForm(): void {
    this.detailForm = this.formBuilder.group({
      agencyType: [this.workingDetails.agencyType, [Validators.required]],
      agencyTypeOtherDesc: [this.workingDetails.agencyTypeOtherDesc, [charLength(70)]],
      agencyName: [this.workingDetails.agencyName, [Validators.required, notOnlyWhitespaceValidator(), charLength(70)]],
      agencyCase: [this.workingDetails.agencyCase, [Validators.pattern(this.alphaNumericPattern), notOnlyWhitespaceValidator(), charLength(25)]],
      agencyContact: [this.workingDetails.agencyContact, [ Validators.pattern(this.alphaDashPeriodPattern), notOnlyWhitespaceValidator(), charLength(70)]],
      address1: [this.workingDetails.address.address1, [notOnlyWhitespaceValidator(), charLength(70)]],
      address2: [this.workingDetails.address.address2, [notOnlyWhitespaceValidator(), charLength(70)]],
      city: [this.workingDetails.address.city, [notOnlyWhitespaceValidator(), charLength(70)]],
      county: [this.workingDetails.address.county, [Validators.pattern(this.alphaDashPattern), notOnlyWhitespaceValidator(), charLength(25)]],
      state: [this.workingDetails.address.state, [notOnlyWhitespaceValidator(), charLength(2)]],
      zip: [this.workingDetails.address.zip, [Validators.pattern(this.zipPattern), notOnlyWhitespaceValidator()]],
      email: [this.workingDetails.email, [Validators.email, charLength(70)]],
      busPhone: [this.workingDetails.busPhone, [Validators.pattern(this.phonePattern), notOnlyWhitespaceValidator()]],
      busPhoneExt: [this.workingDetails.busPhoneExt, [numericValidator(), notOnlyWhitespaceValidator(), charLength(10)]],
      fax: [this.workingDetails.fax, [Validators.pattern(this.phonePattern), notOnlyWhitespaceValidator()]]
    });
  }

  changeAgencyOtherDescAttribute(): void {
    if ('OTHER' === this.detailForm.get('agencyType').value) {
      this.detailForm.controls['agencyTypeOtherDesc'].setValidators(
        [Validators.required, notOnlyWhitespaceValidator(), charLength(70)]);
    } else {
      this.detailForm.controls['agencyTypeOtherDesc'].clearValidators();
      this.detailForm.get('agencyTypeOtherDesc').setValue('');
    }
    this.detailForm.controls['agencyTypeOtherDesc'].updateValueAndValidity();
  }

  cancelOtherAgencyDetails(): void {
    this.canNavigateWithoutConfirm = false;
    this.router.navigate(['other-agencies']);
  }

  addOtherAgencyDetails(): void {
    this.errorMessage = '';

    if (this.otherAgencies.length === 0) { // user clicks New to add first suspect
      this.otherAgencies = [new OtherAgencyDetails()];
      this.workingDetailsIndex = 0;
    } else if (this.agencyIndex == null) { // user clicks New while there are existing suspects
      this.otherAgencies.push(new OtherAgencyDetails());
      this.workingDetailsIndex = this.otherAgencies.length - 1;
    }
    this.otherAgencies[this.workingDetailsIndex].agencyType = this.agencyType.value;
    if (this.agencyTypeOtherDesc) {
      this.otherAgencies[this.workingDetailsIndex].agencyTypeOtherDesc = this.agencyTypeOtherDesc.value;
    }
    this.otherAgencies[this.workingDetailsIndex].agencyName = this.agencyName.value;
    this.otherAgencies[this.workingDetailsIndex].agencyCase = this.agencyCase.value;
    // this.otherAgencies[this.workingDetailsIndex].firstName = this.firstName.value;
    // this.otherAgencies[this.workingDetailsIndex].lastName = this.lastName.value;
    this.otherAgencies[this.workingDetailsIndex].agencyContact = this.agencyContact.value;
    this.otherAgencies[this.workingDetailsIndex].address.address1 = this.address1.value;
    this.otherAgencies[this.workingDetailsIndex].address.address2 = this.address2.value;
    this.otherAgencies[this.workingDetailsIndex].address.city = this.city.value;
    this.otherAgencies[this.workingDetailsIndex].address.county = this.county.value;
    this.otherAgencies[this.workingDetailsIndex].address.state = this.state.value;
    this.otherAgencies[this.workingDetailsIndex].address.zip = this.zip.value;
    this.otherAgencies[this.workingDetailsIndex].address.addressType = 'None';
    this.otherAgencies[this.workingDetailsIndex].email = this.email.value;
    this.otherAgencies[this.workingDetailsIndex].busPhone = this.busPhone.value;
    this.otherAgencies[this.workingDetailsIndex].busPhoneExt = this.busPhoneExt.value;
    this.otherAgencies[this.workingDetailsIndex].fax = this.fax.value;
    this.commonService.getOfrsReport().otherAgencies = this.otherAgencies;
    this.commonService.setAvailableTabs(this.visibleTabs);
    this.canNavigateWithoutConfirm = true;
    this.router.navigate(['other-agencies']);
  }
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.canNavigateWithoutConfirm || !this.detailForm.dirty) {
      return true;
    }
    return false;
  }

  errors(control: AbstractControl): string[] {
    const errors: string[] = [];
    Object.keys(control.errors).forEach((key: string) => {
      if (key === 'required') {
        errors.push(' is required');
      }
      if (key === 'pattern') {
        if (this.getFormControlName(control) === 'fax' || this.getFormControlName(control) === 'busPhone') {
          errors.push(' can only be a valid phone number format');
        } else if (this.getFormControlName(control) === 'agencyCase') {
          errors.push(' can only be alphanumeric');
        } else {
          errors.push(' can only contain alpha characters, apostrophes, hyphens, spaces and periods');
        }
      }
      if (key === 'email') {
        errors.push(' can only be a valid format');
      }
      if (key === 'whitespace') {
        errors.push(' only white space is not allowed');
      }
      if (key === 'maxlength') {
        errors.push(` cannot be longer than ${control.errors[key].requiredLength} characters`);
      }
      if (key === 'NaN') {
        errors.push(' must be numeric');
      }
    });
    return errors;
  }

  getFormControlName(control: AbstractControl): string | null {
    const formGroup = control.parent.controls;
    return Object.keys(formGroup).find(name => control === formGroup[name]) || null;
  }

  get agencyType() { return this.detailForm.get('agencyType'); }
  get agencyTypeOtherDesc() { return this.detailForm.get('agencyTypeOtherDesc'); }
  get agencyName() { return this.detailForm.get('agencyName'); }
  get agencyCase() { return this.detailForm.get('agencyCase'); }
  // get firstName() { return this.detailForm.get('firstName'); }
  // get lastName() { return this.detailForm.get('lastName'); }
  get agencyContact() { return this.detailForm.get('agencyContact'); }
  get address1() { return this.detailForm.get('address1'); }
  get address2() { return this.detailForm.get('address2'); }
  get city() { return this.detailForm.get('city'); }
  get county() { return this.detailForm.get('county'); }
  get state() { return this.detailForm.get('state'); }
  get zip() { return this.detailForm.get('zip'); }
  get email() { return this.detailForm.get('email'); }
  get busPhone() { return this.detailForm.get('busPhone'); }
  get busPhoneExt() { return this.detailForm.get('busPhoneExt'); }
  get fax() { return this.detailForm.get('fax'); }

  saveButtonText(): string {
    if (this.agencyIndex >= 0) {
      return 'Update';
    }
    return 'Add';
  }

}
