import { Component, OnInit } from '@angular/core';

import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap';
import {CommonService} from '../shared/common.service';
import { Router} from '@angular/router';
import {notOnlyWhitespaceValidator} from '../shared/validation/not-only-whitespace.directive';
import {Observable} from 'rxjs';
import {faCalendar} from '@fortawesome/free-solid-svg-icons';
import {noFutureDateValidator} from '../shared/validation/no-future-date.directive';
import {exactlyOneRequiredValidator} from '../shared/validation/exactly-one-required-validator';
import {ClaimInformation} from '../shared/claim-information.model';
import {InvestigationSummary} from '../shared/investigation-summary.model';
import {charLength} from '../shared/validation/char-length-validator';

@Component({
  selector: 'app-fraud-description',
  templateUrl: './fraud-description.component.html',
  styleUrls: ['./fraud-description.component.css']
})
export class FraudDescriptionComponent implements OnInit {

  readonly bsConfig = Object.assign(new BsDatepickerConfig(), {
    containerClass: 'theme-dark-blue',
    maxDate: new Date(),
    showWeekNumbers: false
  });

  // icon
  faCalendar = faCalendar;

  investigationSummary: InvestigationSummary;
  claimInformation: ClaimInformation;
  form: FormGroup;
  errorMessage: string;
  stored: boolean;
  visibleTabs: string[];

  constructor(private commonService: CommonService, private formBuilder: FormBuilder,
              private router: Router) {

    this.visibleTabs = this.commonService.getAvailableTabs().slice();
  }

  ngOnInit(): void {
    if (this.visibleTabs.indexOf('fraudDescriptionNav') === -1) {
      this.visibleTabs.push('fraudDescriptionNav');
    }
    this.investigationSummary = new InvestigationSummary(this.commonService.getOfrsReport().investigationSummary);
    this.claimInformation = new ClaimInformation(this.commonService.getOfrsReport().claimInformation);
    this.createFraudDescriptionForm();
    this.stored = false;
  }

  createFraudDescriptionForm(): void {
    this.form = this.formBuilder.group({
      lossDetails: this.formBuilder.group({
        lossDate: [this.claimInformation.lossDate, [noFutureDateValidator()]],
        approxLossDate: [this.claimInformation.approxLossDate, [notOnlyWhitespaceValidator(), charLength(200)]]
      }, { validators: exactlyOneRequiredValidator('lossDate', 'approxLossDate') }),
      financialDetails: this.formBuilder.group({
        suspectedFraudAmt: [this.claimInformation.suspectedFraudAmt, Validators.max(999_999_999) ],
        lossRangeId: [this.claimInformation.lossRangeId, ]
      }, { validators: exactlyOneRequiredValidator('suspectedFraudAmt', 'lossRangeId') }),
      overview: [this.investigationSummary.overview, [notOnlyWhitespaceValidator(), Validators.required, charLength(4000)]],
    }, );
  }

  get lossDetails() { return this.form.get('lossDetails'); }
  get lossDate() { return this.lossDetails.get('lossDate'); }
  get approxLossDate() { return this.lossDetails.get('approxLossDate'); }

  get financialDetails() { return this.form.get('financialDetails'); }
  get suspectedFraudAmt() { return this.financialDetails.get('suspectedFraudAmt'); }
  get lossRangeId() { return this.financialDetails.get('lossRangeId'); }

  get overview() {return this.form.get('overview'); }
  get overviewLength(): number {
    const overview = this.form.get('overview').value;
    return !overview ? 0 : new TextEncoder().encode(overview).length;
  }

  errors(control: AbstractControl): string[] {
    const errors: string[] = [];
    Object.keys(control.errors).forEach((key: string) => {
      switch (key) {
        case 'required': errors.push('overview is required'); break;
        case 'max': errors.push(' cannot be greater than $999,999,999'); break;
        case 'maxlength': errors.push(` cannot be longer than ${control.errors[key].requiredLength} characters`); break;
        case 'pattern': errors.push(' can only contain alpha-numeric characters and dashes'); break;
        case 'bsDate': errors.push(' is invalid'); break;
        case 'noFutureDate': errors.push(' cannot be a date in the future'); break;
        case 'whitespace': errors.push(' only white space is not allowed'); break;
        case 'NaN': errors.push(' must be numeric'); break;
      }
    });
    return errors;
  }

  shouldShowError(control: AbstractControl): boolean {
    return control && control.errors
      && this.errors(control).length > 0
      && (control.touched || control.dirty);
  }

  hasError(): boolean {
    return this.form.invalid;
  }

  save(): void {
    const claimInformation = new ClaimInformation(Object.assign({},
      this.lossDetails.value,
      this.financialDetails.value,
    ));
    this.commonService.getOfrsReport().claimInformation = claimInformation;
    this.investigationSummary.overview = this.overview.value;
    this.commonService.getOfrsReport().investigationSummary = this.investigationSummary;
    this.commonService.setAvailableTabs(this.visibleTabs);
    this.stored = true;
    this.router.navigate(['attachments']);
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.stored || !this.form.dirty) {
      return true;
    }
    return false;
  }

}
