import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { ClaimStatusDecode } from '../claim-status-decode';
import { CommonService } from '../common.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClaimStatusDecodeResolverService implements Resolve<ClaimStatusDecode[]> {

  constructor(private commonService: CommonService, private router: Router) {}

  resolve(): Observable<ClaimStatusDecode[]> | Observable<never> {
    return this.commonService.getClaimStatusDecodes();
  }
}
