<div class="row">
  <div id="navigation" class="col-2">
    <div class="row">
      <app-navigation [visibleItems]="visibleTabs"></app-navigation>
    </div>
  </div>

      <div *ngIf="internalJurisdictions && internalJurisdictions !== null && internalJurisdictions.length > 0" class="col-10 pl-sm-5">
        <div class="row">
          <div class="col text-center">
            <h2>Select the Jurisdictions to receive your Fraud Report.</h2>
          </div>
        </div>

        <div class="alert alert-danger row" *ngIf="errorMessage">{{errorMessage}}</div>

        <div class="row">
          <div class="col-6 text-center">
            <span>Participating Jurisdictions</span>
            <form [formGroup]="jurisdictionsFormGroup" class="border border-dark">
              <div class="row">
                <div class="col text-center">
                  <p>Only jurisdictions that accept reports from the NAIC Online Fraud Reporting System (OFRS) are available for selection in the list below.</p>
                </div>
              </div>
              <div class="form-group row">
                <label class="control-label col-sm-4 text-right">OFRS Jurisdictions</label>
                <div class="col-sm-7">
                  <select title="Jurisdictions" formControlName="jurisdictions"
                          required multiple class="form-control" id="jurisdictions" name="jurisdictions">
                    <option *ngFor="let j of internalJurisdictions" [value]="j.jurisdictionId">{{j.displayStateName}}</option>
                  </select>
                </div>
              </div>
              <div class="row">
                <div class="col text-center">
                  <p class="small">(Hold down the CTRL key to select multiple jurisdictions.)</p>
                </div>
              </div>
              <div class="form-group row">
                <div class="col">
                  <button type="button" id="jurisdictionsSaveButton" (click)="save(jurisdictionsFormGroup.value)" class="btn btn-success .navbar-right" [disabled]="!jurisdictionsFormGroup.valid">Next</button>
                </div>
              </div>

            </form>
          </div>
          <div class="col-6 text-center">
            <span>Non-Participating Jurisdictions</span>
            <div  class="border border-dark">
              <div class="row">
                <div class="col text-center">
                  <p>To file your report with a non-OFRS participating jurisdiction select one of the following links.
                     You will be routed to that jurisdiction's Fraud Reporting System.</p>
                </div>
              </div>

              <div class="row" id="nonIndusUsedJuris">
                <ng-container *ngFor="let j of externalJurisdictions">
                  <a class="text-primary" target="_blank"
                     href="{{ofrsReport.ofrsReportKey.reportType === 'I' ? j.industryUrl : j.consumerUrl}}">{{j.displayStateName}}</a><br />
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
</div>
