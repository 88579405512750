<div class="row">
  <div class="col-2">
    <div class="row">
      <app-navigation [visibleItems]="visibleTabs"></app-navigation>
    </div>
  </div>

  <div class="col-10">

    <h2>Claim and Incident Information</h2>

    <div *ngIf="errorMessage" class="row alert alert-danger">{{errorMessage}}</div>

    <div *ngIf="form" class="row">
      <div class="col-12">
        <div [formGroup]="form">
          <accordion [isAnimated]="true">
            <accordion-group [(isOpen)]="claimDetailsOpen">
              <button id="claimDetailsAccordion" class="btn btn-link btn-block clearfix" accordion-heading type="button">
                <div class="pull-left float-left">
                  <fa-icon [icon]="claimDetailsOpen ? faMinus : faPlus"></fa-icon>
                  <span class="ml-2">Claim Details</span>
                </div>
              </button>
              <div formGroupName="claimDetails">
                <div class="form-group row">
                  <label for="insuranceTypeId" class="col-sm-4 col-form-label text-right">Insurance Type: <span class="red-text">*</span></label>
                  <div class="col-sm-5">
                    <select title="Insurance Type" formControlName="insuranceTypeId" required class="form-control" id="insuranceTypeId" name="insuranceTypeId">
                      <option *ngFor="let insuranceDecode of insuranceDecodes" [value]="insuranceDecode.insuranceTypeId">{{ insuranceDecode.insuranceName }}</option>
                    </select>
                    <div *ngIf="insuranceTypeId.invalid && (insuranceTypeId.dirty || insuranceTypeId.touched)" id="insuranceTypeIdError" class="alert alert-danger">
                      <div *ngIf="insuranceTypeId.errors">
                        <div *ngFor="let error of errors(insuranceTypeId)">Insurance Type{{ error }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="claimStatus" class="col-sm-4 col-form-label text-right">Claim Status: <span class="red-text">*</span></label>
                  <div class="col-sm-5">
                    <select title="Claim Status" formControlName="claimStatus" required class="form-control" id="claimStatus" name="claimStatus">
                      <option *ngFor="let claimStatus of claimStatusDecodes" [value]="claimStatus.claimStatusTypeId">{{ claimStatus.claimStatusName }}</option>
                    </select>
                    <div id="claimStatusError" *ngIf="claimStatus.invalid && (claimStatus.dirty || claimStatus.touched)" class="alert alert-danger">
                      <div *ngIf="claimStatus.errors">
                        <div *ngFor="let error of errors(claimStatus)">Claim Status{{ error }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="policyNumber" class="col-sm-4 col-form-label text-right">Policy Number: <span class="red-text">*</span></label>
                  <div class="col-sm-5">
                    <input id="policyNumber" name="policyNumber" formControlName="policyNumber" required type="text" class="form-control" />
                    <div id="policyNumberError" *ngIf="policyNumber.invalid && (policyNumber.dirty || policyNumber.touched)" class="alert alert-danger">
                      <div *ngIf="policyNumber.errors">
                        <div *ngFor="let error of errors(policyNumber)">Policy Number{{ error }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="claimNumber" class="col-sm-4 col-form-label text-right">Claim Number: <span class="red-text">*</span></label>
                  <div class="col-sm-5">
                    <input id="claimNumber" name="claimNumber" formControlName="claimNumber" required type="text" class="form-control" />
                    <div id="claimNumberError" *ngIf="claimNumber.invalid && (claimNumber.dirty || claimNumber.touched)" class="alert alert-danger">
                      <div *ngIf="claimNumber.errors">
                        <div *ngFor="let error of errors(claimNumber)">Claim Number{{ error }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="companyCaseNumber" class="col-sm-4 col-form-label text-right">Company Case Number:</label>
                  <div class="col-sm-5">
                    <input id="companyCaseNumber" name="companyCaseNumber" formControlName="companyCaseNumber" type="text" class="form-control" />
                    <div id="companyCaseNumberError" *ngIf="companyCaseNumber.invalid && (companyCaseNumber.dirty || companyCaseNumber.touched)" class="alert alert-danger">
                      <div *ngIf="companyCaseNumber.errors">
                        <div *ngFor="let error of errors(companyCaseNumber)">Company Case Number{{ error }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="civilLitigationPending" class="col-sm-4 col-form-label text-right">Civil Litigation Pending:</label>
                  <div class="col-sm-5">
                    <select title="Civil Litigation Pending" formControlName="civilLitigationPending" class="form-control" id="civilLitigationPending" name="civilLitigationPending">
                      <option value="Y">Yes</option>
                      <option value="N">No</option>
                      <option value="U">Unknown</option>
                    </select>
                    <div id="civilLitigationPendingError" *ngIf="civilLitigationPending.invalid && (civilLitigationPending.dirty || civilLitigationPending.touched)" class="alert alert-danger">
                      <div *ngIf="civilLitigationPending.errors">
                        <div *ngFor="let error of errors(civilLitigationPending)">Civil Litigation Pending{{ error }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </accordion-group>
            <accordion-group [(isOpen)]="lossInjuryDetailsOpen">
              <button id="lossInjuryDetailsAccordion" class="btn btn-link btn-block clearfix" accordion-heading type="button">
                <div class="pull-left float-left">
                  <fa-icon [icon]="lossInjuryDetailsOpen ? faMinus : faPlus"></fa-icon>
                  <span class="ml-2">Loss/Injury Details</span>
                </div>
              </button>
              <div formGroupName="lossDetails">

                <div class="form-group row">
                  <label for="lossDate" class="col-sm-4 col-form-label text-right">
                    Exact Date of Incident if known:
                  </label>
                  <div class="col-sm-4">
                    <input id="lossDate" title="Complete Date" type="text" class="form-control ng-invalid"
                           [bsConfig]="bsConfig" placeholder="MM/DD/YYYY" bsDatepicker formControlName="lossDate" #d="bsDatepicker" triggers=""/>
                    <div id="lossDateError" *ngIf="shouldShowError(lossDate)" class="alert alert-danger">
                      <div *ngIf="lossDate.errors">
                        <div *ngFor="let error of errors(lossDate)">{{error}}</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-2" style="padding-left: 0">
                    <button id="exactDateCalendarButton" type="button" class="btn btn-secondary"
                            (click)="d.toggle()" [attr.aria-expanded]="d.isOpen">
                      <fa-icon [icon]="faCalendar"></fa-icon>
                    </button>
                  </div>
                </div>

                <div class="form-group row">
                  <label for="approxLossDate" style="padding-top: 0" class="col-sm-4 col-form-label text-right">
                    Approximate Date of Incident if exact date is unknown:
                  </label>
                  <div class="col-sm-5">
                    <input id="approxLossDate" name="approxLossDate" formControlName="approxLossDate" type="text"
                           class="form-control ng-invalid" autocomplete="off"/>
                    <div id="approxLossDateError" *ngIf="shouldShowError(approxLossDate)" class="alert alert-danger">
                      <div *ngIf="approxLossDate.errors">
                        <div *ngFor="let error of errors(approxLossDate)">{{ error }}</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="lossDetailsError" *ngIf="lossDetails.errors?.exactlyOneRequired && (lossDetails.dirty || lossDetails.touched)"
                     class="alert alert-danger">
                  Either the exact date of the incident or an approximate date of the incident is required, but not both.
                </div>

                <label>Location of Loss or Injury Event</label>
                <app-address
                  [formGroup]="location"
                  [isAddressTypeHidden]="true"
                  [labelWidth]="4"
                  [colWidth]="5"
                  [errorMessages]="validationService.getValidationMessages()"
                  [jurisdictions]="jurisdictions">
                </app-address>
              </div>
            </accordion-group>
            <accordion-group [(isOpen)]="serviceOpen">
              <button id="serviceAccordion" class="btn btn-link btn-block clearfix" accordion-heading type="button">
                <div class="pull-left float-left">
                  <fa-icon [icon]="serviceOpen ? faMinus : faPlus"></fa-icon>
                  <span class="ml-2">Dates of Service</span>
                </div>
              </button>
              <div class="form-group row">
                <div class="col-sm-12 text-center">
                  <button id="addServiceBtn" type="button" class="btn btn-primary" (click)="openModal(service_template)">Add a Service</button></div>
                <div class="col-sm-12 text-left">
                  <ul class="nav flex-column">
                    <li *ngFor="let service of claimInformation.services.slice().reverse() let idx = index" class="nav-item" >
                      <div class="form-group row">
                        <div class="col-sm-8"><a class="text-primary" (click)="openExistingService(service, claimInformation.services.length - idx -1); openModal(service_template)">{{service.serviceDesc.substr(0,40)}}</a></div>
                        <div class="col-sm-4">
                          <button type="button" class="btn btn-secondary" (click)="trashService(claimInformation.services.length - idx -1)" >
                            <fa-icon [icon]="faTrash"></fa-icon>
                          </button>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </accordion-group>
            <accordion-group [(isOpen)]="procedureOpen">
              <button id="procedureAccordion" class="btn btn-link btn-block clearfix" accordion-heading type="button">
                <div class="pull-left float-left">
                  <fa-icon [icon]="procedureOpen ? faMinus : faPlus"></fa-icon>
                  <span class="ml-2">Procedure</span>
                </div>
              </button>
              <div class="form-group row">
                <div class="col-sm-12 text-center">
                  <button id="addprocedureBtn" type="button" class="btn btn-primary" (click)="openModal(procedure_template)">Add a Procedure</button></div>
                <div class="col-sm-12 text-center">
                  <ul class="nav flex-column">
                    <li *ngFor="let procedure of claimInformation.procedures.slice().reverse() let idx = index" class="nav-item" >
                      <div class="form-group row">
                        <div class="col-sm-6"><a class="text-primary text-lg-right" (click)="openExistingProcedure(procedure, claimInformation.procedures.length - idx -1); openModal(procedure_template)">{{procedure.procedureCode}}    {{procedure.procedureType}}</a></div>
                        <div class="col-sm-6">
                          <button type="button" class="btn btn-secondary" (click)="trashProcedure(claimInformation.procedures.length - idx -1)" >
                            <fa-icon [icon]="faTrash"></fa-icon>
                          </button>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </accordion-group>
            <accordion-group [(isOpen)]="financialDetailsOpen">
              <button id="financialDetailsAccordion" class="btn btn-link btn-block clearfix" accordion-heading type="button">
                <div class="pull-left float-left">
                  <fa-icon [icon]="financialDetailsOpen ? faMinus : faPlus"></fa-icon>
                  <span class="ml-2">Financial Details</span>
                </div>
              </button>
              <div formGroupName="financialDetails">
                <div class="form-group row">
                  <label for="approxReserveAmt" class="col-sm-4 col-form-label text-right">Approximate Reserve Amount:</label>
                  <div class="col-sm-6">
                    <input id="approxReserveAmt" currencyMask [options]="{ prefix: '$ ', allowNegative: 'false' , thousands: ',', nullable: 'true', precision: '0'}" name="approxReserveAmt" formControlName="approxReserveAmt" type="text" class="form-control" autocomplete="off"/>
                    <div id="approxReserveAmtError" *ngIf="approxReserveAmt.invalid && (approxReserveAmt.dirty || approxReserveAmt.touched)" class="alert alert-danger">
                      <div *ngIf="approxReserveAmt.errors">
                        <div *ngFor="let error of errors(approxReserveAmt)">Approximate Reserve Amount{{ error }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="billedAmt" class="col-sm-4 col-form-label text-right">Billed Amount:</label>
                  <div class="col-sm-6">
                    <input id="billedAmt" currencyMask [options]="{ prefix: '$ ', allowNegative: 'false' , thousands: ',', nullable: 'true', precision: '0'}" name="billedAmt" formControlName="billedAmt" type="text" class="form-control" autocomplete="off"/>
                    <div id="billedAmtError" *ngIf="billedAmt.invalid && (billedAmt.dirty || billedAmt.touched)" class="alert alert-danger">
                      <div *ngIf="billedAmt.errors">
                        <div *ngFor="let error of errors(billedAmt)">Billed Amount{{ error }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="suspectedFraudAmt" class="col-sm-4 col-form-label text-right">Suspected Fraud Amount:</label>
                  <div class="col-sm-6">
                    <input id="suspectedFraudAmt" currencyMask [options]="{ prefix: '$ ', allowNegative: 'false' , thousands: ',', nullable: 'true', precision: '0'}" name="suspectedFraudAmt" formControlName="suspectedFraudAmt" type="text" class="form-control" autocomplete="off"/>
                    <div id="suspectedFraudAmtError" *ngIf="suspectedFraudAmt.invalid && (suspectedFraudAmt.dirty || suspectedFraudAmt.touched)" class="alert alert-danger">
                      <div *ngIf="suspectedFraudAmt.errors">
                        <div *ngFor="let error of errors(suspectedFraudAmt)">Suspected Fraud Amount{{ error }}</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label for="lossRangeId" class="col-sm-4 col-form-label text-right">Potential Loss Amount:</label>
                  <div class="col-sm-5">
                    <select title="Potential Loss Amount" formControlName="lossRangeId" class="form-control" id="lossRangeId" name="lossRangeId">
                      <option [ngValue]="null" disabled hidden>[Select Loss Range]</option>
                      <option value="1">$1 - $5,000 </option>
                      <option value="2">$5,001 - $25,000</option>
                      <option value="3">$25,001 - $75,000</option>
                      <option value="4">$75,000+</option>
                      <option value="99">Unable to estimate</option>
                    </select>
                    <div id="lossRangeIdError" *ngIf="lossRangeId.invalid && (lossRangeId.dirty || lossRangeId.touched)" class="alert alert-danger">
                      <div *ngIf="lossRangeId.errors">
                        <div *ngFor="let error of errors(lossRangeId)">Potential Loss Amount{{ error }}</div>
                      </div>
                    </div>
                  </div>
                </div>


                <div class="form-group row">
                  <label for="approxPaidAmt" class="col-sm-4 col-form-label text-right">Approximate Amount Paid:</label>
                  <div class="col-sm-6">
                    <input id="approxPaidAmt" currencyMask [options]="{ prefix: '$ ', allowNegative: 'false' , thousands: ',', nullable: 'true', precision: '0'}" name="approxPaidAmt" formControlName="approxPaidAmt" type="text" class="form-control" autocomplete="off"/>
                    <div id="approxPaidAmtError" *ngIf="approxPaidAmt.invalid && (approxPaidAmt.dirty || approxPaidAmt.touched)" class="alert alert-danger">
                      <div *ngIf="approxPaidAmt.errors">
                        <div *ngFor="let error of errors(approxPaidAmt)">Approximate Amount Paid{{ error }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="paidDate" class="col-sm-4 col-form-label text-right">Date Paid:</label>
                  <div class="col-sm-6">
                    <input type="text"
                           id="paidDate"
                           name="paidDate"
                           formControlName="paidDate"
                           #dppaid="bsDatepicker"
                           class="form-control"
                           placeholder="MM/DD/YYYY"
                           bsDatepicker
                           placement="top"
                           [bsConfig]="bsConfig">
                    <div id="paidDateError" *ngIf="paidDate.invalid && (paidDate.dirty || paidDate.touched)" class="alert alert-danger">
                      <div *ngIf="paidDate.errors">
                        <div *ngFor="let error of errors(paidDate)">Date Paid{{ error }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-2">
                    <button type="button" class="btn btn-secondary" (click)="dppaid.toggle()" [attr.aria-expanded]="dppaid.isOpen">
                      <fa-icon [icon]="faCalendar"></fa-icon>
                    </button>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="approxSettleAmt" class="col-sm-4 col-form-label text-right">Approximate Settlement Amount:</label>
                  <div class="col-sm-6">
                    <input id="approxSettleAmt" currencyMask [options]="{ prefix: '$ ', allowNegative: 'false' , thousands: ',', nullable: 'true', precision: '0'}" name="approxSettleAmt" formControlName="approxSettleAmt" type="text" class="form-control" autocomplete="off"/>
                    <div id="approxSettleAmtError" *ngIf="approxSettleAmt.invalid && (approxSettleAmt.dirty || approxSettleAmt.touched)" class="alert alert-danger">
                      <div *ngIf="approxSettleAmt.errors">
                        <div *ngFor="let error of errors(approxSettleAmt)">Approximate Settlement Amount{{ error }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="settlePaidDate" class="col-sm-4 col-form-label text-right">Date Settlement Paid:</label>
                  <div class="col-sm-6">
                    <input type="text"
                           id="settlePaidDate"
                           name="settlePaidDate"
                           formControlName="settlePaidDate"
                           #dpsettlepaid="bsDatepicker"
                           class="form-control"
                           placeholder="MM/DD/YYYY"
                           bsDatepicker
                           placement="top"
                           [bsConfig]="bsConfig">
                    <div id="settlePaidDateError" *ngIf="settlePaidDate.invalid && (settlePaidDate.dirty || settlePaidDate.touched)" class="alert alert-danger">
                      <div *ngIf="settlePaidDate.errors">
                        <div *ngFor="let error of errors(settlePaidDate)">Date Settlement Paid{{ error }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-2">
                    <button type="button" class="btn btn-secondary" (click)="dpsettlepaid.toggle()" [attr.aria-expanded]="dpsettlepaid.isOpen">
                      <fa-icon [icon]="faCalendar"></fa-icon>
                    </button>
                  </div>
                </div>
              </div>
            </accordion-group>
          </accordion>


          <div class="form-group row">
            <div class="col-lg-12 text-center">
              <button type="button" id="claimInfoSaveButton" [disabled]="hasError()" (click)="save()" class="btn btn-success .navbar-right" >Next</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<ng-template #service_template>
  <div *ngIf="serviceForm">
    <div [formGroup]="serviceForm">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Add a Service</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="cancelService()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" >
        <div class="form-group row">
          <label for="serviceDesc" class="col-sm-3 col-form-label align=left">Service Description: <span class="red-text">*</span></label>
          <div class="col-sm-9" >
            <textarea type="text" title="Enter description of service provided - Max characters 200" formControlName="serviceDesc" class="form-control" id="serviceDesc" name="serviceDesc" ></textarea>
            <div id="serviceDescError" *ngIf="serviceDesc.invalid && (serviceDesc.dirty || serviceDesc.touched)" class="alert alert-danger">
              <div *ngIf="serviceDesc.errors">
                <div *ngFor="let error of errors(serviceDesc)">Service Description{{ error }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="beginDate" class="col-sm-3 col-form-label text-right">Begin Date:</label>
          <div class="col-sm-7">
            <input type="text"
                   id="beginDate"
                   name="beginDate"
                   formControlName="beginDate"
                   #dpbegin="bsDatepicker"
                   class="form-control"
                   placeholder="MM/DD/YYYY"
                   bsDatepicker
                   placement="bottom"
                   [bsConfig]="bsConfig">
            <div id="beginDateError" *ngIf="beginDate.invalid && (beginDate.dirty || beginDate.touched)" class="alert alert-danger">
              <div *ngIf="beginDate.errors">
                <div *ngFor="let error of errors(beginDate)">Begin Date{{ error }}</div>
              </div>
            </div>
          </div>
          <div class="col-sm-2">
            <button type="button" class="btn btn-secondary" (click)="dpbegin.toggle()" [attr.aria-expanded]="dpbegin.isOpen">
              <fa-icon [icon]="faCalendar"></fa-icon>
            </button>
          </div>
        </div>
        <div class="form-group row">
          <label for="endDate" class="col-sm-3 col-form-label text-right">End Date:</label>
          <div class="col-sm-7">
            <input type="text"
                   id="endDate"
                   name="endDate"
                   formControlName="endDate"
                   #dpend="bsDatepicker"
                   class="form-control"
                   placeholder="MM/DD/YYYY"
                   bsDatepicker
                   placement="bottom"
                   [bsConfig]="bsConfig">
            <div id="endDateError" *ngIf="endDate.invalid && (endDate.dirty || endDate.touched)" class="alert alert-danger">
              <div *ngIf="endDate.errors">
                <div *ngFor="let error of errors(endDate)">End Date {{ error }}</div>
              </div>
            </div>
          </div>
          <div class="col-sm-2">
            <button type="button" class="btn btn-secondary" (click)="dpend.toggle()" [attr.aria-expanded]="dpend.isOpen">
              <fa-icon [icon]="faCalendar"></fa-icon>
            </button>
          </div>
        </div>

        <div *ngIf="serviceForm.errors?.beingEndDates && (serviceForm.touched || serviceForm.dirty)" id="serviceDateError" class="alert alert-danger">
          End Date must be after Begin Date.
        </div>
        <div class="form-group row">
          <div class="col-sm-4 text-center">
            <button type="button" id="cancelServiceButton" (click)="cancelService()" class="btn btn-success .navbar-right"> Cancel </button>
          </div>
          <div *ngIf="workingServiceIndex === -1" class="col-sm-4 text-center">
            <button type="button" id="addServiceButton" (click)="addUpdateService()" class="btn btn-success .navbar-right" [disabled]="!serviceForm.valid"> Add </button>
          </div>
          <div *ngIf="workingServiceIndex !== -1" class="col-sm-4 text-center">
            <button type="button" id="updateServiceButton" (click)="addUpdateService()" class="btn btn-success .navbar-right" [disabled]="!serviceForm.valid"> Update </button>
          </div>
          <div *ngIf="workingServiceIndex !== -1" class="col-sm-4 text-center">
            <button type="button" id="removeServiceButton" (click)="removeService()" class="btn btn-danger .navbar-right"> Remove </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #procedure_template>
  <div *ngIf="procedureForm">
    <div [formGroup]="procedureForm">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Add a procedure</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="cancelProcedure()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" >
        <div class="form-group row">
          <label for="procedureCode" class="col-sm-4 col-form-label text-right">Procedure Code: <span class="red-text">*</span></label>
          <div class="col-sm-6">
            <input id="procedureCode" name="procedureCode" formControlName="procedureCode" required type="text" class="form-control" />
            <div id="procedureCodeError" *ngIf="procedureCode.invalid && (procedureCode.dirty || procedureCode.touched)" class="alert alert-danger">
              <div *ngIf="procedureCode.errors">
                <div *ngFor="let error of errors(procedureCode)">Procedure Code{{ error }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row" >
          <div class="col-sm-6 text-sm-center">
            <input id="cptRadioBtn" type="radio" value="CPT" formControlName="procedureType"> CPT
          </div>
          <div class="col-sm-6 text-sm-left">
            <input id="cdtRadioBtn" type="radio" value="CDT" formControlName="procedureType"> CDT
          </div>
        </div>

        <div class="form-group row">
          <div class="col-sm-4 text-center">
            <button type="button" id="cancelProcedureButton" (click)="cancelProcedure()" class="btn btn-success .navbar-right"> Cancel </button>
          </div>
          <div *ngIf="workingProcedureIndex === -1" class="col-sm-4 text-center">
            <button type="button" id="addProcedureButton" (click)="addUpdateProcedure()" class="btn btn-success .navbar-right" [disabled]="!procedureForm.valid"> Add </button>
          </div>
          <div *ngIf="workingProcedureIndex !== -1" class="col-sm-4 text-center">
            <button type="button" id="updateProcedureButton" (click)="addUpdateProcedure()" class="btn btn-success .navbar-right" [disabled]="!procedureForm.valid"> Update </button>
          </div>
          <div *ngIf="workingProcedureIndex !== -1" class="col-sm-4 text-center">
            <button type="button" id="removeProcedureButton" (click)="removeProcedure()" class="btn btn-danger .navbar-right"> Remove </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
