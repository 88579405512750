import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AddressDecode} from '../../shared/address-decode.model';
import {Jurisdiction} from '../../shared/jurisdiction.model';
import {faMinus, faPlus, faCalendar} from '@fortawesome/free-solid-svg-icons';
import {BsDatepickerConfig} from '../../../../node_modules/ngx-bootstrap/datepicker/bs-datepicker.config';
import {CommonService} from '../../shared/common.service';
import {OfrsIndustryValidationService} from '../../shared/validation/validation.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Address} from '../../shared/address.model';
import {Vehicle} from '../../shared/vehicle.model';
import {Employer} from '../../shared/employer.model';
import {notOnlyWhitespaceValidator} from '../../shared/validation/not-only-whitespace.directive';
import {ssnValidator} from '../../shared/validation/ssn-validator';
import {numericValidator} from '../../shared/validation/numeric-validator';
import {Observable, Subject} from 'rxjs';
import {Aka} from '../../shared/aka.model';
import {BsModalService, ModalOptions} from 'ngx-bootstrap';
import {ConfirmLeaveComponent} from '../../confirm-leave/confirm-leave.component';
import * as moment from 'moment';
import {charLength} from '../../shared/validation/char-length-validator';

@Component({
  selector: 'app-aka-detail',
  templateUrl: './aka-detail.component.html',
  styleUrls: ['./aka-detail.component.css']
})
export class AkaDetailComponent implements OnInit {
  canNavigateWithoutConfirm  = false;
  // confirmationMessage = '';
  detailForm: FormGroup;
  formErrors: any;
  errorMessage: string;
  // stored = false;
  addressDecodes: AddressDecode[];
  jurisdictions: Jurisdiction[];
  akas: Aka[];
  phonePattern = '^\\(?([0-9]{3})\\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$';
  zipPattern = '^\\d{5}(?:[-]\\d{4})?$';
  ssnPattern = '^\\d{3}-\\d{2}-\\d{4}$';
  workingAkaIndex = 0;
  faPlus = faPlus;
  faMinus = faMinus;
  faCalendar = faCalendar;
  akaInfoCollapseFlag: boolean;
  employerInfoCollapseFlag: boolean;
  vehicleInfoCollapseFlag: boolean;
  reportedInjuryCollapseFlag: boolean;
  commentsCollapseFlag: boolean;
  workingAka: Aka;
  idxString: string;
  akaIdxString: string;
  maxDate: Date = new Date();
  minDate: Date = new Date();
  bsConfig: Partial<BsDatepickerConfig>;
  isEntityTypePerson: boolean;
  fromPath: string;
  notPopulated = true;
  visibleTabs: string[];
  isIndustry: boolean;

  constructor(private commonService: CommonService, private formBuilder: FormBuilder,
              public validationService: OfrsIndustryValidationService,
              private destinationRouter: ActivatedRoute,
              private router: Router, private modalService: BsModalService) {
    this.visibleTabs = this.commonService.getAvailableTabs().slice();
    this.isIndustry = this.commonService.getOfrsReport().getOfrsReportKey().reportType === 'I';
    this.akaInfoCollapseFlag = true;
    this.employerInfoCollapseFlag = true;
    this.vehicleInfoCollapseFlag = true;
    this.reportedInjuryCollapseFlag = true;
    this.commentsCollapseFlag = true;
    this.idxString = destinationRouter.snapshot.paramMap.get('idx');
    this.akas = this.commonService.getOfrsReport().suspects[+this.idxString].akas;
    this.akaIdxString = destinationRouter.snapshot.paramMap.get('akaIdx');
    this.isEntityTypePerson = destinationRouter.snapshot.paramMap.get('entityType') === 'Person';
    this.bsConfig = Object.assign({}, { containerClass: 'theme-dark-blue' });
    this.fromPath = 'suspects/suspect/' + this.idxString;
  }

  ngOnInit() {
    this.getAddressDecodes();
    this.getJurisdictions();
    if ( this.akaIdxString !== null) {
      this.workingAka = this.akas[+this.akaIdxString];
      if (this.workingAka.address === null) {
        this.workingAka.address = new Address('Employer', '', '', '', '', '', '', '');
      } else {
        if (this.workingAka.address.addressType === null) {
          this.workingAka.address.addressType = '';
        }
        if (this.workingAka.address.state === null) {
          this.workingAka.address.state = '';
        }
      }
      if (this.workingAka.vehicle === null) {
        this.workingAka.vehicle = new Vehicle();
      }
      if (this.workingAka.employer === null) {
        this.workingAka.employer = new Employer();
      }
      if (this.workingAka.employer.address === null) {
        this.workingAka.employer.address = new Address('Employer', '', '', '', '', '', '', '');
      } else {
        if (this.workingAka.employer.address.addressType === null) {
          this.workingAka.employer.address.addressType = '';
        }
        if (this.workingAka.employer.address.state === null) {
          this.workingAka.employer.address.state = '';
        }
      }
      if (this.workingAka.homePhone === null) {
        this.workingAka.homePhone = '';
      }
      if (this.workingAka.cellPhone === null) {
        this.workingAka.cellPhone = '';
      }
      if (this.workingAka.busPhone === null) {
        this.workingAka.busPhone = '';
      }
      if (this.workingAka.vehicle === null) {
        this.workingAka.vehicle = new Vehicle();
      }
      if (this.workingAka.vehicle.licenseState === null) {
        this.workingAka.vehicle.licenseState = '';
      }
    } else {
      this.workingAka = this.createInitialAka();
    }
    this.createAkaForm(this.workingAka);
    this.minDate.setFullYear(new Date().getFullYear() - 125);
    if ( this.akaIdxString !== null) {
      // this.setToEntityType(this.isEntityTypePerson ? 'Person' : 'Business');
      this.changeAddressOtherDescAttribute();
    }
  }

  createInitialAka(): Aka {
    const aka = new Aka();
    aka.address = new Address('', '', '', '', '', '', '', '');
    const vehicle = new Vehicle();
    aka.vehicle = vehicle;

    const employer = new Employer();
    const employerAddress = new Address('Employer', '', '', '', '', '', '', '');
    employer.address = employerAddress;
    aka.employer = employer;
    aka.homePhone = '';
    aka.cellPhone = '';
    aka.busPhone = '';
    aka.driverLicenseNumber = '';
    aka.driverLicenseState = '';
    return aka;
  }

  private getJurisdictions(): void {
    this.commonService.getJurisdictions()
      .subscribe(data => this.jurisdictions = data);
  }

  private getAddressDecodes(): void {
    this.commonService.getAddressDecodes()
      .subscribe(data => {
        this.addressDecodes = data;
        // this.addressTypeOther = data.filter((value) => value.addressName === 'Other' )[0].addressTypeId;
      });
  }


  createAkaForm(aka: Aka): void {
    let dateOfBirth: Date = null;
    if (aka.birthDate != null && aka.birthDate.length !== 0) {
      dateOfBirth = moment(aka.birthDate, 'YYYY-MM-DD').toDate();
    }

    this.detailForm = this.formBuilder.group({
      firstName: [aka.firstName, [notOnlyWhitespaceValidator(), charLength(70)]],
      middleName: [aka.middleName, [notOnlyWhitespaceValidator(), charLength(70)]],
      lastName: [aka.lastName, [notOnlyWhitespaceValidator(), charLength(70)]],
      gender: [aka.gender, ],
      ssn: [aka.ssn,   [Validators.pattern(this.ssnPattern), ssnValidator()]],
      birthDate: [dateOfBirth, notOnlyWhitespaceValidator()],
      driverLicenseNumber: [aka.driverLicenseNumber, [notOnlyWhitespaceValidator(), charLength(200)]],
      driverLicenseState: [aka.driverLicenseState, [charLength(2)]],
      occupation: [aka.occupation, [notOnlyWhitespaceValidator(), charLength(70)]],
      tin: [aka.tin, [notOnlyWhitespaceValidator(), charLength(25)]],
      ein: [aka.ein, [notOnlyWhitespaceValidator(), charLength(10)]],
      npi: [aka.npi, [notOnlyWhitespaceValidator(), charLength(25)]],
      businessName: [aka.businessName, [notOnlyWhitespaceValidator(), charLength(70)]],
      addressType: [aka.address.addressType, ],
      addressTypeOtherDesc: [aka.address.addressTypeOtherDesc, [notOnlyWhitespaceValidator(), charLength(25)]],
      address1: [aka.address.address1, [notOnlyWhitespaceValidator(), charLength(70)]],
      address2: [aka.address.address2, [notOnlyWhitespaceValidator(), charLength(70)]],
      city: [aka.address.city, [notOnlyWhitespaceValidator(), charLength(70)]],
      county: [aka.address.county, [notOnlyWhitespaceValidator(), charLength(25)]],
      state: [aka.address.state, [charLength(2)]],
      zip: [aka.address.zip, [Validators.pattern(this.zipPattern)]],
      email: [aka.email, [Validators.email, charLength(70)]],
      homePhone: [aka.homePhone, Validators.pattern(this.phonePattern)],
      cellPhone: [aka.cellPhone, Validators.pattern(this.phonePattern)],
      busPhone: [aka.busPhone, Validators.pattern(this.phonePattern)],
      busPhoneExt: [aka.busPhoneExt, [numericValidator(), notOnlyWhitespaceValidator(), charLength(10)]],
      fax: [aka.fax, Validators.pattern(this.phonePattern)],

      vehicleYear: [aka.vehicle.year, [numericValidator(), notOnlyWhitespaceValidator(),
        Validators.min(1900), Validators.max(new Date().getFullYear() + 1)]],
      vehicleMake: [aka.vehicle.make, [notOnlyWhitespaceValidator(), charLength(25)]],
      vehicleModel: [aka.vehicle.model, [notOnlyWhitespaceValidator(), charLength(25)]],
      vehicleVin: [aka.vehicle.vin, [notOnlyWhitespaceValidator(), charLength(18)]],
      vehicleLicPlate: [aka.vehicle.licensePlate, [notOnlyWhitespaceValidator(), charLength(10)]],
      vehicleLicState: [aka.vehicle.licenseState, [charLength(2)]],

      employerName: [aka.employer.name, [notOnlyWhitespaceValidator(), charLength(70)]],
      employerAddress1: [aka.employer.address.address1, [notOnlyWhitespaceValidator(), charLength(70)]],
      employerAddress2: [aka.employer.address.address2, [notOnlyWhitespaceValidator(), charLength(70)]],
      employerCity: [aka.employer.address.city, [notOnlyWhitespaceValidator(), charLength(70)]],
      employerCounty: [aka.employer.address.county, [notOnlyWhitespaceValidator(), charLength(25)]],
      employerState: [aka.employer.address.state, [charLength(2)]],

      employerZip: [aka.employer.address.zip, Validators.pattern(this.zipPattern)],
      employerPhone: [aka.employer.busPhone, Validators.pattern(this.phonePattern)],
      employerPhoneExt: [aka.employer.busPhoneExt, [numericValidator(), notOnlyWhitespaceValidator(), charLength(10)] ],
      employerFax: [aka.employer.fax, Validators.pattern(this.phonePattern)]
    }, );
    this.detailForm.valueChanges.subscribe(data => this.onValueChanged(data));

    this.onValueChanged();
  }

  onValueChanged(data?: any): void {
    if (!this.detailForm) {return; }
    this.notPopulated = this.isNotPopulated();
    const form = this.detailForm;
    this.formErrors = this.validationService.setErrors(form);
  }

  changeAddressOtherDescAttribute(): void {
    if ('Other' === this.detailForm.get('addressType').value) {
      this.detailForm.controls['addressTypeOtherDesc'].setValidators(
        [Validators.required, notOnlyWhitespaceValidator(), charLength(25)]);
      this.detailForm.controls['addressTypeOtherDesc'].updateValueAndValidity();
    } else {
      this.detailForm.controls['addressTypeOtherDesc'].clearValidators();
      this.detailForm.get('addressTypeOtherDesc').setValue('');
      this.detailForm.controls['addressTypeOtherDesc'].updateValueAndValidity();
    }
  }

  cancelAka(value): void {
    this.canNavigateWithoutConfirm = false;
    this.router.navigate([this.fromPath]);
  }

  removeAka(value): void {
    const mos = new ModalOptions();
    mos.initialState = {
      title: 'Are you sure?',
      msg: 'Do you really want to remove the alias?',
      yesBtnName: 'Yes',
      noBtnName: 'No'
    };
    const subject = new Subject<boolean>();
    const modal = this.modalService.show(ConfirmLeaveComponent,  mos);
    modal.content.subject = subject;
    modal.content.subject.asObservable().subscribe(value1 => {
      if (value1 === true) {
        this.canNavigateWithoutConfirm = true;
        this.akas.splice(+this.akaIdxString, 1);
        this.commonService.getOfrsReport().getSuspects()[+this.idxString].akas = this.akas;
        this.router.navigate([this.fromPath]);
      } else {
        return;
      }}
    );
  }

  isNotPopulated(): boolean {
    if (this.detailForm.pristine) {
      return true;
    }
    // Check if any field (except addressType) is populated.
    const isPopulated = Object.keys(this.detailForm.controls)
      .filter(key => key !== 'addressType')
      .map(key => this.detailForm.get(key).value)
      .some(value => value && value.length > 0);
    return !isPopulated;
  }

  addAka(value): void {
    this.errorMessage = '';

    if (this.akas == null) { // user adds the first aka
      this.akas = new Array(this.workingAka);
      this.workingAkaIndex = 0;
    } else if ( this.akaIdxString == null) { // user adds another aka
      this.akas.push(this.workingAka);
      this.workingAkaIndex = this.akas.length - 1;
    } else { // user edits existing aka
      this.workingAkaIndex = +this.idxString;
    }

    this.akas[this.workingAkaIndex].firstName = value.firstName;
    this.akas[this.workingAkaIndex].middleName = value.middleName;
    this.akas[this.workingAkaIndex].lastName = value.lastName;
    this.akas[this.workingAkaIndex].gender = value.gender;
    this.akas[this.workingAkaIndex].ssn = value.ssn;
    this.akas[this.workingAkaIndex].birthDate = value.birthDate ? moment(value.birthDate).format('YYYY-MM-DD') : null;
    this.akas[this.workingAkaIndex].driverLicenseNumber = value.driverLicenseNumber;
    this.akas[this.workingAkaIndex].driverLicenseState = value.driverLicenseState;
    this.akas[this.workingAkaIndex].occupation = value.occupation;
    this.akas[this.workingAkaIndex].tin = value.tin;
    this.akas[this.workingAkaIndex].ein = value.ein;
    this.akas[this.workingAkaIndex].npi = value.npi;

    this.akas[this.workingAkaIndex].businessName = value.businessName;
    this.akas[this.workingAkaIndex].address.addressType = value.addressType;
    this.akas[this.workingAkaIndex].address.addressTypeOtherDesc = value.addressTypeOtherDesc;
    this.akas[this.workingAkaIndex].address.address1 = value.address1;
    this.akas[this.workingAkaIndex].address.address2 = value.address2;
    this.akas[this.workingAkaIndex].address.city = value.city;
    this.akas[this.workingAkaIndex].address.county = value.county;
    this.akas[this.workingAkaIndex].address.state = value.state;
    this.akas[this.workingAkaIndex].address.zip = value.zip;
    this.akas[this.workingAkaIndex].email = value.email;
    this.akas[this.workingAkaIndex].homePhone = value.homePhone;
    this.akas[this.workingAkaIndex].cellPhone = value.cellPhone;
    this.akas[this.workingAkaIndex].busPhone = value.busPhone;
    this.akas[this.workingAkaIndex].busPhoneExt = value.busPhoneExt;
    this.akas[this.workingAkaIndex].fax = value.fax;
    this.akas[this.workingAkaIndex].vehicle.year = value.vehicleYear;
    this.akas[this.workingAkaIndex].vehicle.make = value.vehicleMake;
    this.akas[this.workingAkaIndex].vehicle.model = value.vehicleModel;
    this.akas[this.workingAkaIndex].vehicle.vin = value.vehicleVin;
    this.akas[this.workingAkaIndex].vehicle.licensePlate = value.vehicleLicPlate;
    this.akas[this.workingAkaIndex].vehicle.licenseState = value.vehicleLicState;
    this.akas[this.workingAkaIndex].employer.name = value.employerName;
    this.akas[this.workingAkaIndex].employer.address.address1 = value.employerAddress1;
    this.akas[this.workingAkaIndex].employer.address.address2 = value.employerAddress2;
    this.akas[this.workingAkaIndex].employer.address.city = value.employerCity;
    this.akas[this.workingAkaIndex].employer.address.county = value.employerCounty;
    this.akas[this.workingAkaIndex].employer.address.state = value.employerState;
    this.akas[this.workingAkaIndex].employer.address.zip = value.employerZip;
    this.akas[this.workingAkaIndex].employer.busPhone = value.employerPhone;
    this.akas[this.workingAkaIndex].employer.busPhoneExt = value.employerPhoneExe;
    this.akas[this.workingAkaIndex].employer.fax = value.employerfax;
    this.commonService.getOfrsReport().suspects[+this.idxString].akas = this.akas;
    this.canNavigateWithoutConfirm = true;
    this.router.navigate([this.fromPath]);
  }
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.canNavigateWithoutConfirm || !this.detailForm.dirty) {
      return true;
    }
    return false;
  }

  formatSsn() {
    const ssnParts = this.detailForm.value.ssn.replace(/[^\d]/gi, '').match(/(\d{0,3})(\d{0,2})(\d{0,4})/);
    const formattedSsn = !ssnParts[2] ? ssnParts[1] : ssnParts[1] + '-' + ssnParts[2]
      + (ssnParts[3] ? '-' + ssnParts[3] : '');
    this.detailForm.get('ssn').setValue(formattedSsn);
  }
}
