import {ValidatorFn, AbstractControl} from '@angular/forms';

export function numericValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} => {
        let number = control.value;
        if (Number.isInteger(number)) {
          return null;
        } else if (number) {
            number = number.replace(/[^\w\s]/gi, '');
            number = number.replace(/ /g, '');
            return isNaN(number) ? {'NaN': {number}} : null;
        } else {
            return null;
        }
    };
}
