import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class OfrsIndustryValidationService {
    constructor() {}

    validationMessages = {
      'naicCocode': {
        'required': 'NAIC Company Code is required.',
        'NaN': 'NAIC Company Code must be numeric.',
        'maxlength': 'NAIC Company Code cannot be more than 5 digits.'
      },
      'title': {
        'required': 'Title is required.',
        'whitespace': 'Only white space is not allowed.',
        'maxlength': 'Title cannot be more than 70 characters long.'
      },
      'department': {
        'required': 'Department is required.',
        'whitespace': 'Only white space is not allowed.',
        'maxlength': 'Department cannot be more than 70 characters long.'
      },
      'firstName': {
        'required': 'First name is required.',
        'whitespace': 'Only white space is not allowed.',
        'maxlength': 'First Name cannot be more than 70 characters long.'
      },
      'middleName': {
        'whitespace': 'Only white space is not allowed.',
        'maxlength': 'Middle Name cannot be more than 70 characters long.'
      },
      'lastName': {
        'required': 'Last name is required.',
        'whitespace': 'Only white space is not allowed.',
        'maxlength': 'Last Name cannot be more than 70 characters long.'
      },
      'addressType': {
        'required': 'Please select an address type.'
      },
      'addressTypeOtherDesc': {
        'required': 'Address type other desc is required.',
        'whitespace': 'Only white space is not allowed.',
        'maxlength': 'Address Description cannot be more than 25 characters long.'
      },
      'address1': {
        'required': 'Address is required.',
        'whitespace': 'Only white space is not allowed.',
        'maxlength': 'Address line cannot be more than 70 characters long.'
      },
      'address2': {
        'whitespace': 'Only white space is not allowed.',
        'maxlength': 'Address line cannot be more than 70 characters long.'
      },
      'city': {
        'required': 'City is required.',
        'whitespace': 'Only white space is not allowed.',
        'maxlength': 'City cannot be more than 70 characters long.'
      },
      'county': {
        'whitespace': 'Only white space is not allowed.',
        'pattern': 'can only contain alpha characters and dashes.',
        'maxlength': 'County cannot be more than 25 characters long.'
      },
      'state': {
        'required': 'Please select a state.',
        'maxlength': 'State cannot be more than 2 characters long.'
      },
      'zip': {
        'required': 'Zip code is required.',
        'pattern': 'The zip code is malformatted.'
      },
      'email': {
        'required': 'Email is required.',
        'email': 'invalid format.',
        'maxlength': 'Email cannot be more than 70 characters long.'
      },
      'homePhone': {
        'required': 'A minimum of one phone number is required.',
        'pattern': 'Invalid phone number.'
      },
      'cellPhone': {
        'required': 'A minimum of one phone number is required.',
        'pattern': 'Invalid phone number.'
      },
      'busPhone': {
        'required': 'A minimum of one phone number is required.',
        'pattern': 'Invalid phone number.'
      },
      'busPhoneExt': {
        'NaN': 'Phone extention must be numeric.',
        'whitespace': 'Only white space is not allowed.',
        'maxlength': 'Phone extension cannot be more than 10 characters long.'
      },
      'fax': {
        'pattern': 'Invalid fax number.'
      },
      'entityType': {
        'required': 'Entity category is required.'
      },
      'partyDecode': {
        'required': 'Party type is required.'
      },
      'partyDecodeOtherDesc': {
        'required': 'Description is required when Other is selected.',
        'whitespace': 'Only white space is not allowed.',
        'maxlength': 'Party description cannot be more than 70 characters long.'
      },
      'ssn': {
        'invalidSsn': 'Social security number must be valid.',
        'pattern': 'Invalid SSN.'
      },
      'birthDate': {
        'whitespace': 'Only white space is not allowed.'
      },
      'driverLicenseNumber': {
        'whitespace': 'Only white space is not allowed.',
        'maxlength': 'Driver License Number cannot be more than 70 characters long.'
      },
      'occupation': {
        'whitespace': 'Only white space is not allowed.',
        'maxlength': 'Occupation cannot be more than 70 characters long.'
      },
      'tin': {
        'whitespace': 'Only white space is not allowed.',
        'maxlength': 'TIN cannot be more than 25 characters long.'
      },
      'ein': {
        'whitespace': 'Only white space is not allowed.',
        'maxlength': 'EIN cannot be more than 10 characters long.'
      },
      'npi': {
        'whitespace': 'Only white space is not allowed.',
        'maxlength': 'NPI cannot be more than 25 characters long.'
      },
      'businessName': {
        'required': 'Business name is required.',
        'whitespace': 'Only white space is not allowed.',
        'maxlength': 'Business Name cannot be more than 70 characters long.'
      },
      'employerName': {
        'whitespace': 'Only white space is not allowed.',
        'maxlength': 'Employer Name cannot be more than 70 characters long.'
      },
      'employerAddress1': {
        'whitespace': 'Only white space is not allowed.',
        'maxlength': 'Address line cannot be more than 70 characters long.'
      },
      'employerAddress2': {
        'whitespace': 'Only white space is not allowed.',
        'maxlength': 'Address line cannot be more than 70 characters long.'
      },
      'employerCity': {
        'whitespace': 'Only white space is not allowed.',
        'maxlength': 'City cannot be more than 70 characters long.'
      },
      'employerCounty': {
        'whitespace': 'Only white space is not allowed.',
        'maxlength': 'County cannot be more than 25 characters long.'
      },
      'employerZip': {
        'pattern': 'The zip code is malformatted.'
      },
      'employerPhone': {
        'pattern': 'Invalid phone number.'
      },
      'employerPhoneExt': {
        'NaN': 'Phone extention must be numeric.',
        'whitespace': 'Only white space is not allowed.',
        'maxlength': 'Phone Extension cannot be more than 10 characters long.'
      },
      'employerFax': {
        'pattern': 'Invalid fax number.'
      },
      'vehicleYear': {
        'NaN': 'Vehicle year must be numeric.',
        'whitespace': 'Only white space is not allowed.',
        'min': 'Vehicle year must be 1900 or newer.',
        'max': 'Vehicle year must not be further than one year in the future.'
      },
      'vehicleMake': {
        'whitespace': 'Only white space is not allowed.',
        'maxlength': 'Vehicle Make cannot be more than 25 characters long.'
      },
      'vehicleModel': {
        'whitespace': 'Only white space is not allowed.',
        'maxlength': 'Vehicle Model cannot be more than 25 characters long.'
      },
      'vehicleVin': {
        'whitespace': 'Only white space is not allowed.',
        'maxlength': 'VIN cannot be more than 18 characters long.'
      },
      'vehicleLicPlate': {
        'whitespace': 'Only white space is not allowed.',
        'maxlength': 'License Plate cannot be more than 10 characters long.'
      },
      'vehicleLicState': {
        'whitespace': 'Only white space is not allowed.',
        'maxlength': 'State cannot be more than 2 characters long.'
      },
      'reportedInjury': {
        'whitespace': 'Only white space is not allowed.',
        'maxlength': 'Reported Injury cannot be more than 1000 characters long.'
      },
      'comments': {
        'whitespace': 'Only white space is not allowed.',
        'maxlength': 'Comments cannot be more than 1000 characters long.'
      },
      'siuCaseNumber': {
        'notStartWithAWhiteSpace': 'White space is not allowed in the beginning.',
        'maxlength': 'Max length 30 cannot be exceeded.',
        'pattern': 'Only alpha numeric characters and dash are allowed.'
      },
      'fraudOverview': {
        'whitespace': 'Only white space is not allowed.',
        'maxlength': 'Max length 4000 cannot be exceeded.'
      },
      'completedDate': {
        'bsDate': 'Invalid completed date.'
      },
      'pcFraudTypeOther': {
        'whitespace': 'Only white space is not allowed.',
        'maxlength': 'Description cannot be more than 70 characters long.',
      },
      'hcFraudTypeOther': {
        'whitespace': 'Only white space is not allowed.',
        'maxlength': 'Description cannot be more than 70 characters long.',
      },
      'gnFraudTypeOther': {
        'whitespace': 'Only white space is not allowed.',
        'maxlength': 'Description cannot be more than 70 characters long.',
      },
    };

    formErrors = {
        'naicCocode': '',
        'title': '',
        'department': '',
        'firstName': '',
        'middleName': '',
        'lastName': '',
        'addressType': '',
        'addressTypeOtherDesc': '',
        'address1': '',
        'address2': '',
        'city': '',
        'county': '',
        'state': '',
        'zip': '',
        'email': '',
        'homePhone': '',
        'cellPhone': '',
        'busPhone': '',
        'busPhoneExt': '',
        'fax': '',
        'entityType': '',
        'partyDecode': '',
        'partyDecodeOtherDesc': '',
        'ssn': '',
        'birthDate': '',
        'driverLicenseNumber': '',
        'occupation': '',
        'tin': '',
        'ein': '',
        'npi': '',
        'businessName': '',
        'employerName': '',
        'employerAddress1': '',
        'employerAddress2': '',
        'employerCity': '',
        'employerCounty': '',
        'employerZip': '',
        'employerPhone': '',
        'employerPhoneExt': '',
        'employerFax': '',
        'vehicleYear': '',
        'vehicleMake': '',
        'vehicleModel': '',
        'vehicleVin': '',
        'vehicleLicPlate': '',
        'vehicleLicState': '',
        'reportedInjury': '',
        'comments': '',
        'siuCaseNumber': '',
        'fraudOverview': '',
        'completedDate': '',
        'pcFraudTypeOther': '',
        'hcFraudTypeOther': '',
        'gnFraudTypeOther': '',
    };

    setErrors(form: FormGroup) {
      for (const field of Object.keys(this.formErrors)) {
        this.formErrors[field] = '';
        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key of Object.keys(control.errors)) {
            this.formErrors[field] += messages[key] + ' ';
          }
        }
      }
      return this.formErrors;
    }

    getValidationMessages(): any {
      return this.validationMessages;
    }
}
