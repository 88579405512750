import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

/**
 * @description
 * Validate the length of a field according to the number of
 * Unicode code points. By default, the Javascript `str.length`
 * property only counts the number of UTF-16 bytes in a string.
 * This validator will count the code points instead.
 *
 * @usageNotes
 * ```typescript
 * this.formGroup = this.formBuilder.group({
 *   email: ['', charLength(50)]
 * });
 * ```
 *
 * @param maxLength a list of FormControls by name
 */
export function charLength(maxLength: Number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors|null => {
    if (control == null || control.value == null) {
      return null;
    }

    const byteArray = new TextEncoder().encode(control.value);

    // Count the number of code points in a string.
    let codePointCount = 0;
    byteArray.forEach(s => {
      if (s < 128 || s >= 192) {
        codePointCount++;
      }
    });

    // Validate the number of code points.
    if (codePointCount > maxLength) {
      return {maxlength: {requiredLength: maxLength, actualLength: codePointCount}};
    }

    // Validate the number of bytes (max 4000 bytes for Oracle VARCHAR2).
    if (byteArray.length > 4000) {
      return {maxlength: {requiredLength: maxLength, actualLength: byteArray.length}};
    }

    // String okay.
    return null;
  };
}
