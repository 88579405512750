import {Address} from './address.model';

export class OtherAgencyDetails {
  agencyName: string;
  agencyCase: string;
  agencyType: string;
  agencyTypeOtherDesc: string;
  agencyContact: string;
  address: Address;
  busPhone: string;
  busPhoneExt: string;
  email: string;
  fax: string;

  constructor(
    agencyName?: string,
  agencyCase?: string,
  agencyType?: string,
  agencyTypeOtherDesc?: string,
  agencyContact?: string,
  address?: Address,
  busPhone?: string,
  busPhoneExt?: string,
  email?: string,
  fax?: string,
  ) {
    this.agencyName = agencyName || '';
    this. agencyCase = agencyCase || '';
    this.agencyType = agencyType || '';
    this.agencyTypeOtherDesc = agencyTypeOtherDesc || '';
    this.agencyContact = agencyContact || '';
    this.address = address || new Address();
    this.busPhone = busPhone || '';
    this.busPhoneExt = busPhoneExt || '';
    this.email = email || '';
    this.fax = fax || '';
  }
}
