<div class="row">
  <div class="col-2">
    <div class="row float-left">
      <app-navigation [visibleItems]="visibleTabs"></app-navigation>
    </div>
  </div>
  <div class="col-10">
    <div class="row">
      <div class="col text-center">
        <h2>Submit Report</h2>
        <div class="alert alert-danger row" *ngIf="errorMessage">{{errorMessage}}</div>
        <p>Please review the data entered by using the tabs to the left of this page.  When satisfied that the information entered is correct, return to this tab to submit your report. An opportunity to print this report will be available after it has been submitted.</p>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-sm-12 text-left">
        <p>Based on your state selection(s) your report will be sent to:</p>
      </div>
      <div class="col-sm-12 text-left">
        <ul class="report" id="selectedStateList">
          <li *ngFor="let juris of jurisdictions">
            <fa-icon [icon]="faCircle" style="color: black"></fa-icon>
            <span>{{juris.departmentTextEnglish}}</span>
          </li>
        </ul>
      </div>
    </div>
    <p>Click on the State Regulatory Information link below to view regulatory information for these states.</p>
    <div align="center" ><a id="stateRegInfolink" href='https://www.naic.org' class="text-primary" target="_blank">State Regulatory Information</a></div>
    <br/>
  <form>
    <div class="form-group row">
      <div class="col-6">
        <button *ngIf="!isIndustry" type="button" id="viewReportButton"
                (click)="htmlview()" class="btn btn-success .navbar-right">Html View</button>
      </div>
      <div class="col-6">
        <button *ngIf="!isSubmitting" type="button" id="submitReportButton"
                (click)="save()" class="btn btn-success .navbar-right">Submit</button>
        <button *ngIf="isSubmitting" type="button" id="submitReportButtonPushed"
                disabled class="btn btn-success .navbar-right">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" style="vertical-align: initial"></span>
          Submitting...
        </button>
      </div>
    </div>
  </form>
  </div>
</div>

<!-- Confirmation / View PDF Report Dialog -->
<div bsModal #printReportModal="bs-modal" class="modal fade" [config]="{backdrop: 'static'}"
     tabindex="-1" role="dialog" aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-dialog-centered modal-lg" style="text-align: center">
    <div class="modal-content">
      <div class="modal-header">
        <h4 id="dialog-static-name" class="modal-title pull-left">
          <fa-icon [icon]="faCheckCircle" style="color: darkgreen"></fa-icon>
          Fraud Report Successfully Submitted
        </h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h4>NAIC Fraud Report #{{reportId}}</h4>
        <div class="alert alert-warning" role="alert" style="text-align: left">
          <fa-icon [icon]="faExclamationCircle"></fa-icon>&nbsp;
          <b>Important:</b>
          Print a copy of the Fraud Report or make note of the report number for your records.
        </div>
        <button *ngIf="!isPdfDownloading" type="button" id="printReportButton" (click)="downloadPDF()" class="btn btn-primary .navbar-right" >
          <fa-icon [icon]="faFilePdf"></fa-icon>&nbsp; Download PDF Report
        </button>
        <button *ngIf="isPdfDownloading" type="button" id="printReportButton-disabled" disabled class="btn btn-primary .navbar-right" >
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" style="vertical-align: initial"></span>
          Download PDF Report
        </button>
        <p style="margin-top: 1em">Thank you for using the NAIC Online Fraud Reporting System.</p>
      </div>
    </div>
  </div>
</div>
