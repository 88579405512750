import { Component, OnInit } from '@angular/core';
import {CommonService} from '../shared/common.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {OfrsIndustryValidationService} from '../shared/validation/validation.service';
import {Router} from '@angular/router';
import {notOnlyWhitespaceValidator} from '../shared/validation/not-only-whitespace.directive';
import {notStartsWithSpaceValidator} from '../shared/validation/not-only-whitespace.directive';



import {InvestigationSummary} from '../shared/investigation-summary.model';
import {BsDatepickerConfig} from '../../../node_modules/ngx-bootstrap/datepicker/bs-datepicker.config';
import {Observable} from 'rxjs';
import {faCalendar} from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {charLength} from '../shared/validation/char-length-validator';

@Component({
  selector: 'app-investigation-summary',
  templateUrl: './investigation-summary.component.html',
  styleUrls: ['./investigation-summary.component.css'],
  animations: [
    trigger('slider', [
      state('open', style({
        height: '*',
        'margin-bottom': '1rem'
      })),
      state('closed', style({
        opacity: '0',
        overflow: 'hidden',
        height: '0px',
        'margin-bottom': '0px'
      })),
      transition('open => closed', animate('500ms ease-in-out')),
      transition('closed => open', animate('500ms ease-in-out'))
    ])
  ]
})
export class InvestigationSummaryComponent implements OnInit {
  investigationSummary: InvestigationSummary;
  formGroup: FormGroup;
  formErrors: any;
  errorMessage: string;
  maxDate: Date = new Date();
  minDate: Date = new Date();
  bsConfig: Partial<BsDatepickerConfig>;
  stored: boolean;
  pattern = /^[a-z\d-\s]+$/i;
  visibleTabs: string[];
  faCalendar = faCalendar;

  constructor(private commonService: CommonService, private formBuilder: FormBuilder, public validationService: OfrsIndustryValidationService,
              private router: Router) {

    this.visibleTabs = this.commonService.getAvailableTabs().slice();
    this.investigationSummary = this.commonService.getOfrsReport().investigationSummary;
    if (this.investigationSummary.isSiuInvestigationCompleted === null) {
      this.investigationSummary.isSiuInvestigationCompleted = '';
    }
    if (this.investigationSummary.isRelatedToOther === null) {
      this.investigationSummary.isRelatedToOther = '';
    }
    this.bsConfig = Object.assign({}, { showWeekNumbers: false, containerClass: 'theme-dark-blue' });
  }

  ngOnInit(): void {
    if (this.visibleTabs.indexOf('investigationSummaryNav') === -1) {
      this.visibleTabs.push('investigationSummaryNav');
    }
    this.createInvesSummaryForm();
    this.minDate.setFullYear(new Date().getFullYear() - 125);
    this.stored = false;
  }

  createInvesSummaryForm(): void {
    let completedDate: Date = null;
    if (this.investigationSummary.completedDate !== null && this.investigationSummary.completedDate.length !== 0) {
      completedDate = moment(this.investigationSummary.completedDate, 'YYYY-MM-DD').toDate();
    }
    this.formGroup = this.formBuilder.group({
      isSiuInvesCompleted: [this.investigationSummary.isSiuInvestigationCompleted, [Validators.required]],
      siuCaseNumber: [this.investigationSummary.siuCaseNumber, [ notStartsWithSpaceValidator(), charLength(30), Validators.pattern(this.pattern)]],
      completedDate: [completedDate],
      isRelatedToOther: [this.investigationSummary.isRelatedToOther, [Validators.required]],
      fraudOverview: [this.investigationSummary.overview, [notOnlyWhitespaceValidator(), charLength(4000)]],
    }, );

    this.formGroup.valueChanges.subscribe(data => this.onValueChanged(data));

    this.onValueChanged();
    this.formGroup.get('isSiuInvesCompleted').valueChanges.subscribe(v => {
      if ( v !== 'Y') {
        this.formGroup.get('completedDate').setValue('');
      }
    });
  }

  get overviewLength(): number {
    const overview = this.formGroup.get('fraudOverview').value;
    return !overview ? 0 : new TextEncoder().encode(overview).length;
  }

  onValueChanged(data?: any) {
    if (!this.formGroup) {return; }
    const form = this.formGroup;
    this.formErrors = this.validationService.setErrors(form);
  }

  save(formData): void {
    this.errorMessage = '';
    this.investigationSummary.isSiuInvestigationCompleted = formData.isSiuInvesCompleted;
    this.investigationSummary.siuCaseNumber = formData.siuCaseNumber;
    const completedDate: Date = formData.completedDate;
    this.investigationSummary.completedDate = (formData.isSiuInvesCompleted === 'Y' && completedDate)
                                            ? moment(completedDate).format('YYYY-MM-DD') : null,
    this.investigationSummary.isRelatedToOther = formData.isRelatedToOther;
    this.investigationSummary.overview = formData.fraudOverview;
    this.commonService.setAvailableTabs(this.visibleTabs);
    this.stored = true;
    this.router.navigate(['evidence-type']);
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.stored || !this.formGroup.dirty) {
      return true;
    }
    return false;
  }
}
