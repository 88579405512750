<div class="row">
  <div class="col-2">
    <div class="row float-left">
      <app-navigation [visibleItems]="visibleTabs"></app-navigation>
    </div>
  </div>
  <div class="col-10 pl-sm-5">
    <div class="row">
      <div class="col text-center">
        <h2>Investigation Summary</h2>
        <p>Fields marked with an <span class="red-text">*</span> are required</p>
      </div>
    </div>

    <div class="alert alert-danger row" *ngIf="errorMessage">{{errorMessage}}</div>

    <form *ngIf="formGroup" [formGroup]="formGroup" >
      <div class="form-group row">
        <label for="isSiuInvesCompleted" class="control-label col-sm-6 text-right">SIU Investigation Completed? <span class="red-text">*</span></label>
        <div class="col-sm-6">
          <select title="Is SIU Investigation Completed" formControlName="isSiuInvesCompleted" class="form-control" id="isSiuInvesCompleted" name="isSiuInvesCompleted">
            <option [ngValue]="null" disabled hidden>Choose One</option>
            <option value="Y">Yes</option>
            <option value="N">No</option>
            <option value="U">Unknown</option>
          </select>
        </div>
      </div>
      <div [@slider]="formGroup.value.isSiuInvesCompleted === 'Y' ? 'open': 'closed'" class="form-group row">
        <label for="completedDate" class="control-label col-sm-6 text-right">Completed Date:</label>
        <div class="col-sm-4">
          <input id="completedDate" title="Complete Date" type="text" class="form-control" [minDate]="minDate" [maxDate]="maxDate" [bsConfig]="bsConfig" placeholder="MM/DD/YYYY" bsDatepicker formControlName="completedDate" #d="bsDatepicker" triggers=""/>
          <pre *ngIf="formGroup.value.completedDate" class="code-preview">{{formGroup.value.completedDate | date}}</pre>
          <div *ngIf="formErrors.completedDate" class="alert alert-danger">
            {{formErrors.completedDate}}
          </div>
        </div>
        <div class="col-sm-2" style="padding-left: 0">
          <button id="completedDateCalendarButton" type="button" class="btn btn-secondary"
                  (click)="d.toggle()" [attr.aria-expanded]="d.isOpen">
            <fa-icon [icon]="faCalendar"></fa-icon>
          </button>
        </div>
      </div>
      <div class="form-group row">
        <label for="siuCaseNumber" class="control-label col-sm-6 text-right">SIU Case Number:</label>
        <div class="col-sm-6">
          <input title="SIU Case Number" type="text" formControlName="siuCaseNumber"  class="form-control" id="siuCaseNumber" name="siuCaseNumber" />
          <div *ngIf="formErrors.siuCaseNumber" class="alert alert-danger">
            {{formErrors.siuCaseNumber}}
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label for="isRelatedToOther" class="control-label col-sm-6 text-right">Is incident believed to be related to other fraudulent activity? <span class="red-text">*</span></label>
        <div class="col-sm-6">
            <select title="Is Incident related to Other Fraud" formControlName="isRelatedToOther" class="form-control" id="isRelatedToOther" name="isRelatedToOther">
              <option [ngValue]="null" disabled hidden>Choose One</option>
              <option value="Y">Yes</option>
              <option value="N">No</option>
              <option value="U">Unknown</option>
          </select>
        </div>
      </div>

      <div class="form-group row">
        <label for="fraudOverview" class="control-label col-sm-12 text-left">Please provide an overview of the fraud activity(4000 characters maximum)</label>
        <textarea rows="8" type="text" title="Fraud Overview" formControlName="fraudOverview" #fraudOverview class="form-control" id="fraudOverview" name="fraudOverview" ></textarea>
          <p id="remainingCount" class="form-text text-muted col-sm-12"><code>{{overviewLength}}</code> of <code>4000</code> characters</p>
          <div *ngIf="formErrors.fraudOverview" class="alert alert-danger">
            {{formErrors.fraudOverview}}
          </div>
      </div>


      <div class="form-group row"><div class="col-sm-12 text-center">
        <button type="button" id="invesSummaryButton" (click)="save(formGroup.value)" class="btn btn-success .navbar-right" [disabled]="!formGroup.valid">
          Next
        </button></div>
      </div>

    </form>
  </div>
</div>
