import { Component, OnInit } from '@angular/core';
import {CommonService} from '../shared/common.service';
import {AbstractControl, FormBuilder, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {OfrsReport} from '../shared/ofrs-report.model';
import {environment} from '../../environments/environment';
import {numericValidator} from '../shared/validation/numeric-validator';
import {notOnlyWhitespaceValidator} from '../shared/validation/not-only-whitespace.directive';
import {SearchCriteria} from '../shared/search-criteria.model';
import {OfrsReportInfo} from '../shared/ofrs-report-info.model';
import {noFutureDateValidator} from '../shared/validation/no-future-date.directive';
import { faCalendar} from '@fortawesome/free-solid-svg-icons';
import {BsDatepickerConfig} from 'ngx-bootstrap';
import { searchFraudValidator } from '../shared/validation/search-fraud-validator';


@Component({
  selector: 'app-search-fraud',
  templateUrl: './search-fraud.component.html',
  styleUrls: ['./search-fraud.component.css']
})
export class SearchFraudComponent implements OnInit {

  transId: number;
  formGroup: FormGroup;
  formErrors: any;
  errorMessage: string;
  reports: OfrsReportInfo[];
  // criteria: SearchCriteria;
  // reportId: number;
  headers: String[] = ['FRAUD ID', 'SUBJECT/INVOLVED PARTY', 'POLICY NUMBER', 'CLAIM NUMBER', 'REPORTING PERSON', 'SUBMIT DATE', 'ACTION'];

  // icons
  faCalendar = faCalendar;

  readonly bsConfig = Object.assign(new BsDatepickerConfig(), {
    containerClass: 'theme-dark-blue',
    maxDate: new Date(),
    showWeekNumbers: false
  });



  constructor(private commonService: CommonService,
              private formBuilder: FormBuilder,
              private router: Router) { }

  ngOnInit() {
    this.createSearchFraudForm();
  }

  createSearchFraudForm(): void {
    this.formGroup = this.formBuilder.group({
      reportId: ['', [notOnlyWhitespaceValidator(), numericValidator()]],
      suspectLastName: ['', [notOnlyWhitespaceValidator()]],
      policyNumber: ['', [notOnlyWhitespaceValidator()]],
      claimNumber: ['', [notOnlyWhitespaceValidator()]],
      submitDateFrom: [null, noFutureDateValidator()],
      submitDateTo: [null, noFutureDateValidator()],
      reporterLastName: ['', [notOnlyWhitespaceValidator()]]
    }, { validator: searchFraudValidator});
  }

  get reportId() { return this.formGroup.get('reportId'); }
  get suspectLastName() { return this.formGroup.get('suspectLastName'); }
  get policyNumber() { return this.formGroup.get('policyNumber'); }
  get claimNumber() { return this.formGroup.get('claimNumber'); }
  get submitDateFrom() { return this.formGroup.get('submitDateFrom'); }
  get submitDateTo() { return this.formGroup.get('submitDateTo'); }
  get reporterLastName() { return this.formGroup.get('reporterLastName'); }

  errors(control: AbstractControl): string[] {
    const errors: string[] = [];
    Object.keys(control.errors).forEach((key: string) => {
       if (key === 'bsDate') {
         while (errors.length > 0) {
           errors.pop();
         }
         errors.push('Invalid date');
       }
       if (key === 'noFutureDate') {
         if (errors.length === 0) {
           errors.push('Cannot be a date in the future');
         }
       }
      if (key === 'whitespace') {
        errors.push('Only white space is not allowed');
      }
      if (key === 'NaN') {
        errors.push('Must be numeric');
      }
    });
    return errors;
  }

  canBeSubmitted(): boolean {
      return this.reportId.value && this.reportId.value.length > 0 ||
        this.suspectLastName.value && this.suspectLastName.value.length > 0 ||
        this.policyNumber.value && this.policyNumber.value.length > 0 ||
        this.claimNumber.value && this.claimNumber.value.length > 0 ||
        this.reporterLastName.value && this.reporterLastName.value.length > 0 ||
        this.submitDateFrom.value && this.submitDateTo.value;
  }
  hasError(): boolean {
    return this.formGroup.invalid;
  }

  search(value): void {
    if (this.reports && this.reports.length > 0) {
      this.reports.slice(0, this.reports.length);
    }
    this.errorMessage = '';
    let reportIdStr: string;
    reportIdStr = value.reportId;
    reportIdStr = reportIdStr.trim();
    const criteria: SearchCriteria = new SearchCriteria(reportIdStr ? +reportIdStr : null, value.suspectLastName, value.policyNumber, value.claimNumber, value.submitDateFrom, value.submitDateTo, value.reporterLastName);

    this.commonService.search(criteria).subscribe(
        data => {
          this.reports = data;
        }, error => {
          console.error(error.message, []);
          this.errorMessage = error.message;
        });
    }
  displaySuspectName(entityType: string, first: string, middle: string, last: string, business: string): string {
    const displayName = this.commonService.displayName(entityType, first, middle, last, business);
    return displayName.trim().length > 0 ? displayName.trim() : 'Unnamed Suspect';
  }

  downloadPDF(reportId: number, transactionId: number): void {
    document.body.style.cursor = 'progress';
    this.commonService.downloadPDFReport(transactionId).subscribe(blob => {
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(blob);
      a.download = `ofrs-report-${reportId}.pdf`;
      a.click();
      this.errorMessage = undefined;
      document.body.style.cursor = 'default';
    }, error => {
      console.error(error);
      this.errorMessage = error.message;
      document.body.style.cursor = 'default';
      document.getElementById('errorMsg').scrollIntoView({behavior: 'smooth'});
    }, () => {
      document.body.style.cursor = 'default';
    });
  }

  modify(transactionId: number): void {
    this.commonService.loadOfrsReport(transactionId).subscribe(
      (ofrsReport: OfrsReport) => {
            this.commonService.setOfrsReport(ofrsReport);
            this.commonService.setAvailableTabs([
                'welcomeNav', 'jurisdictionsNav', 'reportingPersonNav', 'fraudTypeNav',
                'suspectsNav', 'investigationSummaryNav', 'evidenceTypeNav',
                'attachmentsNav', 'claimInformationNav', 'otherAgenciesNav']);
            this.router.navigate(['jurisdiction-selection']);
          }, error => {
            console.error(error.message, []);
            this.errorMessage = error.message;
          });
  }

  cancel(): void {
    this.router.navigate(['welcome']);
  }

}
