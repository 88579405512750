import {Dates} from './util/dates.util';

export class Service {

  serviceDesc: string;
  beginDate: Date;
  endDate: Date;

  constructor(obj?: any) {
    Object.assign(this, obj, {
      beginDate: Dates.parse(obj['beginDate']),
      endDate: Dates.parse(obj['endDate'])
    });
  }

  toJSON() {
    return {
      serviceDesc: this.serviceDesc,
      beginDate: Dates.format(this.beginDate),
      endDate: Dates.format(this.endDate)
    };
  }
}
