import { Injectable } from '@angular/core';
import { Router, Resolve } from '@angular/router';
import { CommonService } from '../common.service';
import { OfrsReport } from '../ofrs-report.model';
import { Observable, of, EMPTY } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OfrsReportResolverService implements Resolve<OfrsReport> {

  constructor(private commonService: CommonService, private router: Router) {}

  resolve(): Observable<OfrsReport> | Observable<never> {
    const report = this.commonService.getOfrsReport();

    if (report && report !== null) {
      return of(report);
    } else {
      this.router.navigate(['welcome']);
      return EMPTY;
    }
  }

}
