import {Component, Input, OnInit} from '@angular/core';
import {NavItem} from './nav-item';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {
  navItems: NavItem[];
  @Input() activeItem;
  @Input() visibleItems;

  constructor() { }

  ngOnInit() {
    this.navItems = [];
    this.navItems.push(new NavItem('welcomeNav', 'Welcome', '/welcome'));
    this.navItems.push(new NavItem('jurisdictionsNav', 'Jurisdiction Selection', '/jurisdiction-selection'));
    this.navItems.push(new NavItem('reportingPersonNav', 'Reporting Person', '/reporting-person'));
    this.navItems.push(new NavItem('fraudTypeNav', 'Fraud Type', '/fraud-type'));
    this.navItems.push(new NavItem('suspectsNav', 'Entity/Suspect Info', '/suspects'));
    this.navItems.push(new NavItem('investigationSummaryNav', 'Investigation Summary', '/investigation-summary'));
    this.navItems.push(new NavItem('fraudDescriptionNav', 'Fraud Description', '/fraud-description'));
    this.navItems.push(new NavItem('evidenceTypeNav', 'Evidence Type', '/evidence-type'));
    this.navItems.push(new NavItem('attachmentsNav', 'Attachments', '/attachments'));
    this.navItems.push(new NavItem('claimInformationNav', 'Claim and Incident Information', '/claim-information'));
    this.navItems.push(new NavItem('otherAgenciesNav', 'Other Agencies', '/other-agencies'));
    this.navItems.push(new NavItem('submitReportNav', 'Submit Report', '/submit-report'));
  }

}
