import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {User} from './user.model';
import {AuthConfig, JwksValidationHandler, OAuthService} from 'angular-oauth2-oidc';
import {Company} from './company.model';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class OfrsUserService {

  user: User;
  status: 'NO_AUTH'|'NO_ROLE'|'OK' = 'NO_AUTH';

  constructor(private httpClient: HttpClient,
              private oauthService: OAuthService,
              private sessionService: SessionService) {
    const config: AuthConfig = {
      strictDiscoveryDocumentValidation: true,
      issuer: `${environment.auth.issuer}`,
      redirectUri: `${environment.auth.redirectBaseUrl}`,
      postLogoutRedirectUri: environment.auth.logoutUrl,
      silentRefreshRedirectUri: environment.auth.redirectBaseUrl + '/silent-refresh.html',
      logoutUrl: `${environment.auth.logoutUrl}`,
      clientId: environment.auth.clientId,
      tokenEndpoint: `${environment.auth.tokenEndpoint}`,
      userinfoEndpoint: `${environment.auth.userInfoEndpoint}`,
      scope: 'openid profile email',
      timeoutFactor: 0.75,
      responseType: 'code',
      showDebugInformation: true,
    };
    this.oauthService.configure(config);
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
  }

  async login(): Promise<User> {
    await this.oauthService.loadDiscoveryDocumentAndLogin();
    this.user = await this.loadUser().toPromise()
      .then(user => {
        this.status = 'OK';
        return new User(user);
      })
      .catch(err => {
        this.status = err.status === 403 ? 'NO_ROLE' : 'NO_AUTH';
        console.error(err);
        return undefined;
      });
    if (this.user) {
      this.user.companies = await this.loadCompanies().toPromise().catch(err => {
          console.log(err);
          return [];
      });
    }
    return this.user;
  }

  logout() {
    this.oauthService.logOut();
  }

  protected loadUser(): Observable<User> {
    const url = `${environment.apiUrl}/user`;
    this.sessionService.configureSessionTimeout();
    return this.httpClient.get<User>(url);
  }

  protected loadCompanies(): Observable<Company[]> {
    const url = `${environment.apiUrl}/companies`;
    return this.httpClient.get<Company[]>(url);
  }
}
