import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PartyDecode} from '../../shared/party-decode.model';
import {AddressDecode} from '../../shared/address-decode.model';
import {Jurisdiction} from '../../shared/jurisdiction.model';
import {Party} from '../../shared/party.model';
import {faMinus, faPlus, faCalendar, faTrash} from '@fortawesome/free-solid-svg-icons';
import {CommonService} from '../../shared/common.service';
import {OfrsIndustryValidationService} from '../../shared/validation/validation.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Address} from '../../shared/address.model';
import {Vehicle} from '../../shared/vehicle.model';
import {Employer} from '../../shared/employer.model';
import {selectValidator} from '../../shared/validation/select-validator';
import {notOnlyWhitespaceValidator} from '../../shared/validation/not-only-whitespace.directive';
import {numericValidator} from '../../shared/validation/numeric-validator';
import {Observable, Subject} from 'rxjs';
import {ssnValidator} from '../../shared/validation/ssn-validator';
import {BsDatepickerConfig} from '../../../../node_modules/ngx-bootstrap/datepicker/bs-datepicker.config';
import {Aka} from '../../shared/aka.model';
import {BsModalService, ModalOptions} from 'ngx-bootstrap';
import {ConfirmLeaveComponent} from '../../confirm-leave/confirm-leave.component';
import * as moment from 'moment';
import {charLength} from '../../shared/validation/char-length-validator';

@Component({
  selector: 'app-suspect-detail',
  templateUrl: './suspect-detail.component.html',
  styleUrls: ['./suspect-detail.component.css']
})
export class SuspectDetailComponent implements OnInit {
  canNavigateWithoutConfirm  = false;
  // confirmationMessage = '';
  detailForm: FormGroup;
  formErrors: any;
  errorMessage: string;
  partyDecodes: PartyDecode[];
  addressDecodes: AddressDecode[];
  jurisdictions: Jurisdiction[];
  suspects: Party[];
  phonePattern = '^\\(?([0-9]{3})\\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$';
  zipPattern = '^\\d{5}(?:[-]\\d{4})?$';
  ssnPattern = '^\\d{3}-\\d{2}-\\d{4}$';
  workingSuspectIndex = 0;
  workingSuspect: Party;
  akas: Aka[]; // akas associated with the working suspect
  faPlus = faPlus;
  faMinus = faMinus;
  faCalendar = faCalendar;
  faTrash = faTrash;
  suspectInfoCollapseFlag: boolean;
  employerInfoCollapseFlag: boolean;
  aliasInfoCollapseFlag: boolean;
  vehicleInfoCollapseFlag: boolean;
  reportedInjuryCollapseFlag: boolean;
  commentsCollapseFlag: boolean;
  idxString: string;
  maxDate: Date = new Date();
  minDate: Date = new Date();
  bsConfig: Partial<BsDatepickerConfig>;
  disableEntityType = false;
  visibleTabs: string[];
  isIndustry: boolean;


  constructor(private commonService: CommonService, private formBuilder: FormBuilder,
              public validationService: OfrsIndustryValidationService,
              private destinationRouter: ActivatedRoute,
              private router: Router, private modalService: BsModalService) {
    this.visibleTabs = this.commonService.getAvailableTabs().slice();
    this.isIndustry = this.commonService.getOfrsReport().getOfrsReportKey().reportType === 'I';
    this.suspects = this.commonService.getOfrsReport().suspects;
    this.suspectInfoCollapseFlag = true;
    this.employerInfoCollapseFlag = true;
    this.aliasInfoCollapseFlag = true;
    this.vehicleInfoCollapseFlag = true;
    this.reportedInjuryCollapseFlag = true;
    this.commentsCollapseFlag = true;
    this.idxString = destinationRouter.snapshot.paramMap.get('idx');
    this.bsConfig = Object.assign({}, { containerClass: 'theme-dark-blue' });

  }
  ngOnInit() {
    if (this.visibleTabs.indexOf('suspectsNav') === -1) {
      this.visibleTabs.push('suspectsNav');
    }
    this.getPartyDecodes();
    this.getAddressDecodes();
    this.getJurisdictions();
    if ( this.idxString && this.idxString.length > 0 ) { // user edits existing suspect
      this.workingSuspectIndex = +this.idxString;
      this.workingSuspect = this.suspects[this.workingSuspectIndex];
      if (this.workingSuspect.entityType == null) {
        this.workingSuspect.entityType = '';
      }
      if (this.workingSuspect.partyType == null) {
        this.workingSuspect.partyType = '';
      }
      if (this.workingSuspect.partyTypeOtherDesc == null) {
        this.workingSuspect.partyTypeOtherDesc = '';
      }
      if (this.workingSuspect.address == null) {
        this.workingSuspect.address = new Address('', '', '', '', '', '', '', '');
      } else {
        if (this.workingSuspect.address.addressType == null) {
          this.workingSuspect.address.addressType = '';
        }
        if (this.workingSuspect.address.state == null) {
          this.workingSuspect.address.state = '';
        }
      }
      if (this.workingSuspect.vehicle == null) {
        this.workingSuspect.vehicle = new Vehicle();
      }
      if (this.workingSuspect.vehicle.licenseState == null) {
        this.workingSuspect.vehicle.licenseState = '';
      }
      if (this.workingSuspect.employer == null) {
        this.workingSuspect.employer = new Employer();
      }
      if (this.workingSuspect.employer.address == null) {
        this.workingSuspect.employer.address = new Address('Employer', '', '', '', '', '', '', '');
      } else {
        if (this.workingSuspect.employer.address.addressType == null) {
          this.workingSuspect.employer.address.addressType = '';
        }
        if (this.workingSuspect.employer.address.state == null) {
          this.workingSuspect.employer.address.state = '';
        }
      }
      if (this.workingSuspect.homePhone == null) {
        this.workingSuspect.homePhone = '';
      }
      if (this.workingSuspect.cellPhone == null) {
        this.workingSuspect.cellPhone = '';
      }
      if (this.workingSuspect.busPhone == null) {
        this.workingSuspect.busPhone = '';
      }

      this.akas = this.workingSuspect.akas;
      // entity type is disabled for an existing suspect
      this.disableEntityType = true;
    } else {
      this.workingSuspect = this.createInitialSuspect();
    }
    this.createSuspectForm(this.workingSuspect);
    this.minDate.setFullYear(new Date().getFullYear() - 125);
    if ( this.idxString ) {
      this.setToEntityType(this.workingSuspect.entityType);
      this.changeAddressOtherDescAttribute();
    }
  }

  createInitialSuspect(): Party {
    const suspect = new Party();
    suspect.entityType = '';
    suspect.partyType = '';
    suspect.partyTypeOtherDesc = '';
    suspect.address = new Address('', '', '', '', '', '', '', '');
    const vehicle = new Vehicle();
    suspect.vehicle = vehicle;
    suspect.vehicle.licenseState = '';
    const employer = new Employer();
    const employerAddress = new Address('Employer', '', '', '', '', '', '', '');
    employer.address = employerAddress;
    suspect.employer = employer;
    suspect.homePhone = '';
    suspect.cellPhone = '';
    suspect.busPhone = '';
    suspect.akas = [];
    return suspect;
  }

  private getPartyDecodes(): void {
    this.commonService.getPartyDecodes()
      .subscribe(data => {
        this.partyDecodes = data;
      });
  }

  private getJurisdictions(): void {
    this.commonService.getJurisdictions()
      .subscribe(data => this.jurisdictions = data);
  }

  private getAddressDecodes(): void {
    this.commonService.getAddressDecodes()
      .subscribe(data => {
        this.addressDecodes = data;
      });
  }


  createSuspectForm(suspect: Party): void {
    let dateOfBirth: Date  = null;
    if (suspect.birthDate != null && suspect.birthDate.length !== 0) {
      dateOfBirth = moment(suspect.birthDate, 'YYYY-MM-DD').toDate();
    }

    this.detailForm = this.formBuilder.group({
      entityType: [{value: suspect.entityType, disabled: this.disableEntityType}, [Validators.required]],
      partyType: [suspect.partyType, [Validators.required]],
      partyTypeOtherDesc: [suspect.partyTypeOtherDesc, [charLength(70)]],
      firstName: [suspect.firstName, [Validators.required, notOnlyWhitespaceValidator(), charLength(70)]],
      middleName: [suspect.middleName, [notOnlyWhitespaceValidator(), charLength(70)]],
      lastName: [suspect.lastName, [Validators.required, notOnlyWhitespaceValidator(), charLength(70)]],
      gender: [suspect.gender],
      ssn: [suspect.ssn, [Validators.pattern(this.ssnPattern), ssnValidator()]],
      birthDate: [dateOfBirth, [notOnlyWhitespaceValidator()]],
      driverLicenseNumber: [suspect.driverLicenseNumber, [notOnlyWhitespaceValidator(), charLength(200)]],
      driverLicenseState: [suspect.driverLicenseState, [charLength(2)]],
      occupation: [suspect.occupation, [notOnlyWhitespaceValidator(), charLength(70)]],
      tin: [suspect.tin, [notOnlyWhitespaceValidator(), charLength(25)]],
      ein: [suspect.ein, [notOnlyWhitespaceValidator(), charLength(10)]],
      npi: [suspect.npi, [notOnlyWhitespaceValidator(), charLength(25)]],
      businessName: [suspect.businessName, [Validators.required, notOnlyWhitespaceValidator(), charLength(70)]],
      subjectOfInvestigation: [suspect.subjectOfInvestigation, [Validators.required]],
      addressType: [suspect.address.addressType, [Validators.required, selectValidator()]],
      addressTypeOtherDesc: [suspect.address.addressTypeOtherDesc, [notOnlyWhitespaceValidator(), charLength(25)]],
      address1: [suspect.address.address1, [Validators.required, notOnlyWhitespaceValidator(), charLength(70)]],
      address2: [suspect.address.address2, [notOnlyWhitespaceValidator(), charLength(70)]],
      city: [suspect.address.city, [Validators.required, notOnlyWhitespaceValidator(), charLength(70)]],
      county: [suspect.address.county, [notOnlyWhitespaceValidator(), charLength(25)]],
      state: [suspect.address.state, [Validators.required, selectValidator(), charLength(2)]],
      zip: [suspect.address.zip, [Validators.required, Validators.pattern(this.zipPattern)]],
      email: [suspect.email, [Validators.email, charLength(70)]],
      homePhone: [suspect.homePhone, [Validators.pattern(this.phonePattern)]],
      cellPhone: [suspect.cellPhone, [Validators.pattern(this.phonePattern)]],
      busPhone: [suspect.busPhone, [Validators.pattern(this.phonePattern)]],
      busPhoneExt: [suspect.busPhoneExt, [numericValidator(), notOnlyWhitespaceValidator(), charLength(10)]],
      fax: [suspect.fax, [Validators.pattern(this.phonePattern)]],
      vehicleYear: [suspect.vehicle.year, [numericValidator(), notOnlyWhitespaceValidator(),
        Validators.min(1900), Validators.max(new Date().getFullYear() + 1)]],
      vehicleMake: [suspect.vehicle.make, [notOnlyWhitespaceValidator(), charLength(25)]],
      vehicleModel: [suspect.vehicle.model, [notOnlyWhitespaceValidator(), charLength(25)]],
      vehicleVin: [suspect.vehicle.vin, [notOnlyWhitespaceValidator(), charLength(18)]],
      vehicleLicPlate: [suspect.vehicle.licensePlate, [notOnlyWhitespaceValidator(), charLength(10)]],
      vehicleLicState: [suspect.vehicle.licenseState, [charLength(2)]],
      employerName: [suspect.employer.name, [notOnlyWhitespaceValidator(), charLength(70)]],
      employerAddress1: [suspect.employer.address.address1, [notOnlyWhitespaceValidator(), charLength(70)]],
      employerAddress2: [suspect.employer.address.address2, [notOnlyWhitespaceValidator(), charLength(70)]],
      employerCity: [suspect.employer.address.city, [notOnlyWhitespaceValidator(), charLength(70)]],
      employerCounty: [suspect.employer.address.county, [notOnlyWhitespaceValidator(), charLength(25)]],
      employerState: [suspect.employer.address.state, [charLength(2)]],
      employerZip: [suspect.employer.address.zip, [Validators.pattern(this.zipPattern)]],
      employerPhone: [suspect.employer.busPhone, [Validators.pattern(this.phonePattern)]],
      employerPhoneExt: [suspect.employer.busPhoneExt, [numericValidator(), notOnlyWhitespaceValidator(), charLength(10)]],
      employerFax: [suspect.employer.fax, [Validators.pattern(this.phonePattern)]],
      reportedInjury: [suspect.reportedInjury, [notOnlyWhitespaceValidator(), charLength(1000)]],
      comments: [suspect.comments, [notOnlyWhitespaceValidator(), charLength(1000)]]
      }, );
    this.detailForm.valueChanges.subscribe(data => this.onValueChanged(data));
    this.onValueChanged();
  }

  onValueChanged(data?: any): void {
    if (!this.detailForm) {return; }
    const form = this.detailForm;
    this.formErrors = this.validationService.setErrors(form);
  }

  changePartyOtherDescAttribute(): void {
    if ('OTHER' === this.detailForm.get('partyType').value) {
      this.detailForm.controls['partyTypeOtherDesc'].setValidators([Validators.required, notOnlyWhitespaceValidator(), charLength(70)]);
    } else {
      this.detailForm.controls['partyTypeOtherDesc'].clearValidators();
      this.detailForm.get('partyTypeOtherDesc').setValue('');
    }
    this.detailForm.controls['partyTypeOtherDesc'].updateValueAndValidity();
  }

  changeAddressOtherDescAttribute(): void {
    if ('Other' === this.detailForm.get('addressType').value) {
      this.detailForm.controls['addressTypeOtherDesc'].setValidators([Validators.required, notOnlyWhitespaceValidator(), charLength(25)]);
      this.detailForm.controls['addressTypeOtherDesc'].updateValueAndValidity();
    } else {
      this.detailForm.controls['addressTypeOtherDesc'].clearValidators();
      this.detailForm.get('addressTypeOtherDesc').setValue('');
      this.detailForm.controls['addressTypeOtherDesc'].updateValueAndValidity();
    }
  }

  setToEntityType(type: string) {
    if (type === 'Person') {
      this.detailForm.controls['firstName'].setValidators([Validators.required, notOnlyWhitespaceValidator(), charLength(70)]);
      this.detailForm.controls['lastName'].setValidators([Validators.required, notOnlyWhitespaceValidator(), charLength(70)]);
      this.detailForm.controls['businessName'].clearValidators();
      this.detailForm.controls['businessName'].setValue('');
    } else {
      this.detailForm.controls['firstName'].clearValidators();
      this.detailForm.controls['lastName'].clearValidators();
      this.detailForm.controls['businessName'].setValidators([Validators.required, notOnlyWhitespaceValidator(), charLength(70)]);
      this.detailForm.controls['firstName'].setValue('');
      this.detailForm.controls['middleName'].setValue('');
      this.detailForm.controls['lastName'].setValue('');
      this.detailForm.controls['gender'].setValue('');
      this.detailForm.controls['ssn'].setValue('');
      this.detailForm.controls['birthDate'].setValue('');
      this.detailForm.controls['driverLicenseNumber'].setValue('');
      this.detailForm.controls['driverLicenseState'].setValue('');
      this.detailForm.controls['occupation'].setValue('');
      this.detailForm.controls['tin'].setValue('');
      this.detailForm.controls['ein'].setValue('');
      this.detailForm.controls['npi'].setValue('');
    }
    this.detailForm.controls['firstName'].updateValueAndValidity();
    this.detailForm.controls['middleName'].updateValueAndValidity();
    this.detailForm.controls['lastName'].updateValueAndValidity();
    this.detailForm.controls['gender'].updateValueAndValidity();
    this.detailForm.controls['ssn'].updateValueAndValidity();
    this.detailForm.controls['birthDate'].updateValueAndValidity();
    this.detailForm.controls['driverLicenseNumber'].updateValueAndValidity();
    this.detailForm.controls['driverLicenseState'].updateValueAndValidity();
    this.detailForm.controls['occupation'].updateValueAndValidity();
    this.detailForm.controls['tin'].updateValueAndValidity();
    this.detailForm.controls['ein'].updateValueAndValidity();
    this.detailForm.controls['npi'].updateValueAndValidity();
    this.detailForm.controls['businessName'].updateValueAndValidity();
    this.detailForm.controls['subjectOfInvestigation'].updateValueAndValidity();
  }

  cancelSuspect(value): void {
    this.canNavigateWithoutConfirm = false;
    this.router.navigate(['suspects']);
  }
  removeSuspect(value): void {
    const mos = new ModalOptions();
    mos.initialState = {
      title: 'Are you sure?',
      msg: 'Do you really want to remove the suspect?',
      yesBtnName: 'Yes',
      noBtnName: 'No'
    };
    const subject = new Subject<boolean>();
    const modal = this.modalService.show(ConfirmLeaveComponent,  mos);
    modal.content.subject = subject;
    modal.content.subject.asObservable().subscribe(value1 => {
      if (value1 === true) {
        this.canNavigateWithoutConfirm = true;
        this.suspects.splice(+this.idxString, 1);
        this.commonService.getOfrsReport().suspects = this.suspects;
        this.router.navigate(['suspects']);
      } else {
        return;
      }}
    );
  }
  removeAka(idx): void {
    const mos = new ModalOptions();
    mos.initialState = {
      title: 'Are you sure?',
      msg: 'Do you really want to remove the alias?',
      yesBtnName: 'Yes',
      noBtnName: 'No'
    };
    const subject = new Subject<boolean>();
    const modal = this.modalService.show(ConfirmLeaveComponent,  mos);
    modal.content.subject = subject;
    modal.content.subject.asObservable().subscribe(value1 => {
      if (value1 === true) {
        this.canNavigateWithoutConfirm = true;
        this.akas.splice(idx, 1);
        this.commonService.getOfrsReport().getSuspects()[+this.idxString].akas = this.akas;
      } else {
        return;
      }}
    );
  }

  addSuspect(value): void {
    this.errorMessage = '';

    if (this.suspects == null) { // user clicks New to add first suspect
      this.suspects = new Array(this.workingSuspect);
      this.workingSuspectIndex = 0;
    } else if (this.idxString == null) { // user clicks New while there are existing suspects
      this.suspects.push(this.workingSuspect);
      this.workingSuspectIndex = this.suspects.length - 1;
    }
    // else { // user edits existing suspect
    //   this.workingSuspectIndex = +this.idxString;
    // }
    this.suspects[this.workingSuspectIndex].entityType = this.detailForm.get('entityType').value; // value.entityType returns undefined when updating a suspect)
    this.suspects[this.workingSuspectIndex].partyType = value.partyType;
    this.suspects[this.workingSuspectIndex].partyTypeOtherDesc = value.partyTypeOtherDesc;

    this.suspects[this.workingSuspectIndex].firstName = value.firstName;
    this.suspects[this.workingSuspectIndex].middleName = value.middleName;
    this.suspects[this.workingSuspectIndex].lastName = value.lastName;
    this.suspects[this.workingSuspectIndex].gender = value.gender;
    this.suspects[this.workingSuspectIndex].ssn = value.ssn;
    this.suspects[this.workingSuspectIndex].birthDate = value.birthDate ? moment(value.birthDate).format('YYYY-MM-DD') : null;
    this.suspects[this.workingSuspectIndex].driverLicenseNumber = value.driverLicenseNumber;
    this.suspects[this.workingSuspectIndex].driverLicenseState = value.driverLicenseState;
    this.suspects[this.workingSuspectIndex].occupation = value.occupation;
    this.suspects[this.workingSuspectIndex].tin = value.tin;
    this.suspects[this.workingSuspectIndex].ein = value.ein;
    this.suspects[this.workingSuspectIndex].npi = value.npi;

    this.suspects[this.workingSuspectIndex].subjectOfInvestigation = value.subjectOfInvestigation;
    this.suspects[this.workingSuspectIndex].businessName = value.businessName;
    this.suspects[this.workingSuspectIndex].address.addressType = value.addressType;
    this.suspects[this.workingSuspectIndex].address.addressTypeOtherDesc = value.addressTypeOtherDesc;
    this.suspects[this.workingSuspectIndex].address.address1 = value.address1;
    this.suspects[this.workingSuspectIndex].address.address2 = value.address2;
    this.suspects[this.workingSuspectIndex].address.city = value.city;
    this.suspects[this.workingSuspectIndex].address.county = value.county;
    this.suspects[this.workingSuspectIndex].address.state = value.state;
    this.suspects[this.workingSuspectIndex].address.zip = value.zip;
    this.suspects[this.workingSuspectIndex].email = value.email;
    this.suspects[this.workingSuspectIndex].homePhone = value.homePhone;
    this.suspects[this.workingSuspectIndex].cellPhone = value.cellPhone;
    this.suspects[this.workingSuspectIndex].busPhone = value.busPhone;
    this.suspects[this.workingSuspectIndex].busPhoneExt = value.busPhoneExt;
    this.suspects[this.workingSuspectIndex].fax = value.fax;
    this.suspects[this.workingSuspectIndex].vehicle.year = value.vehicleYear;
    this.suspects[this.workingSuspectIndex].vehicle.make = value.vehicleMake;
    this.suspects[this.workingSuspectIndex].vehicle.model = value.vehicleModel;
    this.suspects[this.workingSuspectIndex].vehicle.vin = value.vehicleVin;
    this.suspects[this.workingSuspectIndex].vehicle.licensePlate = value.vehicleLicPlate;
    this.suspects[this.workingSuspectIndex].vehicle.licenseState = value.vehicleLicState;
    this.suspects[this.workingSuspectIndex].employer.name = value.employerName;
    this.suspects[this.workingSuspectIndex].employer.address.address1 = value.employerAddress1;
    this.suspects[this.workingSuspectIndex].employer.address.address2 = value.employerAddress2;
    this.suspects[this.workingSuspectIndex].employer.address.city = value.employerCity;
    this.suspects[this.workingSuspectIndex].employer.address.county = value.employerCounty;
    this.suspects[this.workingSuspectIndex].employer.address.state = value.employerState;
    this.suspects[this.workingSuspectIndex].employer.address.zip = value.employerZip;
    this.suspects[this.workingSuspectIndex].employer.busPhone = value.employerPhone;
    this.suspects[this.workingSuspectIndex].employer.busPhoneExt = value.employerPhoneExe;
    this.suspects[this.workingSuspectIndex].employer.fax = value.employerfax;
    this.suspects[this.workingSuspectIndex].reportedInjury = value.reportedInjury;
    this.suspects[this.workingSuspectIndex].comments = value.comments;
    this.commonService.getOfrsReport().suspects = this.suspects;
    this.commonService.setAvailableTabs(this.visibleTabs);
    this.canNavigateWithoutConfirm = true;
    this.router.navigate(['suspects']);
  }
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.canNavigateWithoutConfirm || !this.detailForm.dirty) {
      return true;
    }
    return false;
    // return this.dialogService.confirm(this.confirmationMessage);
  }

  startNewAlias(): void {
    const path = 'suspects/suspect/' + this.idxString + '/aka/' + this.suspects[this.workingSuspectIndex].entityType;
    this.router.navigate([path]);
  }

  displayAkaName(first: string, middle: string, last: string, business: string): string {
    const displayName = this.commonService.displayName(this.workingSuspect.entityType, first, middle, last, business);
    return displayName.trim().length > 0 ? displayName.trim() : 'Unnamed Alias';
  }

  formatSsn() {
    const ssnParts = this.detailForm.value.ssn.replace(/[^\d]/gi, '').match(/(\d{0,3})(\d{0,2})(\d{0,4})/);
    const formattedSsn = !ssnParts[2] ? ssnParts[1] : ssnParts[1] + '-' + ssnParts[2]
      + (ssnParts[3] ? '-' + ssnParts[3] : '');
    this.detailForm.get('ssn').setValue(formattedSsn);
  }

}
