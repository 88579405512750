<div class="row">
  <div class="col-2">
    <div class="row">
      <app-navigation [visibleItems]="visibleTabs"></app-navigation>
    </div>
  </div>

  <div class="col-10">
    <h2>Attachments</h2>

    <div id="dropzone" class="row" [class.highlighted]="highlighted"
        (dragover)="onDragOver($event)"
        (dragleave)="highlighted = false"
        (dragexit)="highlighted = false"
        (drop)="onFilesDropped($event)">
      <input id="fileInput" #fileInput type="file" multiple (input)="onFilesDropped($event)"/>
      <div class="col-5">
        <div><fa-icon size="4x" [icon]="faUpload"></fa-icon></div>
        <div>Drag and drop file here</div>
      </div>
      <div class="col-2 align-self-center">or</div>
      <div class="col-5 align-self-center">
        <button type="button" class="btn btn-primary" (click)="fileInput.click()">
          <fa-icon [icon]="faFolderOpen"></fa-icon>&nbsp; Browse...
        </button>
      </div>
    </div>

    <div *ngIf="attachments.length > MAX_FILE_COUNT" class="alert alert-danger">
      <b>Too many files</b> - Only a maximum of {{MAX_FILE_COUNT}} files are permitted.<br/>
    </div>

    <ng-template #thumbnailPopover>
      <img [src]="thumbnail"/>
    </ng-template>

    <div *ngFor="let attachment of attachments; let idx = index"
          class="row fileRow" [class.fileError]="attachment.hasErrors">
      <div class="col-1">
        <fa-icon size="4x" [icon]="attachment.icon"></fa-icon>
      </div>
      <div class="col-8 align-self-center">
        <a *ngIf="attachment.thumbnail" href="javascript:void(0);"
           [popover]="thumbnailPopover"
           (mouseenter)="thumbnail = attachment.thumbnail"
           (click)="openInNewTab(attachment)"
           triggers="mouseenter:mouseleave">
          <b>{{attachment.name}}</b>
        </a>
        <a *ngIf="!attachment.thumbnail" href="javascript:void(0);"
          (click)="openInNewTab(attachment)"><b>{{attachment.name}}</b></a>
        <div [ngSwitch]="attachment.status">
          <span *ngSwitchCase="'PENDING'" class="spinner-border spinner-border-sm" role="status"
                aria-hidden="true" style="vertical-align: initial"></span>
          <progressbar *ngSwitchCase="'UPLOADING'" type="primary" [striped]="true" [animate]="true"
                       [value]="attachment.bytesUploaded" [max]="attachment.size">
            <span class="progress-bar-text">{{attachment.progressString}}</span>
          </progressbar>
          <span *ngSwitchDefault>{{attachment.formattedSize}}</span>
        </div>
      </div>
      <div class="col-3 text-right align-self-center">
        <b>{{attachment.lastModified | date}}</b>
        <button type="button" class="btn remove-button" (click)="removeAttachment(idx)" >
          <fa-icon size="lg" [icon]="faTimes"></fa-icon>
        </button>
      </div>
      <div *ngIf="!attachment.isTypeValid" class="fileErrorMessage">
        This file is not an approved file type.
      </div>
      <div *ngIf="!attachment.isSizeValid" class="fileErrorMessage">
        File size must be less than {{MAX_FILE_SIZE_STRING}}.
      </div>
      <div *ngIf="attachment.isFailed" class="fileErrorMessage">
        Upload failed due to connection problems. Please try again.
      </div>
    </div>

    <div class="text-center">
      <button type="button" id="attachmentsNextButton" class="btn btn-success"
              [disabled]="hasErrors || inProgress"
              (click)="next()">Next</button>
    </div>
  </div>
</div>
