<div id="otherAgenciesDetailContainer" class="row">
  <div id="navigationContainer" class="col-2">
    <div class="row float-left">
      <app-navigation [visibleItems]="visibleTabs"></app-navigation>
    </div>
  </div>
  <div class="col-10 pl-sm-6">
    <div class="row">
      <div class="col-9 ">
        <h2>Other Agency Details</h2>
      </div>
    </div>

    <div id="otherAgencyDetailsMessage" class="alert alert-danger row" *ngIf="errorMessage">{{ errorMessage }}
    </div>

    <form [formGroup]="detailForm">
      <div class="form-group row">
        <label for="agencyType" class="col-form-label col-sm-3 text-right">Agency Type: <span class="red-text">*</span></label>
        <div class="col-sm-6">
          <select title="Agency Type" formControlName="agencyType" (change)="changeAgencyOtherDescAttribute()" required class="form-control" id="agencyType" name="agencyType">
            <option *ngFor="let decode of agencyTypeDecodes" [value]="decode.agencyCode">{{ decode.agencyName }}</option>
          </select>
          <div id="agencyTypeError" *ngIf="agencyType.invalid && (agencyType.dirty || agencyType.touched)" class="alert alert-danger">
            <div *ngIf="agencyType.errors">
              <div *ngFor="let error of errors(agencyType)">
                Agency Type{{ error }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="'OTHER' === agencyType.value" class="form-group row">
        <label for="agencyTypeOtherDesc" class="col-form-label col-sm-3 text-right" >Description if Other: <span class="red-text">*</span></label>
        <div class="col-sm-6">
          <input type="text" title="Agency Other Desc" formControlName="agencyTypeOtherDesc" required class="form-control" id="agencyTypeOtherDesc" name="agencyTypeOtherDesc"/>
          <div id="agencyTypeOtherDescError" *ngIf="agencyTypeOtherDesc.invalid && (agencyTypeOtherDesc.dirty || agencyTypeOtherDesc.touched)" class="alert alert-danger">
            <div *ngIf="agencyTypeOtherDesc.errors">
              <div *ngFor="let error of errors(agencyTypeOtherDesc)">
                Description{{ error }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label for="agencyName" class="col-sm-3 col-form-label text-right">Agency Name: <span class="red-text">*</span></label>
        <div class="col-sm-6">
          <input id="agencyName" name="agencyName" formControlName="agencyName" required type="text" class="form-control"/>
          <div id="agencyNameError" *ngIf="agencyName.invalid && (agencyName.dirty || agencyName.touched)" class="alert alert-danger">
            <div *ngIf="agencyName.errors">
              <div *ngFor="let error of errors(agencyName)">
                Agency Name{{ error }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label for="agencyCase" class="col-sm-3 col-form-label text-right">Agency Case/Claim ID:</label>
        <div class="col-sm-6">
          <input id="agencyCase" name="agencyCase" formControlName="agencyCase" type="text" class="form-control"/>
          <div id="agencyCaseError" *ngIf="agencyCase.invalid && (agencyCase.dirty || agencyCase.touched)" class="alert alert-danger">
            <div *ngIf="agencyCase.errors">
              <div *ngFor="let error of errors(agencyCase)">
                Agency Case{{ error }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label for="agencyContact" class="col-form-label col-sm-3 text-right">Agency Contact: </label>
        <div class="col-sm-6">
          <input id="agencyContact" name="agencyContact" formControlName="agencyContact" type="text" class="form-control" />
          <div id="agencyContactError" *ngIf="agencyContact.invalid && (agencyContact.dirty || agencyContact.touched)" class="alert alert-danger">
            <div *ngIf="agencyContact.errors">
              <div *ngFor="let error of errors(agencyContact)">
                Agency Contact{{ error }}
              </div>
            </div>
          </div>
        </div>
      </div>


      <app-address
        [formGroup]="detailForm"
        [isAddressTypeHidden]="true"
        [colWidth]="6"
        [errorMessages]="validationService.getValidationMessages()"
        [jurisdictions]="jurisdictions">
      </app-address>

      <div class="form-group row">
        <label class="col-form-label col-sm-3 text-right">Email:</label>
        <div class="col-sm-6">
          <input id="email" name="email" formControlName="email" type="text" class="form-control"
          />
          <div
            id="emailError"
            *ngIf="email.invalid && (email.dirty || email.touched)"
            class="alert alert-danger"
          >
            <div *ngIf="email.errors">
              <div *ngFor="let error of errors(email)">Email{{ error }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-form-label col-sm-3 text-right">Business Phone:</label>
        <div class="col-sm-3">
          <input
            id="busPhone"
            name="busPhone"
            formControlName="busPhone"
            placeholder="999-999-9999"
            type="text"
            class="form-control"
          />
          <div
            id="busPhoneError"
            *ngIf="busPhone.invalid && (busPhone.dirty || busPhone.touched)"
            class="alert alert-danger"
          >
            <div *ngIf="busPhone.errors">
              <div *ngFor="let error of errors(busPhone)">
                Business Phone{{ error }}
              </div>
            </div>
          </div>
        </div>
        <label class="col-form-label col-sm-1 text-right">Ext:</label>
        <div class="col-sm-2">
          <input
            id="ext"
            name="busPhoneExt"
            formControlName="busPhoneExt"
            type="text"
            class="form-control"
          />
          <div
            id="busPhoneExtError"
            *ngIf="
              busPhoneExt.invalid && (busPhoneExt.dirty || busPhoneExt.touched)
            "
            class="alert alert-danger"
          >
            <div *ngIf="busPhoneExt.errors">
              <div *ngFor="let error of errors(busPhoneExt)">
                Ext{{ error }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-form-label col-sm-3 text-right">Fax Number:</label>
        <div class="col-sm-6">
          <input
            id="fax"
            name="fax"
            formControlName="fax"
            placeholder="999-999-9999"
            type="text"
            class="form-control"
          />
          <div
            id="faxError"
            *ngIf="fax.invalid && (fax.dirty || fax.touched)"
            class="alert alert-danger"
          >
            <div *ngIf="fax.errors">
              <div *ngFor="let error of errors(fax)">Fax{{ error }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-sm-3 text-right">
          <button
            type="button"
            id="CancelOtherAgencyDetailsButton"
            (click)="cancelOtherAgencyDetails()"
            class="btn btn-success .navbar-right"
          >
            Cancel
          </button>
        </div>
        <div class="col-sm-6 text-right">
          <button type="button" id="saveOtherAgencyDetailsButton" (click)="addOtherAgencyDetails()" class="btn btn-success .navbar-right" [disabled]="detailForm.invalid">
            {{saveButtonText()}}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
