<!--reference to ofrs-api-core/src/main/resources/pdf-report-template.html-->
<p class="text-uppercase text-center font-weight-bold" xmlns="http://www.w3.org/1999/html">**Notice: This page is only a draft of your report.**</p>
<p class="text-uppercase text-center font-weight-bold">To submit your report, return to the previous tab and click Submit Report.</p>
<div id="disclaimer" style="position: running(disclaimer);">
  The Online Fraud Reporting System (OFRS) (“System”) is restricted to use
  for legitimate business purposes only. The information collected in this
  database contains Confidential Information. Confidential Information
  means any documents, materials or information that is confidential,
  privileged or otherwise protected from disclosure under the laws applicable
  to the insurance regulator that is the source of the documents, materials
  or information. The Confidential Information contained in this System is
  subject to the Master Information Sharing and Confidentiality Agreement
  between the states. The information in this System may come from third
  parties and is provided 'AS IS.'&nbsp; The NAIC makes no guarantee as to the
  truth, accuracy, or completeness of the information. The NAIC makes no
  guarantee or representation regarding the status of the data reported by
  the participating states or absence of data that is not reported to this
  System. By using this System, you hereby agree to abide by the Master
  Information Sharing and Confidentiality Agreement and you also understand
  that improper access, use, disclosure, dissemination or reproduction of the
  System or its content may result in immediate termination of access and
  other disciplinary or legal action.
</div>
<div class="d-flex flex-row justify-content-between my-4">
  <div >
    <span>FRAUD REPORT ID: </span>
    <span >{{ofrsReport?.ofrsReportKey.reportId}}</span>
  </div>
  <div >
    <span>REPORT TYPE: </span>
      <span >Consumer</span>
  </div>
  <div >
    <span>DATE SUBMITTED: </span>
    <span >{{ofrsReport?.ofrsReportKey.dateSubmitted | date}}</span>
  </div>
</div>
<div class="section-title">
  UNIFORM SUSPECTED INSURANCE FRAUD REPORTING FORM
</div>
<div>
  <div class="text-center text-uppercase" style="background-color: yellow;">
    For State Use Only
  </div>
  <div>
    <span class="font-weight-bold">Division of Insurance - Fraud Bureau</span>
  </div>
  <div >
    <span class="font-weight-bold">State(s) of Claim: </span>
      <span>{{ofrsReport?.states}}</span>
  </div>
</div>
<section>
  <div class="section-title">
    Reporting Person Information
  </div>
  <div>

    <div>
      <div ><span class="font-weight-bold">Name and Title: </span>
        <span >{{ofrsReport?.reporter?.firstName}} </span>
        <span >{{ofrsReport?.reporter?.middleName}} </span>
        <span>{{ofrsReport?.reporter?.lastName}} </span>
        <span>{{ofrsReport?.reporter?.title}}</span><br/>
      </div>
    </div>
    <div>
      <div >
        <span class="font-weight-bold">Mailing address (include dept.): </span>
        <span>{{ofrsReport?.reporter?.department}} </span>
        <span>{{getCleanAddress(ofrsReport?.reporter?.address)}}</span>
      </div>
      <div >
        <div><span class="font-weight-bold">Home Number: </span>{{formatPhoneNumber(ofrsReport?.reporter?.homePhone)}}</div>
        <div><span class="font-weight-bold">Cell Number: </span>{{formatPhoneNumber(ofrsReport?.reporter?.cellPhone)}}</div>
        <div>
          <span class="font-weight-bold">Business Number: </span>{{formatPhoneNumber(ofrsReport?.reporter?.busPhone)}}
          <span *ngIf="ofrsReport?.reporter?.busPhoneExt != null && ofrsReport?.reporter?.busPhoneExt != ''" class="font-weight-bold">EXT: </span>{{ofrsReport?.reporter?.busPhoneExt}}
        </div>
        <div><span class="font-weight-bold">Fax Number: </span>{{formatPhoneNumber(ofrsReport?.reporter?.fax)}}</div>
        <div><span class="font-weight-bold">Email Address: </span>{{ofrsReport?.reporter?.email}}</div>
      </div>
    </div>
  </div>
</section>
<section *ngIf="ofrsReport?.suspects != null && ofrsReport?.suspects.length > 0">
  <div class="section-title">
  Subject Information
  </div>
  <div *ngFor="let suspect of ofrsReport?.suspects; let suspectNumber = index; let final = last">
    <h4 class="text-center text-uppercase my-2">Suspect #{{incrementIndexForLabel(suspectNumber)}}</h4>
    <div>
      <span><span class="font-weight-bold">Type: </span>{{suspect.partyType}}
          <span *ngIf="suspect.partyType == 'OTHER' && suspect.partyTypeOtherDesc != null"> - {{suspect.partyTypeOtherDesc}}</span>
        </span>
          <div>
            <span class="font-weight-bold">Last Name / Business Name: </span>{{suspect.lastName}}
          <span *ngIf="suspect.lastName != null && suspect.lastName != '' && suspect.businessName != null && suspect.businessName != ''">/</span>
          {{suspect.businessName}}
          </div>
      <div><span class="font-weight-bold">First Name: </span>{{suspect.firstName}}</div>
      <div><span class="font-weight-bold">Middle Name: </span>{{suspect.middleName}}</div>
      </div>
    <div><span class="font-weight-bold">Sex: </span>{{suspect.gender}}</div>
    <div><span class="font-weight-bold">Date of Birth: </span>{{suspect.birthDate | date}}</div>
    <div><span class="font-weight-bold">SSN: </span>{{maskSSNIdentifier(suspect.ssn)}}</div>
    <div>
      <span class="font-weight-bold">Is Suspect of Investigation: </span>
      <span [ngSwitch]="suspect.subjectOfInvestigation">
          <span *ngSwitchCase="'Y'">Yes</span>
          <span *ngSwitchCase="'N'">No</span>
          <span *ngSwitchDefault>Unknown</span>
        </span>
    </div>
    <div><span class="font-weight-bold">Occupation: </span>{{suspect.occupation}}</div>
      <div>
        <div><span class="font-weight-bold">Address Type: </span>{{suspect.address?.addressType}}</div>
        <div><span class="font-weight-bold">Street Address (include PO Box and apartment #'s): </span>
          {{getCleanAddress(suspect.address)}}
        </div>
          <div *ngIf="suspect.driverLicenseNumber != null && suspect.driverLicenseNumber != ''
           && suspect.driverLicenseState != null && suspect.driverLicenseState != ''">
            <div><span class="font-weight-bold">Driver's License # & State: </span>
            {{suspect.driverLicenseNumber}} / {{suspect.driverLicenseState}}
          </div>
        </div>
      </div>
      <div >
        <div *ngIf="suspect.homePhone" >
          <span class="font-weight-bold">Home Phone Number: </span>
          <span>{{formatPhoneNumber(suspect.homePhone)}}</span>
        </div>
        <div *ngIf="suspect.cellPhone" >
          <span class="font-weight-bold">Cell Phone Number: </span>
        <span>{{formatPhoneNumber(suspect.cellPhone)}}</span>
      </div>
        <div *ngIf="suspect.busPhone != null" >
          <span class="font-weight-bold">Business Phone Number: </span>
          <span>{{formatPhoneNumber(suspect.busPhone)}}
            <span *ngIf="suspect.busPhoneExt != null && suspect.busPhoneExt != ''" class="font-weight-bold">EXT: </span>{{suspect.busPhoneExt}}
          </span>
        </div>
        <div *ngIf="suspect.fax != null" >
          <span class="font-weight-bold">Fax Number: </span>
          <span>{{formatPhoneNumber(suspect.fax)}}</span>
        </div>

      </div>
    <div *ngIf="suspect.akas.length > 0">
      <h4 class="text-center text-uppercase my-2">Additional Aliases for Suspect #{{incrementIndexForLabel(suspectNumber)}}</h4>
      <div *ngFor="let alias of suspect.akas; let aliasNumber = index">
        <div class="font-weight-bold my-2">Alias #{{incrementIndexForLabel(aliasNumber)}}</div>
        <div>
          <span class="font-weight-bold">Last Name / Business Name: </span>{{alias.lastName}}
          <span *ngIf="alias.lastName != null && alias.lastName != '' && alias.businessName != null && alias.businessName != ''">/</span>
          {{alias.businessName}}
        </div>
        <div><span class="font-weight-bold">First Name: </span>{{alias.firstName}}</div>
        <div><span class="font-weight-bold">Middle Name: </span>{{alias.middleName}}</div>
        <div><span class="font-weight-bold">Sex: </span>{{alias.gender}}</div>
        <div><span class="font-weight-bold">Date of Birth: </span>{{alias.birthDate | date}}</div>
        <div><span class="font-weight-bold">SSN: </span>{{maskSSNIdentifier(alias.ssn)}}</div>
        <div><span class="font-weight-bold">Occupation: </span>{{alias.occupation}}</div>
      <div>
        <div><span class="font-weight-bold">Address Type: </span>{{alias.address?.addressType}}</div>
        <div><span class="font-weight-bold">Street Address (include PO Box and apartment #'s): </span>
          {{getCleanAddress(alias.address)}}
        </div>
        <div *ngIf="alias.driverLicenseNumber != null && alias.driverLicenseNumber != ''
           && alias.driverLicenseState != null && alias.driverLicenseState != ''">
          <span class="font-weight-bold">Driver's License # & State: </span>
            {{alias.driverLicenseNumber}} / {{alias.driverLicenseState}}
        </div>
      </div>
      <div >
        <div *ngIf="alias.homePhone" >
          <span class="font-weight-bold">Home Phone Number: </span>
          <span>{{formatPhoneNumber(alias.homePhone)}}</span>
        </div>
        <div *ngIf="alias.cellPhone" >
          <span class="font-weight-bold">Cell Phone Number: </span>
          <span>{{formatPhoneNumber(alias.cellPhone)}}</span>
        </div>
        <div *ngIf="alias.busPhone != null" >
          <span class="font-weight-bold">Business Phone Number: </span>
          <span>{{formatPhoneNumber(alias.busPhone)}}
            <span *ngIf="alias.busPhoneExt != null && alias.busPhoneExt != ''" class="font-weight-bold">EXT: </span>{{alias.busPhoneExt}}
          </span>
        </div>
        <div *ngIf="alias.fax != null" >
          <span class="font-weight-bold">Fax Number: </span>
          <span>{{formatPhoneNumber(alias.fax)}}</span>
        </div>

      </div>
    </div>
  </div>


<!--    <div *ngIf="suspect.employer != null">-->
<!--      <h4 class="text-center text-uppercase my-2">Employer Information (if applicable): {{suspect.employer.name}}</h4>-->
<!--      <div >-->
<!--        <span *ngIf="suspect.employer.address?.address1 != null && suspect.employer.address?.address1 != ''-->
<!--        && suspect.employer.address?.address2 != null && suspect.employer.address?.address2 != ''">-->
<!--          <span class="font-weight-bold">Address: </span>-->
<!--          <span *ngIf="suspect.employer.address?.address1 != null && suspect.employer.address?.address1 != ''">-->
<!--            {{suspect.employer.address?.address1}}-->
<!--          </span>-->
<!--          <span *ngIf="suspect.employer.address?.address2 != null && suspect.employer.address?.address2 != ''">-->
<!--            {{suspect.employer.address?.address2}}-->
<!--          </span>-->
<!--        </span>-->
<!--        <div *ngIf="suspect.employer.address?.city != null && suspect.employer.address?.city != ''">-->
<!--          <span class="font-weight-bold">City: </span>-->
<!--          <span>{{suspect.employer.address?.city}}</span>-->
<!--        </div>-->
<!--        <div *ngIf="suspect.employer.address?.state != null && suspect.employer.address?.state != ''">-->
<!--          <span class="font-weight-bold">State:</span>-->
<!--          <span>{{suspect.employer.address?.state}}</span>-->
<!--        </div>-->
<!--        <div *ngIf="suspect.employer.address?.zip != null && suspect.employer.address?.zip != ''">-->
<!--          <span class="font-weight-bold">Zip: </span>-->
<!--          <span>{{suspect.employer.address?.zip}}</span>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div *ngIf="suspect.employer.busPhone != null && suspect.employer.busPhone != ''">-->
<!--        <span class="font-weight-bold">Phone #</span> {{suspect.employer.busPhone}} <span class="font-weight-bold">Ext</span> {{suspect.employer.busPhoneExt}}-->
<!--      </div>-->
<!--    </div>-->
<!--    <h4 class="text-uppercase text-center my-2">Other Information</h4>-->
<!--    <div><span class="font-weight-bold">Reported Injuries: </span>{{suspect.reportedInjury}}</div>-->
    <h4 class="text-uppercase text-center my-2">Additional Comments: (ex. other ID information and source)</h4>
    <p>{{suspect.comments}}</p>

    <div *ngIf="!final">
      <div class="suspect-boundary"></div>
    </div>

  </div>

</section>
<section>
  <div class="section-title | text-uppercase">
    Claim/Incident Information
  </div>
  <div class="section-title">(all financial information and dates of service are considered approximate)</div>
  <div>
    <div><span class="font-weight-bold">Potential Loss Amount: </span>
      <span *ngIf="ofrsReport?.claimInformation.suspectedFraudAmt != null">
        {{ofrsReport?.claimInformation.suspectedFraudAmt}}
      </span>
      <span *ngIf="ofrsReport?.claimInformation.suspectedFraudAmt == null">
        <span [ngSwitch]="ofrsReport?.claimInformation.lossRangeId">
          <span *ngSwitchCase="1">$1 - $5,000</span>
          <span *ngSwitchCase="2">$5,001 - $25,000</span>
          <span *ngSwitchCase="3">$25,001 - $75,000</span>
          <span *ngSwitchCase="4">$75,001+</span>
          <span *ngSwitchDefault>Unknown</span>
        </span>
      </span>
    </div>
    <div><span class="font-weight-bold">Date of Loss / Injury: </span>
      <span *ngIf="ofrsReport?.claimInformation.lossDate != null">
        {{ofrsReport?.claimInformation.lossDate | date}}
        </span>
      <span *ngIf="ofrsReport?.claimInformation.lossDate == null && ofrsReport?.claimInformation.approxLossDate != null">
        {{ofrsReport?.claimInformation.approxLossDate}}
      </span>
    </div>
  </div>
</section>
<!-- TODO: Implement if necessary for industry -->
<!--<section>-->
<!--  <div class="section-title">-->
<!--    Evidence-->
<!--  </div>-->
<!--  <div *ngIf="ofrsReport?.evidenceTypes.evidenceTypeIds.length > 0 "><p>{{ofrsReport?.evidenceTypes.evidenceTypeIds}}</p></div>-->
<!--</section>-->
<!--<section>-->
<!--  <div class="section-title">-->
<!--    Suspected Fraud Types-->
<!--  </div>-->
<!--  <div >{{ofrsReport?.fraudTypes}}</div>-->
<!--</section>-->
<section *ngIf="ofrsReport?.investigationSummary.overview != null && ofrsReport?.investigationSummary.overview != ''">
  <div class="section-title">
    Detailed Synopsis
  </div>
  <p>{{ofrsReport?.investigationSummary.overview}}</p>
</section>
<section>
  <div class="section-title">
    All Suspect Party Types
  </div>

  <ul>
    <li *ngFor="let partyType of getDistinctSuspectPartyTypes(ofrsReport?.suspects)">
      {{partyType}}
    </li>
  </ul>
</section>

<section *ngIf="ofrsReport?.attachments?.length > 0">
  <div class="section-title">
    Attachments
  </div>
  <ul >
    <li *ngFor="let attachment of ofrsReport?.attachments">
      <span class="font-weight-bold">File Name: </span> <span class="font-italic">{{attachment.name}}</span> - <span class="font-weight-bold">File Size: </span>{{convertToKiloBytes(attachment.size)}} KB
    </li>
  </ul>
</section>

<p class="text-uppercase text-center font-weight-bold" xmlns="http://www.w3.org/1999/html">**Notice: This page is only a draft of your report.**</p>
<p class="text-uppercase text-center font-weight-bold">To submit your report, return to the previous tab and click Submit Report.</p>
