import {Reporter} from './reporter.model';
import {FraudTypes} from './fraud-types.model';
import {Party} from './party.model';
import {InvestigationSummary} from './investigation-summary.model';
import {EvidenceTypes} from './evidence-types.model';
import { ClaimInformation } from './claim-information.model';
import {OtherAgencyDetails} from './other-agency-details.model';
import {OfrsReportKey} from './ofrs-report-key.model';
import {Attachment} from '../attachments/attachment.model';
import {cloneDeep, cloneDeepWith, forEach} from 'lodash';


export class OfrsReport {
  ofrsReportKey: OfrsReportKey;
  states: string[];
  reporter: Reporter;
  fraudTypes: FraudTypes;
  suspects: Party[];
  investigationSummary: InvestigationSummary;
  evidenceTypes: EvidenceTypes;
  claimInformation: ClaimInformation;
  otherAgencies: OtherAgencyDetails[];
  attachments: Attachment[];

  constructor(obj?: any) {
    obj = obj || {};
    this.ofrsReportKey = new OfrsReportKey(obj.ofrsReportKey);
    this.states = cloneDeep(obj.states) || [];
    this.reporter = cloneDeep(obj.reporter) || new Reporter();
    this.fraudTypes = new FraudTypes(obj.fraudTypes);
    this.suspects = cloneDeep(obj.suspects) || [];
    this.investigationSummary = new InvestigationSummary(obj.investigationSummary);
    this.evidenceTypes = new EvidenceTypes(obj.evidenceTypes);
    this.claimInformation = new ClaimInformation(obj.claimInformation);
    this.otherAgencies = cloneDeep(obj.otherAgencies) || [];
    this.attachments = Array.isArray(obj.attachments) && obj.attachments
      .filter(v => v != null).map(v => new Attachment(v)) || [];
  }

  getOfrsReportKey(): OfrsReportKey {
    return this.ofrsReportKey;
  }

  setOfrsReportKey(value: OfrsReportKey) {
    this.ofrsReportKey = value;
  }

  getStates(): string[] {
    return this.states;
  }

  setStates(value: string[]) {
    this.states = value;
  }

  getFraudTypes(): FraudTypes {
    return this.fraudTypes;
  }

  setFraudTypes(value: FraudTypes) {
    this.fraudTypes = value;
  }

  public getEvidenceTypes(): EvidenceTypes {
    return this.evidenceTypes;
  }

  public setEvidenceTypes(value: EvidenceTypes) {
    this.evidenceTypes = value;
  }

  public getReporter(): Reporter {
      return this.reporter;
  }
  public setReporter(reporter: Reporter) {
      this.reporter = reporter;
  }

  public getSuspects(): Party[] {
    return this.suspects;
  }

  public setSuspects(value: Party[]) {
    this.suspects = value;
  }

  public getInvestigationSummary(): InvestigationSummary {
    return this.investigationSummary;
  }

  public setInvestigationSummary(value: InvestigationSummary) {
    this.investigationSummary = value;
  }
  public getOtherAgencies(): OtherAgencyDetails[] {
    return this.otherAgencies;
  }

  public setOtherAgencies(value: OtherAgencyDetails[]) {
    this.otherAgencies = value;
  }
}
