import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import * as _ from 'lodash';

@Component({
  selector: 'app-contact-electronic-info',
  templateUrl: './contact-electronic-info.component.html',
  styleUrls: ['./contact-electronic-info.component.css']
})
export class ContactElectronicInfoComponent implements OnInit {
  @Input() prefix: string;
  @Input() formGroup: FormGroup;
  @Input() requiredFields: string[] = [];
  @Input() errorMessages: any;
  // @Input() displayedFields: string[] = [];

  constructor() { }

  ngOnInit() {
  }

  getErrorMessage(fieldName: string, errorType: string): string {
    const fieldErrors = this.errorMessages[fieldName];
    return fieldErrors[errorType];
  }

  isRequired(id: string): boolean {
    return this.requiredFields.indexOf(id) >= 0;
  }

  getId(fieldId: string): string {
    return this.prefix ? _.camelCase(`${this.prefix} ${fieldId}`) : fieldId;
  }

  get email() {
    return this.formGroup.get('email');
  }

  get homePhone() {
    return this.formGroup.get('homePhone');
  }

  get cellPhone() {
    return this.formGroup.get('cellPhone');
  }

  get busPhone() {
    return this.formGroup.get('busPhone');
  }

  get busPhoneExt() {
    return this.formGroup.get('busPhoneExt');
  }

  get fax() {
    return this.formGroup.get('fax');
  }
}
