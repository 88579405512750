import {ValidatorFn, AbstractControl, FormGroup, ValidationErrors} from '@angular/forms';
import * as moment from 'moment';

export const claimServiceValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  const beginDate = control.get('beginDate');
  const endDate = control.get('endDate');
  if (beginDate.value === null || beginDate.value === undefined || endDate.value === null || endDate.value === undefined) {
    return null;
  }

  if (!(beginDate.value instanceof Date) || !(endDate.value instanceof Date)) {
    return null;
  }
  const isValid = moment(beginDate.value).isSameOrBefore(moment(endDate.value));
  return isValid ? null : { 'beingEndDates': true };
};

