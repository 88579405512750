import * as moment from 'moment';

export class Dates {

  public static format(d: Date|string): string {
    if (d instanceof Date) {
      return moment(d).format('YYYY-MM-DD');
    }
    return d;
  }

  public static parse(s: Date|string): Date {
    if (s instanceof Date) {
      return s;
    }
    return s ? moment(s, moment.ISO_8601).toDate() : null;
  }

  public static copy(d: Date): Date {
    return moment(d).toDate();
  }
}
