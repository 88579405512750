export class NavItem {
  id: string;
  name: string;
  link: string;
  // visible: boolean;
  // active?: boolean;

  constructor(id: string, name: string, link: string) {
    this.id = id;
    this.name = name;
    this.link = link;
    // this.active = active || false;
    // this.visible = visible || false;
  }
}
