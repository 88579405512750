import { Component, OnInit } from '@angular/core';
import {Observable} from 'rxjs';
import {CommonService} from '../shared/common.service';
import {FormArray, FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {EvidenceTypes} from '../shared/evidence-types.model';
import {EvidenceDecode} from '../shared/evidenceDecode.model';

@Component({
  selector: 'app-evidence-type',
  templateUrl: './evidence-type.component.html',
  styleUrls: ['./evidence-type.component.css']
})
export class EvidenceTypeComponent implements OnInit {
  errorMessage: string;
  otherDescriptionErrorMessage: string;
  evidenceTypes: EvidenceTypes;
  allEvidenceDecodes: EvidenceDecode[];
  formGroup: FormGroup;
  stored = false;
  selectedEvidenceDecodes: number[];
  evidenceOtherDesc: string;
  splitIndex: number;
  firstHalfEvidenceDecodes: EvidenceDecode[];
  secondHalfEvidenceDecodes: EvidenceDecode[];
  visibleTabs: string[];

  constructor(private commonService: CommonService, private formBuilder: FormBuilder, private router: Router) {
    this.visibleTabs = this.commonService.getAvailableTabs().slice();
    this.evidenceTypes = this.commonService.getOfrsReport().evidenceTypes;
    if (this.evidenceTypes.evidenceTypeIds === null) {
      this.evidenceTypes.evidenceTypeIds = [];
    }
    if (this.evidenceTypes.evidenceOtherDesc === null) {
      this.evidenceTypes.evidenceOtherDesc = '';
    }
  }

  ngOnInit(): void {
    if (this.visibleTabs.indexOf('evidenceTypeNav') === -1) {
      this.visibleTabs.push('evidenceTypeNav');
    }
    this.commonService.getEvidenceDecodes()
      .subscribe(data => {
        this.allEvidenceDecodes = data;
        this.createTwoArrays();
        this.createEvidenceTypesForm();
      });


  }
  createTwoArrays() {
    this.splitIndex = Math.ceil(this.allEvidenceDecodes.length / 2);
    this.firstHalfEvidenceDecodes = this.allEvidenceDecodes.slice(0, this.splitIndex);
    this.secondHalfEvidenceDecodes = this.allEvidenceDecodes.slice( this.splitIndex);
  }

  createEvidenceTypesForm(): void {
    const evidenceControls = this.allEvidenceDecodes.map(c =>
      this.evidenceTypes.evidenceTypeIds.includes( c.evidenceTypeId ) ?
        new FormControl(true) : new FormControl(false));
    evidenceControls.push(new FormControl(this.evidenceTypes.evidenceOtherDesc));
    this.formGroup = this.formBuilder.group({
      isNone: [this.evidenceTypes.isNone, ],
      evidenceControlArray: new FormArray(evidenceControls), // Implicitly assign index as the name for each formControl
    });
    this.formGroup.valueChanges.subscribe(data => this.onValueChanged(data));

    this.onValueChanged();
  }

  onValueChanged(data?: any) {
    if (!this.formGroup) {return; }
    // const form = this.formGroup;
    this.hasError();
  }

  hasError(): boolean {
    this.errorMessage = '';
    this.selectedEvidenceDecodes = this.formGroup.value.evidenceControlArray
      .map((v, i) => v ? (i < this.allEvidenceDecodes.length) ? this.allEvidenceDecodes[i].evidenceTypeId : null : null)
      .filter(v => v !== null);
    this.evidenceOtherDesc = this.formGroup.value.evidenceControlArray[this.allEvidenceDecodes.length];

    const total = this.selectedEvidenceDecodes.length;
    if (total === 0 && !this.formGroup.value.isNone) {
      if (this.formGroup.untouched) {
        this.errorMessage = '';
      }
      return true;
    }
    if (this.formGroup.value.isNone && total > 0) {
      this.errorMessage = 'None is invalid with any other selection';
      return true;
    }

    if (total > 0 && this.selectedEvidenceDecodes.includes(this.allEvidenceDecodes[this.allEvidenceDecodes.length - 1].evidenceTypeId)) {
      if (this.evidenceOtherDesc == null || this.evidenceOtherDesc.length === 0) {
        this.otherDescriptionErrorMessage = 'An entry for Other is required';
        return true;
      } else if (this.evidenceOtherDesc.trim().length === 0) {
        this.otherDescriptionErrorMessage = 'Other description cannot contain only whitespace characters';
        return true;
      } else if (this.evidenceOtherDesc.length > 70) {
        this.otherDescriptionErrorMessage = 'Other description cannot be more than 70 characters long';
        return true;
      } else {
        this.otherDescriptionErrorMessage = '';
      }
    }

    return false;
  }

  save(): void {
    this.errorMessage = '';
    this.evidenceTypes.isNone = this.formGroup.value.isNone;
    this.evidenceTypes.evidenceTypeIds = this.selectedEvidenceDecodes;
    this.evidenceTypes.evidenceOtherDesc = this.evidenceOtherDesc;

    this.commonService.getOfrsReport().evidenceTypes = this.evidenceTypes;
    this.commonService.setAvailableTabs(this.visibleTabs);
    this.stored = true;
    this.router.navigate(['attachments']);
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.stored || !this.formGroup.dirty) {
      return true;
    }
    return false;
  }

  // resetting the input text if the chkbox next to other is unchecked
  onCheck(e, i) {
    if ( !e.target.checked) {
      const val = this.formGroup.controls['evidenceControlArray'].value;
      val[i + 1] = '';
      this.formGroup.patchValue({evidenceControlArray: val} );
    }
  }
  onCheckNone(e) {
    if ( e.target.checked) {
      const val = this.formGroup.controls['evidenceControlArray'].value;
      let i = 0;
      for ( i =  0; i < val.length - 1; i++ ) {
        val[i] = false;
      }
      val[val.length - 1] = '';
      this.formGroup.patchValue({evidenceControlArray: val} );
    }
  }
  onTextChange() {
    const val = this.formGroup.controls['evidenceControlArray'].value;
    if  ( val[val.length - 1] || val[val.length - 1].length > 0 ) {
      val[val.length - 2] = true;
      this.formGroup.patchValue({evidenceControlArray: val});
    } else if ( val[val.length - 1].length === 0 ) {
      val[val.length - 2] = false;
      this.formGroup.patchValue({evidenceControlArray: val});
    }
  }




}

