import { Component, OnInit } from '@angular/core';
import {Jurisdiction} from '../shared/jurisdiction.model';
import {CommonService} from '../shared/common.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {selectValidator} from '../shared/validation/select-validator';
import {Router} from '@angular/router';
import {OfrsReport} from '../shared/ofrs-report.model';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-jurisdictions',
  templateUrl: './jurisdictions.component.html',
  styleUrls: ['./jurisdictions.component.css']
})
export class JurisdictionsComponent implements OnInit {

  internalJurisdictions: Jurisdiction[];
  externalJurisdictions: Jurisdiction[];
  jurisdictionsFormGroup: FormGroup;
  ofrsReport: OfrsReport;
  errorMessage: string;
  stored = false;
  visibleTabs: string[];

  constructor(private commonService: CommonService, private formBuilder: FormBuilder, private router: Router) {
    this.visibleTabs = this.commonService.getAvailableTabs().slice();
  }

  ngOnInit(): void {
    // Add jurisdictions to the navigation list.
    if (this.visibleTabs.indexOf('jurisdictionsNav') === -1) {
      this.visibleTabs.push('jurisdictionsNav');
    }

    // If this is a report edit, select the jurisdictions from
    // the existing report.
    this.ofrsReport = this.commonService.getOfrsReport();
    if (this.ofrsReport.states === null) {
      this.ofrsReport.states = [];
    }
    this.jurisdictionsFormGroup = this.formBuilder.group({
      'jurisdictions': [this.ofrsReport.states, [selectValidator()]],
    });

    // Filter the jurisdictions based on whether they are
    // internal (participate in OFRS) or external (do not participate in OFRS).
    // This can vary between consumer and industry forms.
    if (this.ofrsReport.ofrsReportKey.reportType === 'I') {
      this.commonService.getJurisdictions().subscribe(data => {
        this.internalJurisdictions = data.filter(j => j.industryUsedFlag === true);
        this.externalJurisdictions = data.filter(j => j.industryUsedFlag === false);
      });
    } else {
      this.commonService.getJurisdictions().subscribe(data => {
        this.internalJurisdictions = data.filter(j => j.consumerUsedFlag === true);
        this.externalJurisdictions = data.filter(j => j.consumerUsedFlag === false);
      });
    }
  }

  save(value): void {
    this.ofrsReport.states = value.jurisdictions;
    this.commonService.setOfrsReport(this.ofrsReport);
    this.commonService.setAvailableTabs(this.visibleTabs);
    this.stored = true;
    this.router.navigate(['reporting-person']);
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.stored || !this.jurisdictionsFormGroup.dirty;
  }
}
