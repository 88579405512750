<div class="row">
  <div id="navigation" class="col-sm-2">
    <div class="row">
      <app-navigation [visibleItems]="visibleTabs"></app-navigation>
    </div>
  </div>

  <div class="col-sm-10">
    <h2>Welcome to the NAIC Online Fraud Reporting System</h2>
    <div *ngIf="this.user && this.user.companies && this.user.companies.length > 0 else noContent">
      <p>This application is provided for Industry to report suspected insurance fraud.</p>
      <p>Welcome, <span id="curUserId">{{this.user.loginId}}</span></p>
      <p>Please select an option below to continue:</p>
      <div class="row">
        <div class="col-4">
          <button id="addlink" class="btn btn-primary" (click)="startReport()" >
            <fa-icon size="lg" [icon]="faPlus"></fa-icon>&nbsp; New Fraud Report
          </button>
        </div>
        <div class="col-4">
          <a id="searchlink" class="btn btn-primary" [routerLink]="['/search-fraud']">
            <fa-icon size="lg" [icon]="faSearch"></fa-icon>&nbsp; Search Fraud Reports
          </a>
        </div>
        <div class="col-4">
          <a id="guidelink" class="btn btn-primary" href="assets/NewUserGuide.pdf" target="_blank">
            <fa-icon size="lg" [icon]="faFilePdf"></fa-icon>&nbsp; User Guide
          </a>
        </div>
      </div>
    </div>

    <ng-template #noContent>
      <div *ngIf="this.user">
        <p><span id="noCompany">User {{this.user.loginId}} is not assigned to any company.</span></p>
      </div>
      <div *ngIf="this.userService.status === 'NO_ROLE'">
        <p>User is missing the required role for OFRS Industry.</p>
      </div>
      <div *ngIf="this.userService.status === 'NO_AUTH'">
        <p>Invalid user or login failure.</p>
      </div>
    </ng-template>
  </div>
</div>
