<div class="row">
  <div class="card">
    <div class="card-body row">
      <a (click)="consumer()" class="stretched-link"></a>
      <div class="card-icon col-lg-2 col-md-3 col-sm-4">
        <fa-icon size="8x" [icon]="faHome"></fa-icon>
      </div>
      <div class="col">
        <h3 class="card-title">Consumers</h3>
        <p class="card-text">
          If you believe that you have information regarding possible fraudulent
          activities to report to your local state agency, please click here to
          enter the Online Fraud Reporting System.
        </p>
        <p class="card-mouseover text-right">
          File A Report &nbsp;<fa-icon size="md" [icon]="faArrowRight"></fa-icon>
        </p>
      </div>
    </div>
  </div>
</div>
<br/>
<div class="row">
  <div class="card">
    <div class="card-body row">
      <a (click)="industry()" class="stretched-link"></a>
      <div class="card-icon col-lg-2 col-md-3 col-sm-4">
        <fa-icon size="8x" [icon]="faCity"></fa-icon>
      </div>
      <div class="col">
        <h3 class="card-title">Industry</h3>
        <p class="card-text">
          If you are an industry user and have information regarding possible
          fraudulent activities to report to your local state agency, please
          click here to enter the Online Fraud Reporting System.
        </p>
        <p class="card-mouseover text-right">
          File A Report &nbsp;<fa-icon size="md" [icon]="faArrowRight"></fa-icon>
        </p>
      </div>
    </div>
  </div>
</div>
