import {BaseParty} from './base-party.model';
import {Aka} from './aka.model';

export class Party extends BaseParty {

    public entityType: string; // Business or Individual
    public partyCategory: string; // SUS or RPT
    public partyType: string;
    public partyTypeOtherDesc: string;
    public anonymousFlag: string;
    public reportedInjury: string;
    public comments: string;
    public reportingPersonTitle: string;
    public reportingPersonDept: string;
    public subjectOfInvestigation: string;
    public akas: Aka[];

    constructor( ) {
      super();
    }
}
