import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CommonService} from '../shared/common.service';
import {selectValidator} from '../shared/validation/select-validator';
import {Jurisdiction} from '../shared/jurisdiction.model';
import {OfrsIndustryValidationService} from '../shared/validation/validation.service';
import {numericValidator} from '../shared/validation/numeric-validator';
import {Router} from '@angular/router';
import {Reporter} from '../shared/reporter.model';
import {Address} from '../shared/address.model';
import {notOnlyWhitespaceValidator} from '../shared/validation/not-only-whitespace.directive';
import {atLeastOnePhoneValidator} from '../shared/validation/at-least-one-phone-validator';
import {AddressDecode} from '../shared/address-decode.model';
import {Observable} from 'rxjs';
import {OfrsUserService} from '../shared/ofrs-user.service';
import {faUserSecret} from '@fortawesome/free-solid-svg-icons';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {charLength} from '../shared/validation/char-length-validator';

@Component({
  selector: 'app-reporting-person',
  templateUrl: './reporting-person.component.html',
  styleUrls: ['./reporting-person.component.css'],
  animations: [
    trigger('slider', [
      state('open', style({
        height: '*',
        'margin-bottom': '1rem'
      })),
      state('closed', style({
        opacity: '0',
        overflow: 'hidden',
        height: '0px',
        'margin-bottom': '0px'
      })),
      transition('open => closed', animate('500ms ease-in-out')),
      transition('closed => open', animate('500ms ease-in-out'))
    ])
  ]
})
export class ReportingPersonComponent implements OnInit {

  readonly faUserSecret = faUserSecret;
  addressDecodes: AddressDecode[];
  jurisdictions: Jurisdiction[];
  formGroup: FormGroup;
  formErrors: any;
  errorMessage: string;
  cocodes: number[];
  reporter: Reporter;
  address: Address;
  stored = false;
  visibleTabs: string[];
  isIndustry: boolean;
  phonePattern = '^\\(?([0-9]{3})\\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$';
  zipPattern = '^\\d{5}(?:[-]\\d{4})?$';
  constructor(private commonService: CommonService,
              private userService: OfrsUserService,
              private formBuilder: FormBuilder,
              public validationService: OfrsIndustryValidationService,
              private router: Router) {
    this.visibleTabs = this.commonService.getAvailableTabs().slice();
    this.reporter = this.commonService.getOfrsReport().reporter;
    this.isIndustry = this.commonService.getOfrsReport().ofrsReportKey.reportType === 'I';
    this.address = this.reporter.address;
    if (this.address == null) {
      this.address = new Address();
    }
    this.address.addressType = this.isIndustry ? 'Business' : 'Residence';
  }

  ngOnInit(): void {
    if (this.visibleTabs.indexOf('reportingPersonNav') === -1) {
      this.visibleTabs.push('reportingPersonNav');
    }
    if (this.userService.user) {
      this.cocodes = this.userService.user.allCocodes;
    }
      if (this.isEmpty() && this.userService.user && !this.commonService.getOfrsReport().ofrsReportKey.reportId) {
      this.reporter.firstName = this.userService.user.firstName;
      this.reporter.middleName = this.userService.user.middleName;
      this.reporter.lastName = this.userService.user.lastName;
      this.reporter.title = this.userService.user.title;
      this.reporter.department = this.userService.user.department;
      this.address.address1 = this.userService.user.address1;
      this.address.address2 = this.userService.user.address2;
      this.address.city = this.userService.user.city;
      this.address.zip = this.userService.user.zip;
      this.address.county = this.userService.user.county;
      this.address.state = this.userService.user.state;
      this.reporter.email = this.userService.user.email;
      this.reporter.busPhone = this.userService.user.busPhone;
      this.reporter.homePhone = this.userService.user.homePhone;
      this.reporter.cellPhone = this.userService.user.cellPhone;
      this.reporter.busPhoneExt = this.userService.user.busPhoneExt;
      this.reporter.fax = this.userService.user.fax;
    }
    this.createReportingPersonForm();
    this.getAddressDecodes();
    this.getJurisdictions();
  }

  createReportingPersonForm(): void {
    this.formGroup = this.formBuilder.group({
      anonymous: [this.reporter.anonymous, [(!this.isIndustry ? Validators.required : Validators.nullValidator)]],
      naicCocode: [this.reporter.naicCocode, [(this.isIndustry ? Validators.required : Validators.nullValidator)]],
      title: [this.reporter.title, [(this.isIndustry ? Validators.required : Validators.nullValidator),
          notOnlyWhitespaceValidator(), charLength(70)]],
      department: [this.reporter.department, [(this.isIndustry ? Validators.required : Validators.nullValidator),
          notOnlyWhitespaceValidator(), charLength(70)]],
      firstName: [this.reporter.firstName, [(this.isIndustry ? Validators.required : Validators.nullValidator),
          notOnlyWhitespaceValidator(), charLength(70)]],
      middleName: [this.reporter.middleName, [notOnlyWhitespaceValidator(), charLength(70)]],
      lastName: [this.reporter.lastName, [(this.isIndustry ? Validators.required : Validators.nullValidator),
          notOnlyWhitespaceValidator(), charLength(70)]],
      addressType: [this.address.addressType, [Validators.required]],
      address1: [this.address.address1, [(this.isIndustry ? Validators.required : Validators.nullValidator),
          notOnlyWhitespaceValidator(), charLength(70)]],
      address2: [this.address.address2, [notOnlyWhitespaceValidator(), charLength(70)]],
      city: [this.address.city, [(this.isIndustry ? Validators.required : Validators.nullValidator),
          notOnlyWhitespaceValidator(), charLength(70)]],
      county: [this.address.county, [notOnlyWhitespaceValidator(), charLength(25)]],
      state: [this.address.state, [(this.isIndustry ? Validators.required : Validators.nullValidator),
          Validators.maxLength(2)]],
      zip: [this.address.zip, [(this.isIndustry ? Validators.required : Validators.nullValidator),
          Validators.pattern(this.zipPattern)]],
      email: [this.reporter.email, [(this.isIndustry ? Validators.required : Validators.nullValidator),
          Validators.email, charLength(70)]],
      homePhone: [this.reporter.homePhone, [Validators.pattern(this.phonePattern)]],
      cellPhone: [this.reporter.cellPhone, [Validators.pattern(this.phonePattern)]],
      busPhone: [this.reporter.busPhone, [Validators.pattern(this.phonePattern)]],
      busPhoneExt: [this.reporter.busPhoneExt, [numericValidator(), charLength(10)]],
      fax: [this.reporter.fax, [Validators.pattern(this.phonePattern)]]
    }, { validator: (this.isIndustry ? atLeastOnePhoneValidator : Validators.nullValidator)
    });
    this.formGroup.get('addressType').disable();

    this.formGroup.valueChanges.subscribe(data => this.onValueChanged(data));

    this.onValueChanged();
  }

  onValueChanged(data?: any) {
    if (!this.formGroup) {return; }
    const form = this.formGroup;
    this.formErrors = this.validationService.setErrors(form);
  }

  private getAddressDecodes(): void {
    this.commonService.getAddressDecodes()
      .subscribe(data => {
        this.addressDecodes = data;
      });
  }
  private getJurisdictions(): void {
    this.commonService.getJurisdictions()
      .subscribe(data => this.jurisdictions = data);
  }

  onClickAnonymousYes(): void {
    this.formGroup.get('firstName').setValue('');
    this.formGroup.get('middleName').setValue('');
    this.formGroup.get('lastName').setValue('');
    this.formGroup.get('address1').setValue('');
    this.formGroup.get('address2').setValue('');
    this.formGroup.get('city').setValue('');
    this.formGroup.get('county').setValue('');
    this.formGroup.get('state').setValue('');
    this.formGroup.get('zip').setValue('');
    this.formGroup.get('email').setValue('');
    this.formGroup.get('homePhone').setValue('');
    this.formGroup.get('cellPhone').setValue('');
    this.formGroup.get('busPhone').setValue('');
    this.formGroup.get('busPhoneExt').setValue('');
    this.formGroup.get('fax').setValue('');
  }

  save(value): void {
    this.errorMessage = '';
    this.address.address1 = value.address1;
    this.address.address2 = value.address2;
    this.address.city = value.city;
    this.address.county = value.county;
    this.address.state = value.state;
    this.address.zip = value.zip;
    this.reporter.address = this.address;

    this.reporter.anonymous = value.anonymous;
    this.reporter.naicCocode = value.naicCocode;
    this.reporter.title = value.title;
    this.reporter.department = value.department;
    this.reporter.firstName = value.firstName;
    this.reporter.middleName = value.middleName;
    this.reporter.lastName = value.lastName;
    this.reporter.email = value.email;
    this.reporter.homePhone = value.homePhone;
    this.reporter.cellPhone = value.cellPhone;
    this.reporter.busPhone = value.busPhone;
    this.reporter.busPhoneExt = value.busPhoneExt;
    this.reporter.fax = value.fax;
    this.commonService.getOfrsReport().reporter = this.reporter;
    this.commonService.setAvailableTabs(this.visibleTabs);
    this.stored = true;
    this.router.navigate([ this.isIndustry ? 'fraud-type' : 'suspects']);
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.stored || !this.formGroup.dirty) {
      return true;
    }
    return false;
  }

  isEmpty() {
     return !this.reporter.firstName  &&
       !this.reporter.middleName  && !this.reporter.lastName  && !this.address.address1 && !this.address.address2 && !this.address.city && !this.address.county  && !this.address.state
       && !this.address.zip && !this.reporter.title && !this.reporter.department && !this.reporter.email && !this.reporter.busPhone && !this.reporter.homePhone && !this.reporter.cellPhone && !this.reporter.busPhoneExt && !this.reporter.fax;
  }
}
