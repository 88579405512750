import {cloneDeep} from 'lodash';

export class InvestigationSummary {
  isSiuInvestigationCompleted: string;
  siuCaseNumber: string;
  completedDate: string;
  isRelatedToOther: string;
  overview: string;

  constructor(obj?: any) {
    Object.assign(this, cloneDeep(obj));
    this.isSiuInvestigationCompleted = this.isSiuInvestigationCompleted || '';
    this.siuCaseNumber = this.siuCaseNumber || '';
    this.completedDate = this.completedDate || '';
    this.isRelatedToOther = this.isRelatedToOther || '';
    this.overview = this.overview || '';
  }

}
