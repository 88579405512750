import {Component, Input, OnInit} from '@angular/core';
import {FormGroup, Validators} from '@angular/forms';
import * as _ from 'lodash';
import {AddressDecode} from '../address-decode.model';
import {notOnlyWhitespaceValidator} from '../validation/not-only-whitespace.directive';
import {Jurisdiction} from '../jurisdiction.model';
import {charLength} from '../validation/char-length-validator';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.css']
})
export class AddressComponent implements OnInit {

  private static zipPattern = '^\\d{5}(?:[-]\\d{4})?$';

  @Input() isAddressTypeHidden: boolean;
  @Input() isAddressTypeReadOnly: boolean;
  @Input() prefix: string;
  @Input() formGroup: FormGroup;
  @Input() requiredFields: string[] = [];
  @Input() errorMessages: any;
  @Input() displayedFields: string[] = [];
  @Input() addressDecodes: AddressDecode[];
  @Input() jurisdictions: Jurisdiction[];
  @Input() colWidth: string;
  @Input() labelWidth: number;

  constructor() { }

  ngOnInit() {
    // Check for addressType change at initialization in case a
    // report is already loaded.
    this.onAddressTypeChange();
  }

  getId(fieldId: string): string {
    return this.prefix ? _.camelCase(`${this.prefix} ${fieldId}`) : fieldId;
  }

  getErrorMessage(fieldName: string, errorType: string): string {
    const fieldErrors = this.errorMessages[this.getId(fieldName)];
    return fieldErrors[errorType];
  }

  isAddrsTypeHidden(): boolean {
    return this.isAddressTypeHidden ? this.isAddressTypeHidden : false;
  }

  isAddrsTypeReadOnly(): boolean {
    return this.isAddressTypeReadOnly ? this.isAddressTypeReadOnly : false;
  }

  isRequired(id: string): boolean {
    return this.requiredFields.indexOf(this.getId(id)) >= 0;
  }

  getColWidth(): string {
    return this.colWidth ? `col-sm-${this.colWidth}` : 'col-sm-9';
  }

  get labelClass(): string {
    return this.labelWidth ? `col-form-label text-right col-sm-${this.labelWidth}`
      : 'col-form-label text-right col-sm-3';
  }

  get addressType() { return this.formGroup.get(this.getId('addressType')); }
  get addressTypeOtherDesc() { return this.formGroup.get(this.getId('addressTypeOtherDesc')); }
  get address1() { return this.formGroup.get(this.getId('address1')); }
  get address2() { return this.formGroup.get(this.getId('address2')); }
  get city() { return this.formGroup.get(this.getId('city')); }
  get county() { return this.formGroup.get(this.getId('county')); }
  get state() { return this.formGroup.get(this.getId('state')); }
  get zip() { return this.formGroup.get(this.getId('zip')); }

  onAddressTypeChange() {
    if (this.isAddressTypeHidden || this.isAddressTypeReadOnly) {
      return;
    }
    const addressType = this.formGroup.get('addressType').value;
    if (addressType === 'None') {
      this.clearValidators();
    } else {
      this.restoreValidators();
    }

    if (addressType === 'Other') {
      this.formGroup.controls['addressTypeOtherDesc'].setValidators([
        Validators.required, notOnlyWhitespaceValidator(), charLength(25)]);
      this.formGroup.controls['addressTypeOtherDesc'].updateValueAndValidity();
    } else {
      this.formGroup.controls['addressTypeOtherDesc'].clearValidators();
      this.formGroup.get('addressTypeOtherDesc').setValue('');
      this.formGroup.controls['addressTypeOtherDesc'].updateValueAndValidity();
    }
  }

  private clearValidators() {
    this.formGroup.controls['address1'].clearValidators();
    this.formGroup.controls['address2'].clearValidators();
    this.formGroup.controls['city'].clearValidators();
    this.formGroup.controls['county'].clearValidators();
    this.formGroup.controls['state'].clearValidators();
    this.formGroup.controls['zip'].clearValidators();
    this.formGroup.get('addressTypeOtherDesc').setValue('');
    this.formGroup.get('address1').setValue('');
    this.formGroup.get('address2').setValue('');
    this.formGroup.get('city').setValue('');
    this.formGroup.get('county').setValue('');
    this.formGroup.get('state').setValue('');
    this.formGroup.get('zip').setValue('');
    this.formGroup.controls['addressTypeOtherDesc'].updateValueAndValidity();
    this.formGroup.controls['address1'].updateValueAndValidity();
    this.formGroup.controls['address2'].updateValueAndValidity();
    this.formGroup.controls['city'].updateValueAndValidity();
    this.formGroup.controls['county'].updateValueAndValidity();
    this.formGroup.controls['state'].updateValueAndValidity();
    this.formGroup.controls['zip'].updateValueAndValidity();
  }

  private restoreValidators() {
    this.formGroup.controls['address1'].setValidators( [this.maybeRequired('address1'), notOnlyWhitespaceValidator(), Validators.maxLength(70)]);
    this.formGroup.controls['address2'].setValidators([this.maybeRequired('address2'), notOnlyWhitespaceValidator(), Validators.maxLength(70)]);
    this.formGroup.controls['city'].setValidators([this.maybeRequired('city'), notOnlyWhitespaceValidator(), Validators.maxLength(70)]);
    this.formGroup.controls['zip'].setValidators([this.maybeRequired('zip'), Validators.pattern(AddressComponent.zipPattern)]);
    this.formGroup.controls['state'].setValidators([this.maybeRequired('state'), Validators.maxLength(2)]);
    this.formGroup.controls['county'].setValidators([this.maybeRequired('county'), notOnlyWhitespaceValidator(), Validators.maxLength(25)]);
  }

  private maybeRequired(field) {
    return this.isRequired(field) ? Validators.required : Validators.nullValidator;
  }

}


