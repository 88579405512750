import {Injectable} from '@angular/core';
import {HttpClient, HttpEventType, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {Attachment} from './attachment.model';
import {map, switchMap} from 'rxjs/operators';

interface UploadRequest {
  attachmentId: number;
  signature: string;
  uploadURL: string;
}

@Injectable({ providedIn: 'root' })
export class AttachmentService {

  private static readonly httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient) { }

  download(attachment: Attachment): Observable<string> {
    const url = `${environment.apiUrl}/attachments/${attachment.attachmentId}`;
    return this.http.get<string>(url, {responseType: 'text' as 'json'});
  }

  upload(attachment: Attachment): void {
    attachment.status = 'PENDING';
    attachment.subscription = this.requestUpload(attachment)
      .pipe(switchMap(uploadRequest => {
          attachment.attachmentId = uploadRequest.attachmentId;
          attachment.signature = uploadRequest.signature;
          return this.uploadAttachment(uploadRequest.uploadURL, attachment);
      }))
      .subscribe(() => null, error => attachment.status = 'FAILED');
  }

  private requestUpload(attachment: Attachment): Observable<UploadRequest> {
    return this.http.put<UploadRequest>(
        `${environment.apiUrl}/attachments`,
        {name: attachment.name, size: attachment.size, type: attachment.type},
        AttachmentService.httpOptions);
  }

  private uploadAttachment(url: string, attachment: Attachment): Observable<any> {
    const blob = attachment.blob.slice();
    return this.http.put(url, blob, {
      reportProgress: true,
      observe: 'events',
      headers: { 'Content-Type': attachment.type }
    }).pipe(map(event => {
      if (event.type === HttpEventType.Sent) {
        attachment.status = 'UPLOADING';
      } else if (event.type === HttpEventType.UploadProgress) {
        attachment.bytesUploaded = event.loaded;
      } else if (event.type === HttpEventType.Response) {
        attachment.status = event.ok ? 'COMPLETE' : 'FAILED';
      }
    }));
  }

}
