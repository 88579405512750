import {Component, OnDestroy, OnInit} from '@angular/core';
import {CommonService} from '../shared/common.service';
import {FormBuilder} from '@angular/forms';
import {Router} from '@angular/router';
import {faFolderOpen, faTimes, faUpload} from '@fortawesome/free-solid-svg-icons';
import {Attachment, MAX_FILE_COUNT, MAX_FILE_SIZE_STRING} from './attachment.model';
import {AttachmentService} from './attachment.service';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.css']
})
export class AttachmentsComponent implements OnInit, OnDestroy {

  readonly faUpload = faUpload;
  readonly faFolderOpen = faFolderOpen;
  readonly faTimes = faTimes;
  readonly MAX_FILE_SIZE_STRING = MAX_FILE_SIZE_STRING;
  readonly MAX_FILE_COUNT = MAX_FILE_COUNT;

  visibleTabs: string[];
  highlighted = false;
  attachments: Attachment[] = [];
  thumbnail: string;
  isIndustry: boolean;

  constructor(private commonService: CommonService,
              private attachmentService: AttachmentService,
              private formBuilder: FormBuilder,
              private router: Router) { }

  ngOnInit() {
    this.visibleTabs = this.commonService.getAvailableTabs().slice();
    if (this.visibleTabs.indexOf('attachmentsNav') === -1) {
      this.visibleTabs.push('attachmentsNav');
    }
    this.isIndustry = this.commonService.getOfrsReport().ofrsReportKey.reportType === 'I';
    this.attachments = this.commonService.getOfrsReport().attachments || [];
  }

  /**
   * Specifies whether there are any errors that should prevent
   * the user from being able to advanced to the next page.
   */
  get hasErrors(): boolean {
    return this.attachments.some(a => a.hasErrors)
        || this.attachments.length > MAX_FILE_COUNT;
  }

  get inProgress(): boolean {
    return this.attachments.some(a => a.inProgress);
  }

  /**
   * Highlights the draggable area when a file is dragged over it.
   */
  onDragOver(event) {
    this.allowDragAndDrop(event);
    this.highlighted = true;
  }

  onFilesDropped(event) {
    this.allowDragAndDrop(event);
    this.highlighted = false;

    // If files are chosen from the Browse button, they will exist on
    // event.target.files
    if (event && event.target && event.target.files) {
      for (const file of event.target.files) {
        this.addAttachment(new Attachment(file));
      }
    }

    // If files are dropped into the target region, they will exist on
    // event.dataTransfer.files
    if (event && event.dataTransfer && event.dataTransfer.files) {
      for (const file of event.dataTransfer.files) {
        this.addAttachment(new Attachment(file));
      }
    }

    this.attachments.filter(a => a.isValid && a.isNew)
      .forEach(a => this.attachmentService.upload(a));
  }

  openInNewTab(attachment: Attachment): void {
    if (!attachment.blob) {
      document.body.style.cursor = 'wait';
      this.attachmentService.download(attachment).subscribe(
        downloadURL => {
          window.open(downloadURL, '_blank');
          document.body.style.cursor = 'default';
        },
        error => {
          console.error(error);
          document.body.style.cursor = 'default';
        },
        () => document.body.style.cursor = 'default'
      );
    } else if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
      window.navigator.msSaveOrOpenBlob(attachment.blob, attachment.name);
    } else {
      const url = URL.createObjectURL(attachment.blob);
      window.open(url, '_blank');
      URL.revokeObjectURL(url);
    }
  }

  removeAttachment(idx: number): void {
    this.attachments[idx].unsubscribe();
    this.attachments.splice(idx, 1);
  }

  /**
   * Adds an attachment ensuring that it doesn't already exist in the list.
   *
   * @param attachment attachment to add
   */
  private addAttachment(attachment: Attachment): void {
    const isDuplicate = this.attachments.some(a => a.equals(attachment));
    if (!isDuplicate) {
      this.attachments.push(attachment);
    }
  }

  /**
   * Modifies the event behavior to allow the handling of drag and drop
   * events.
   *
   * By the default, a drag or drop event would cause additional actions
   * to be taken by the browser that interfere with creating a drag
   * and drop interface in a web application. The event needs to have
   * propagation stopped to prevent additional spurious events, such as
   * touch or mouse events from interfering with the drag event.
   * Additionally, the event's default method needs to be disabled so
   * that the browser doesn't attempt to open the file in a new tab
   * when a user drops a file inside of it.
   *
   * @param event The event to modify to allow drag and drop behavior.
   * @see https://stackoverflow.com/a/44220147
   */
  private allowDragAndDrop(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
  }

  next() {
    this.commonService.getOfrsReport().attachments = this.attachments.filter(a => a.isComplete);
    this.commonService.setAvailableTabs(this.visibleTabs);
    this.router.navigate([this.isIndustry ? 'claim-information' : 'submit-report']);
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    return !this.inProgress && !this.hasErrors;
  }

  ngOnDestroy(): void {
    this.attachments.forEach(a => a.unsubscribe());
  }

}
