import * as _ from 'lodash';

export class OfrsReportKey {
  reportId: number;
  dateSubmitted: string;
  transactionId: number;
  reportType: string;
  signature: string;

  constructor(obj?: any) {
    Object.assign(this, _.cloneDeep(obj));
    this.reportId = this.reportId || null;
    this.dateSubmitted = this.dateSubmitted || '';
    this.transactionId = this.transactionId || null;
    this.reportType = this.reportType || 'I';
    this.signature = this.signature || '';
  }

}
