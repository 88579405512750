<div class="row">
  <div class="col-11 ">
    <h2 class="text-center">Welcome to the NAIC Online Fraud Reporting System</h2>
    <h4 class="text-center">Consumer Terms of Use Statement</h4>

    <p>
      This system is restricted to use for legitimate business purposes only. All electronic information transmitted by, received from, or stored in NAIC equipment is the property of NAIC. Information submitted will be transmitted to the states as indicated for their review and investigation. NAIC bears no responsibility or liability for the accuracy or veracity of information submitted. Users reporting fraudulent activity through this system will do so in good faith. The use of this system may be monitored and recorded for administrative and security reasons.
    </p>

    <div class="row col-12 font">
      <p>By clicking on the I agree button below, you acknowledge the following:</p>

      <ul style="list-style-type: disc">
        <li>You have read and understand this statement regarding the use of this system.</li>
        <li>Access or use of this system for fraudulent activity is strictly prohibited by NAIC.</li>
        <li>Use of this system or the reporting of fraudulent activity which you know to be false, may subject you to criminal or civil penalties/remedies under state, federal, or other applicable domestic and foreign laws.</li>
      </ul>
    </div>


    <div class="row justify-content-center">
      <button id="termsOfUseAgreeButton" type="button" class="btn btn-success"
              (click)="startReport()">I Agree</button>
    </div>
  </div>
</div>
