import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {environment} from '../../environments/environment';
import {faHome, faCity, faArrowRight} from '@fortawesome/free-solid-svg-icons';
import {OfrsUserService} from '../shared/ofrs-user.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent implements OnInit {

  readonly faHome = faHome;
  readonly faCity = faCity;
  readonly faArrowRight = faArrowRight;

  constructor(private router: Router,
              private userService: OfrsUserService) { }

  ngOnInit() { }

  consumer() {
    this.router.navigate(['/terms-of-use']);
  }

  async industry() {
    try {
      const user = await this.userService.login();
      if (user) {
        this.router.navigate(['/welcome']);
      }
    } catch (e) {
      console.error(e);
    }
  }
}
