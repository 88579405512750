import { Component, TemplateRef, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { faMinus, faPlus, faCalendar, faTrash } from '@fortawesome/free-solid-svg-icons';
import { CommonService } from '../shared/common.service';
import { Jurisdiction } from '../shared/jurisdiction.model';
import { BsDatepickerConfig } from 'ngx-bootstrap';
import { noFutureDateValidator } from '../shared/validation/no-future-date.directive';
import { notOnlyWhitespaceValidator } from '../shared/validation/not-only-whitespace.directive';
import { ClaimInformation } from '../shared/claim-information.model';
import { InsuranceDecode } from '../shared/insurance-decode';
import { ClaimStatusDecode } from '../shared/claim-status-decode';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Service } from '../shared/service.model';
import { claimServiceValidator } from '../shared/validation/claim-service-validator';
import { Procedure } from '../shared/procedure.model';
import { numericValidator } from '../shared/validation/numeric-validator';
import { Observable } from 'rxjs';
import {OfrsIndustryValidationService} from '../shared/validation/validation.service';
import {exactlyOneRequiredValidator} from '../shared/validation/exactly-one-required-validator';
import {charLength} from '../shared/validation/char-length-validator';

@Component({
  selector: 'app-claim-information',
  templateUrl: './claim-information.component.html',
  styleUrls: ['./claim-information.component.css']
})
export class ClaimInformationComponent implements OnInit {

  readonly bsConfig = Object.assign(new BsDatepickerConfig(), {
    containerClass: 'theme-dark-blue',
    maxDate: new Date(),
    showWeekNumbers: false
  });

  private readonly zipPattern = '^\\d{5}(?:[-]\\d{4})?$';
  private readonly alphaNumberDashPattern = '^[a-zA-Z0-9-]+$';

  // icons
  faPlus = faPlus;
  faMinus = faMinus;
  faCalendar = faCalendar;
  faTrash = faTrash;

  errorMessage: string;
  claimInformation: ClaimInformation;
  form: FormGroup;
  claimDetailsOpen = true;
  lossInjuryDetailsOpen = true;

  jurisdictions: Jurisdiction[] = [];
  insuranceDecodes: InsuranceDecode[] = [];
  claimStatusDecodes: ClaimStatusDecode[] = [];

  stored = false;

  services: Service[] = [];
  serviceForm: FormGroup;
  serviceOpen = false;
  modalRef: BsModalRef;
  workingServiceIndex: number;
  procedures: Procedure[] = [];
  procedureForm: FormGroup;
  procedureOpen = false;
  workingProcedureIndex: number;

  financialDetailsOpen = false;
  visibleTabs: string[];

  constructor(
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: BsModalService,
    public validationService: OfrsIndustryValidationService
  ) {
    this.visibleTabs = this.commonService.getAvailableTabs().slice();
  }

  ngOnInit() {
    if (this.visibleTabs.indexOf('claimInformationNav') === -1) {
      this.visibleTabs.push('claimInformationNav');
    }
    this.commonService.getClaimStatusDecodes().subscribe(data => this.claimStatusDecodes = data);
    this.commonService.getInsuranceDecodes().subscribe(data => this.insuranceDecodes = data);
    this.commonService.getJurisdictions().subscribe(data => this.jurisdictions = data);
    this.claimInformation = new ClaimInformation(this.commonService.getOfrsReport().claimInformation);
    this.services = this.claimInformation.services;
    this.procedures = this.claimInformation.procedures;
    this.workingServiceIndex = -1;  // index of array, -1 means none selected
    this.workingProcedureIndex = -1; // index of array, -1 means none selected
    this.createForm();
  }

  createForm(): void {
    this.form = new FormGroup({
      claimDetails: this.formBuilder.group({
        insuranceTypeId: [this.claimInformation.insuranceTypeId, [Validators.required]],
        claimStatus: [this.claimInformation.claimStatus, [Validators.required]],
        policyNumber: [this.claimInformation.policyNumber, [Validators.required, Validators.pattern(this.alphaNumberDashPattern), charLength(25)]],
        claimNumber: [this.claimInformation.claimNumber, [Validators.required, Validators.pattern(this.alphaNumberDashPattern), charLength(25)]],
        companyCaseNumber: [this.claimInformation.companyCaseNumber, [Validators.pattern(this.alphaNumberDashPattern), charLength(25)]],
        civilLitigationPending: [this.claimInformation.civilLitigationPending],
      }),
      lossDetails: this.formBuilder.group({
        lossDate: [this.claimInformation.lossDate, [noFutureDateValidator()]],
        approxLossDate: [this.claimInformation.approxLossDate, [notOnlyWhitespaceValidator(), charLength(200)]],
        location: this.formBuilder.group({
          address1: [this.claimInformation.location.address1, [notOnlyWhitespaceValidator(), charLength(70)]],
          address2: [this.claimInformation.location.address2, [notOnlyWhitespaceValidator(), charLength(70)]],
          city: [this.claimInformation.location.city, [notOnlyWhitespaceValidator(), charLength(70)]],
          county: [this.claimInformation.location.county, [notOnlyWhitespaceValidator(), charLength(25)]],
          state: [this.claimInformation.location.state, [notOnlyWhitespaceValidator(), charLength(2)]],
          zip: [this.claimInformation.location.zip, [notOnlyWhitespaceValidator(), Validators.pattern(this.zipPattern)]]
        })
      }, { validators: exactlyOneRequiredValidator('lossDate', 'approxLossDate') }),
      financialDetails: this.formBuilder.group({
        approxReserveAmt: [this.claimInformation.approxReserveAmt, Validators.max(999_999_999) ],
        billedAmt: [this.claimInformation.billedAmt, Validators.max(999_999_999) ],
        suspectedFraudAmt: [this.claimInformation.suspectedFraudAmt, Validators.max(999_999_999) ],
        approxPaidAmt: [this.claimInformation.approxPaidAmt, Validators.max(999_999_999)],
        paidDate: [this.claimInformation.paidDate, noFutureDateValidator()],
        approxSettleAmt: [this.claimInformation.approxSettleAmt, Validators.max(999_999_999)],
        settlePaidDate: [this.claimInformation.settlePaidDate, noFutureDateValidator()],
        lossRangeId: [this.claimInformation.lossRangeId, ],
      })
    });
    this.serviceForm = this.formBuilder.group({
      serviceDesc: ['', [Validators.required, notOnlyWhitespaceValidator(), charLength(200)]],
      beginDate: [null, noFutureDateValidator()],
      endDate: [null, noFutureDateValidator()]
    }, { validator: claimServiceValidator});
    this.procedureForm = this.formBuilder.group({
      procedureCode: ['', [Validators.required, notOnlyWhitespaceValidator(),
                           numericValidator(), charLength(10)]],
      procedureType: ['', Validators.required]
    });
  }

  get claimDetails() { return this.form.get('claimDetails'); }
  get insuranceTypeId() { return this.claimDetails.get('insuranceTypeId'); }
  get claimStatus() { return this.claimDetails.get('claimStatus'); }
  get policyNumber() { return this.claimDetails.get('policyNumber'); }
  get claimNumber() { return this.claimDetails.get('claimNumber'); }
  get companyCaseNumber() { return this.claimDetails.get('companyCaseNumber'); }
  get civilLitigationPending() { return this.claimDetails.get('civilLitigationPending'); }

  get lossDetails() { return this.form.get('lossDetails'); }
  get lossDate() { return this.lossDetails.get('lossDate'); }
  get approxLossDate() { return this.lossDetails.get('approxLossDate'); }
  get location() { return this.lossDetails.get('location'); }

  get serviceDesc() { return this.serviceForm.get('serviceDesc'); }
  get beginDate() { return this.serviceForm.get('beginDate'); }
  get endDate() { return this.serviceForm.get('endDate'); }

  get procedureCode() { return this.procedureForm.get('procedureCode'); }
  get procedureType() { return this.procedureForm.get('procedureType'); }

  get financialDetails() { return this.form.get('financialDetails'); }
  get approxReserveAmt() { return this.financialDetails.get('approxReserveAmt'); }
  get billedAmt() { return this.financialDetails.get('billedAmt'); }
  get suspectedFraudAmt() { return this.financialDetails.get('suspectedFraudAmt'); }
  get approxPaidAmt() { return this.financialDetails.get('approxPaidAmt'); }
  get paidDate() { return this.financialDetails.get('paidDate'); }
  get approxSettleAmt() { return this.financialDetails.get('approxSettleAmt'); }
  get settlePaidDate() { return this.financialDetails.get('settlePaidDate'); }
  get lossRangeId() { return this.financialDetails.get('lossRangeId'); }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  openExistingService(service: Service, idx: number, template: TemplateRef<any>) {
    this.workingServiceIndex = idx;
    this.serviceForm.setValue(service);
  }

  cancelService() {
    this.resetWorkingService();
    this.modalRef.hide();
  }

  trashService(idx: number) {
    this.services.splice(idx, 1);
  }

  removeService() {
    this.services.splice(this.workingServiceIndex, 1);
    this.resetWorkingService();
    this.modalRef.hide();
  }

  addUpdateService() {
    const service = new Service(this.serviceForm.value);
    if (this.workingServiceIndex === -1) {
      this.services.push(service);
    } else {
      this.services[this.workingServiceIndex] = service;
    }
    this.resetWorkingService();
    this.modalRef.hide();
  }

  resetWorkingService() {
    this.workingServiceIndex = -1;
    this.serviceForm.reset();
  }

  openExistingProcedure(procedure: Procedure, idx: number, template: TemplateRef<any>) {
    this.workingProcedureIndex = idx;
    this.procedureForm.setValue(procedure);
  }

  cancelProcedure() {
    this.resetWorkingProcedure();
    this.modalRef.hide();
  }

  trashProcedure(idx: number) {
    this.procedures.splice(idx, 1);
  }

  removeProcedure() {
    this.procedures.splice(this.workingProcedureIndex, 1);
    this.resetWorkingProcedure();
    this.modalRef.hide();
  }

  addUpdateProcedure() {
    const procedure = new Procedure(this.procedureForm.value);
    if (this.workingProcedureIndex === -1) {
      this.procedures.push(procedure);
    } else {
      this.procedures[this.workingProcedureIndex] = procedure;
    }
    this.resetWorkingProcedure();
    this.modalRef.hide();
  }

  resetWorkingProcedure() {
    this.workingProcedureIndex = -1;
    this.procedureForm.reset();
  }

  errors(control: AbstractControl): string[] {
    const errors: string[] = [];
    Object.keys(control.errors).forEach((key: string) => {
      switch (key) {
        case 'required': errors.push(' is required'); break;
        case 'max': errors.push(' cannot be greater than $999,999,999,999'); break;
        case 'maxlength': errors.push(` cannot be longer than ${control.errors[key].requiredLength} characters`); break;
        case 'pattern': errors.push(' can only contain alpha-numeric characters and dashes'); break;
        case 'bsDate': errors.push(' is invalid'); break;
        case 'noFutureDate': errors.push(' cannot be a date in the future'); break;
        case 'whitespace': errors.push(' only white space is not allowed'); break;
        case 'NaN': errors.push(' must be numeric'); break;
      }
    });
    return errors;
  }

  shouldShowError(control: AbstractControl): boolean {
    return control && control.errors
        && this.errors(control).length > 0
        && (control.touched || control.dirty);
  }

  hasError(): boolean {
    return this.form.invalid;
  }

  save() {
    const claimInformation = new ClaimInformation(Object.assign({},
      this.claimDetails.value,
      this.lossDetails.value,
      this.financialDetails.value,
      { services: this.services, procedures: this.procedures }
    ));
    this.commonService.getOfrsReport().claimInformation = claimInformation;
    this.commonService.setAvailableTabs(this.visibleTabs);
    this.stored = true;
    this.router.navigate(['other-agencies']);
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.stored;
  }

}
