import {Address} from '../shared/address.model';


export class Reporter {
    public anonymous: boolean;
    public loginId: string;
    public naicCocode: number;
    public title: string;
    public department: string;
    public firstName: string;
    public middleName: string;
    public lastName: string;
    public address: Address;
    public email: string;
    public fax: string;
    public homePhone: string;
    public cellPhone: string;
    public busPhone: string;
    public busPhoneExt: string;

    constructor(naicCocode?: number, title?: string, department?: string, firstName?: string, middleName?: string, lastName?: string, address?: Address,
                email?: string, fax?: string, homePhone?: string, cellPhone?: string, busPhone?: string, busPhoneExt?: string) {
        this.naicCocode = naicCocode || null;
        this.title = title || '';
        this.department = department || '';
        this.firstName = firstName || '';
        this.middleName = middleName || '';
        this.lastName = lastName || '';
        this.address = address || new Address();
        this.address.addressType = 'Business'; // requirement: Set Address Type to display Business (Type 1) and do not allow user to edit that field
        this.email = email || '';
        this.fax = fax || '';
        this.homePhone = homePhone || '';
        this.cellPhone = cellPhone || '';
        this.busPhone = busPhone || '';
        this.busPhoneExt = busPhoneExt || '';
    }
}
