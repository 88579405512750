<div class="row">
  <div class="col-2">
    <div class="row float-left">
      <app-navigation [visibleItems]="visibleTabs"></app-navigation>
    </div>
  </div>
  <div class="col-10 pl-sm-5">
    <div class="row">
      <div class="col text-center">
        <h2>Person Reporting Suspected Fraud</h2>
        <p> Fields marked with an <span class="red-text">*</span> are required</p>
        <p *ngIf="isIndustry">Provide the requested information about yourself.  Contact the NAIC Help Desk @ (816) 783-8500 to change any of your default demographic information.</p>
      </div>
    </div>

  <div class="alert alert-danger row" *ngIf="errorMessage">{{errorMessage}}</div>

  <form [formGroup]="formGroup">
    <div *ngIf="!isIndustry" class="card shadow">
      <div class="card-body">
        <div class="row">
          <div class="col-auto card-icon">
            <fa-icon size="6x" [icon]="faUserSecret" style="vertical-align: middle;"></fa-icon>
          </div>
          <div class="col">
            <p>
              <b>YOU MAY REPORT ANONYMOUSLY:</b>
            </p>
            <p>
              No personal identifying information is required to submit an allegation of suspected fraud. For those individuals
              wishing to receive verification of the NAIC's receipt of the report, please provide your name and e-mail address.
              You may also choose to provide additional contact information to facilitate additional communication from the state
              insurance department that investigates this report.
            </p>
            <p>
              I WOULD LIKE TO REMAIN ANONYMOUS: <span style="color:red"><sup>*</sup></span>
              <input type="radio" id="anonymousYes" (click) = "onClickAnonymousYes()" formControlName="anonymous" [value]="true"/>
              <label for="anonymousYes">Yes</label>
              <input type="radio" id="anonymousNo" formControlName="anonymous" [value]="false"/>
              <label for="anonymousNo">No</label>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div [@slider]="isIndustry || this.formGroup.value.anonymous === false ? 'open' : 'closed'">
    <div *ngIf="isIndustry" class="form-group row">
      <label for="naicCocode" class="col-form-label col-sm-3 text-right">NAIC Company Code: <span class="red-text">*</span></label>
      <div class="col-sm-9">
        <select title="NAIC Company Code" [formControl]="formGroup.controls['naicCocode']" required class="form-control" id="naicCocode" name="naicCocode">
          <option [ngValue]="null" disabled hidden>Choose One</option>
          <option *ngFor="let cocode of cocodes" [value]="cocode">{{cocode}}</option>
        </select>
        <div *ngIf="formErrors.naicCocode" class="alert alert-danger">
          {{formErrors.naicCocode}}
        </div>
      </div>
    </div>
    <div *ngIf="isIndustry" class="form-group row">
      <label for="title" class="col-form-label col-sm-3 text-right">Title: <span class="red-text">*</span></label>
      <div class="col-sm-9">
        <input title="Title" [formControl]="formGroup.controls['title']" required class="form-control" id="title" name="title" />
        <div *ngIf="formErrors.title" class="alert alert-danger">
          {{formErrors.title}}
        </div>
      </div>
    </div>
    <div *ngIf="isIndustry" class="form-group row">
      <label for="department" class="col-form-label col-sm-3 text-right">Department: <span class="red-text">*</span></label>
      <div class="col-sm-9">
        <input title="Department" [formControl]="formGroup.controls['department']" required class="form-control" id="department" name="department" />
        <div *ngIf="formErrors.department" class="alert alert-danger">
          {{formErrors.department}}
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label for="firstName" class="col-form-label col-sm-3 text-right">First Name: <span *ngIf="isIndustry" class="red-text">*</span></label>
      <div class="col-sm-9">
        <input title="First Name" [formControl]="formGroup.controls['firstName']" [required]="isIndustry" class="form-control" id="firstName" name="firstName" />
        <div *ngIf="formErrors.firstName" class="alert alert-danger">
          {{formErrors.firstName}}
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label for="middleName" class="col-form-label col-sm-3 text-right">Middle Name:</label>
      <div class="col-sm-9">
        <input title="Middle Name" [formControl]="formGroup.controls['middleName']"  class="form-control" id="middleName" name="middleName" />
        <div *ngIf="formErrors.middleName" class="alert alert-danger">
          {{formErrors.middleName}}
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label for="lastName" class="col-form-label col-sm-3 text-right">Last Name: <span *ngIf="isIndustry" class="red-text">*</span></label>
      <div class="col-sm-9">
       <input title="Last Name" [formControl]="formGroup.controls['lastName']" [required]="isIndustry" class="form-control" id="lastName" name="lastName" />
        <div *ngIf="formErrors.lastName" class="alert alert-danger">
          {{formErrors.lastName}}
        </div>
      </div>
    </div>

    <app-address
      [isAddressTypeHidden]="!this.isIndustry"
      [isAddressTypeReadOnly]="true"
      [formGroup]="formGroup"
      [requiredFields]="this.isIndustry ? ['addressType','address1','city','state','zip'] : []"
      [errorMessages]="validationService.getValidationMessages()"
      [addressDecodes]="addressDecodes"
      [jurisdictions]="jurisdictions">
    </app-address>

    <fieldset>
      <legend>Contact Info</legend>
      <span *ngIf="isIndustry">At least one phone number is required. <span class="red-text">*</span></span>
      <app-contact-electronic-info
        [formGroup]="formGroup"
        [requiredFields]="this.isIndustry ? ['email'] : []"
        [errorMessages]="validationService.getValidationMessages()">
      </app-contact-electronic-info>
    </fieldset>
    </div> <!-- end of slider div -->
    <div class="form-group row">
      <div class="col-sm-12 text-center">
        <button type="button" id="reportingPersonButton" (click)="save(formGroup.value)" class="btn btn-success .navbar-right" [disabled]="!formGroup.valid">
          Next
        </button>
      </div>
    </div>
    </form>

  </div>
</div>


