<div [formGroup]="formGroup">
  <div *ngIf="!isAddrsTypeHidden()" class="form-group row">
    <label for="{{getId('addressType')}}" [class]="labelClass">Address Type: <span *ngIf="isRequired('addressType') && !isAddrsTypeReadOnly()" class="red-text">*</span></label>
    <div [className]="getColWidth()">
      <select formControlName="addressType" [required]="isRequired('addressType')" (change)="onAddressTypeChange()" class="form-control" id="{{getId('addressType')}}" #addressDecode name="{{getId('addressType')}}">
        <option *ngFor="let addressDecode of addressDecodes" [value]="addressDecode.addressName">{{addressDecode.addressName=='None'?'Unknown':addressDecode.addressName}}</option>
      </select>
      <!--<input type="text" class="form-control" id="{{getId('email')}}" name="{{getId('email')}}" placeholder="Enter Email..." formControlName="email" [required]="isRequired('email')">-->
      <div id="{{getId('addressTypeError')}}" *ngIf="addressType.invalid && (addressType.dirty || addressType.touched)" class="alert alert-danger">
        <ng-container *ngIf="addressType.errors.addressType">
          {{getErrorMessage('addressType', 'addressType')}}
        </ng-container>
      </div>
    </div>
  </div>

  <ng-container *ngIf="isAddrsTypeHidden() || this.formGroup.get('addressType').value !== 'None'">

    <div *ngIf="!isAddrsTypeHidden() && 'Other' === formGroup.controls['addressType'].value" class="form-group row">
      <label for="{{getId('addressTypeOtherDesc')}}" [class]="labelClass">If other, specify: <span class="red-text">*</span></label>
      <div [className]="getColWidth()">
        <input type="text" class="form-control" id="{{getId('addressTypeOtherDesc')}}" name="{{getId('addressTypeOtherDesc')}}" formControlName="{{getId('addressTypeOtherDesc')}}" required>
        <div id="{{getId('addressTypeOtherDescError')}}" *ngIf="addressTypeOtherDesc.invalid && (addressTypeOtherDesc.dirty || addressTypeOtherDesc.touched)" class="alert alert-danger">
          <ng-container *ngIf="addressTypeOtherDesc.errors.required">
            {{getErrorMessage('addressTypeOtherDesc', 'required')}}
          </ng-container>
          <ng-container *ngIf="addressTypeOtherDesc.errors.whitespace">
            {{getErrorMessage('addressTypeOtherDesc', 'whitespace')}}
          </ng-container>
          <ng-container *ngIf="addressTypeOtherDesc.errors.maxlength">
            {{getErrorMessage('addressTypeOtherDesc', 'maxlength')}}
          </ng-container>
        </div></div>
    </div>

    <div class="form-group row">
      <label for="{{getId('address1')}}" [class]="labelClass">Address: <span *ngIf="isRequired('address1')" class="red-text">*</span></label>
      <div [className]="getColWidth()">
        <input type="text" class="form-control" id="{{getId('address1')}}" name="{{getId('address1')}}" formControlName="{{getId('address1')}}" [required]="isRequired('address1')">
        <div id="{{getId('address1Error')}}" *ngIf="address1.invalid && (address1.dirty || address1.touched)" class="alert alert-danger">
          <ng-container *ngIf="address1.errors.required">
            {{getErrorMessage('address1', 'required')}}
          </ng-container>
          <ng-container *ngIf="address1.errors.whitespace">
            {{getErrorMessage('address1', 'whitespace')}}
          </ng-container>
          <ng-container *ngIf="address1.errors.maxlength">
            {{getErrorMessage('address1', 'maxlength')}}
          </ng-container>
        </div></div>
    </div>

    <div class="form-group row">
      <label for="{{getId('address2')}}" [class]="labelClass"></label>
      <div [className]="getColWidth()">
        <input type="text" class="form-control" id="{{getId('address2')}}" name="{{getId('address2')}}" formControlName="{{getId('address2')}}">
        <div id="{{getId('address2Error')}}" *ngIf="address2.invalid && (address2.dirty || address2.touched)" class="alert alert-danger">
          <ng-container *ngIf="address2.errors.whitespace">
            {{getErrorMessage('address2', 'whitespace')}}
          </ng-container>
          <ng-container *ngIf="address2.errors.maxlength">
            {{getErrorMessage('address2', 'maxlength')}}
          </ng-container>
        </div></div>
    </div>

    <div class="form-group row">
      <label for="{{getId('city')}}" [class]="labelClass">City: <span *ngIf="isRequired('city')" class="red-text">*</span></label>
      <div [className]="getColWidth()">
        <input type="text" class="form-control" id="{{getId('city')}}" name="{{getId('city')}}" formControlName="{{getId('city')}}" [required]="isRequired('city')">
        <div id="{{getId('cityError')}}" *ngIf="city.invalid && (city.dirty || city.touched)" class="alert alert-danger">
          <ng-container *ngIf="city.errors.required">
            {{getErrorMessage('city', 'required')}}
          </ng-container>
          <ng-container *ngIf="city.errors.whitespace">
            {{getErrorMessage('city', 'whitespace')}}
          </ng-container>
          <ng-container *ngIf="city.errors.maxlength">
            {{getErrorMessage('city', 'maxlength')}}
          </ng-container>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="{{getId('county')}}" [class]="labelClass">County:</label>
      <div [className]="getColWidth()">
        <input type="text" class="form-control" id="{{getId('county')}}" name="{{getId('county')}}" formControlName="{{getId('county')}}">
        <div id="{{getId('countyError')}}" *ngIf="county.invalid && (county.dirty || county.touched)" class="alert alert-danger">
          <ng-container *ngIf="county.errors.whitespace">
            {{getErrorMessage('county', 'whitespace')}}
          </ng-container>
          <ng-container *ngIf="county.errors.pattern">
            {{getErrorMessage('county', 'pattern')}}
          </ng-container>
          <ng-container *ngIf="county.errors.maxlength">
            {{getErrorMessage('county', 'maxlength')}}
          </ng-container>
        </div></div>
    </div>

    <div class="form-group row">
      <label for="{{getId('state')}}" [class]="labelClass">State: <span *ngIf="isRequired('state')" class="red-text">*</span></label>
      <div [className]="getColWidth()">
        <select formControlName="{{getId('state')}}" [required]="isRequired('state')" class="form-control" id="{{getId('state')}}" name="{{getId('state')}}">
          <option [ngValue]='' disabled hidden>Choose One</option>
          <option *ngFor="let juris of jurisdictions" [value]="juris.jurisdictionId">{{juris.jurisdictionId}}</option>
        </select>
        <div id="{{getId('stateError')}}" *ngIf="state.invalid && (state.dirty || state.touched)" class="alert alert-danger">
          <ng-container *ngIf="state.errors.required">
            {{getErrorMessage('state', 'required')}}
          </ng-container>
          <ng-container *ngIf="state.errors.maxlength">
            {{getErrorMessage('state', 'maxlength')}}
          </ng-container>
        </div></div>
    </div>
    <div class="form-group row">
      <label for="{{getId('zip')}}" [class]="labelClass">Zip: <span *ngIf="isRequired('zip')" class="red-text">*</span></label>
      <div [className]="getColWidth()">
        <input type="text" class="form-control" id="{{getId('zip')}}" name="{{getId('zip')}}" formControlName="{{getId('zip')}}" [required]="isRequired('zip')">
        <div id="{{getId('zipError')}}" *ngIf="zip.invalid && (zip.dirty || zip.touched)" class="alert alert-danger">
          <ng-container *ngIf="zip.errors.required">
            {{getErrorMessage('zip', 'required')}}
          </ng-container>
          <ng-container *ngIf="zip.errors.pattern">
            {{getErrorMessage('zip', 'pattern')}}
          </ng-container>
        </div></div>
    </div>

  </ng-container>

</div>



