import {ValidatorFn, AbstractControl} from '@angular/forms';

export function ssnValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} => {
        let ssn = control.value;
        if (ssn === null) {
          return null;
        }
        ssn = ssn.replace(/[^\w\s]/gi, '');
        ssn = ssn.replace(/ /g, '');
        let no = false;
        let area = '';
        let group = '';
        let serial = '';
        if (ssn.length > 3 && ssn.length < 6) {
            area = ssn.substr(0, 3);
            group = ssn.substr(3, 2);
        }
        if (ssn.length > 5) {
            area = ssn.substr(0, 3);
            group = ssn.substr(3, 2);
            serial = ssn.substr(5, 4);
        }
        if (area && (area === '000' || area === '666' || parseInt (area, 10) > 899)) {
            no = true;
        }
        if (group && group === '00') {
            no = true;
        }
        if (serial && serial === '0000') {
            no = true;
        }
        return no ? {'invalidSsn': {ssn}} : null;
    };
}
