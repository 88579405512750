import { Component, OnInit } from '@angular/core';
import {CommonService} from '../shared/common.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.css']
})
export class TermsOfUseComponent implements OnInit {

  constructor(private commonService: CommonService,
              private router: Router) { }

  ngOnInit() { }

  startReport(): void {
    this.commonService.setAvailableTabs([]); // clear navigation panel
    this.commonService.resetOfrsReport();
    this.commonService.getOfrsReport().ofrsReportKey.reportType = 'C';
    this.router.navigate(['jurisdiction-selection']);
  }

}
