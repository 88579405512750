<div class="row">
  <div class="col-2">
    <div class="row">
      <app-navigation [visibleItems]="visibleTabs"></app-navigation>
    </div>
  </div>
  <div class="col-10">
    <h2>Other Agencies where this Fraud was reported</h2>

    <form [formGroup]="formGroup">
      <div>
        <div class="formGroup row">
          <div class="col-sm-10 text-left">
            <label><input id="noneOtherAgencies" formControlName="noneOtherAgencies" type="checkbox" (change)="checkboxStateChanged(formGroup.get('noneOtherAgencies').value)" />&nbsp;None -- No other Agencies were notified of this Fraud</label>
          </div>
        </div>
        <div class="formGroup row">
          <div class="col-sm-8 text-left">Click the New button to add detail information of an Other Agency where fraud is reported</div>
        </div>

        <div class="form-group row" *ngIf="otherAgencies && otherAgencies.length > 0">
          <div class="col-sm-6 text-sm-center">OR</div>
          <br>
          <div class="col-sm-10">Select an existing Other Agency to update its detailed information</div>
          <br>
          <br>
          <div class="col-sm-12 text-left">
            <ul class="nav flex-column"  id="existingOtherAgenciesList">
              <li *ngFor="let otherAgency of otherAgencies; let idx = index" class="nav-item" >
                <div class="form-group row">
                  <div class="col-sm-8"><a class="text-primary" [routerLink]="['./other-agency', idx]">{{otherAgency.agencyName}}</a>
                  </div>
                  <div class="col-sm-2">
                    <button id="removeOtherAgenciesButton{{idx}}" type="button" class="btn btn-secondary" (click)="removeOtherAgencyDetails(idx)" >
                      <fa-icon [icon]="faTrash"></fa-icon>
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="form-group row" style="margin-top:10px">
          <div class="col-sm-1">
            <button type="button" id="newButton" [disabled]="noOtherAgenciesFlag || otherAgencies.length >= 10" (click)="newOtherAgency()" class="btn btn-success .navbar-right"> New </button>
          </div>
          <div class="col-sm-4 text-sm-center">
            <button type="button" id="otherAgenciesLandingButton"  [disabled]="!(noOtherAgenciesFlag || otherAgencies && otherAgencies.length > 0)" (click)="next()" class="btn btn-success .navbar-right">
              Next
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
