import { Component, OnInit } from '@angular/core';
import {FraudDecode} from '../shared/fraudDecode.model';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {CommonService} from '../shared/common.service';
import {Router} from '@angular/router';
import {FraudTypes} from '../shared/fraud-types.model';
import {faMinus, faPlus} from '@fortawesome/free-solid-svg-icons';
import {Observable} from 'rxjs';
import {OfrsIndustryValidationService} from '../shared/validation/validation.service';
import {notOnlyWhitespaceValidator} from '../shared/validation/not-only-whitespace.directive';
import {charLength} from '../shared/validation/char-length-validator';

@Component({
  selector: 'app-fraud-type',
  templateUrl: './fraud-type.component.html',
  styleUrls: ['./fraud-type.component.css']
})
export class FraudTypeComponent implements OnInit {
  fraudTypes: FraudTypes;
  propertyCasualtyFraudCodes: FraudDecode[];
  healthCareFraudCodes: FraudDecode[];
  generalFraudCodes: FraudDecode[];
  formGroup: FormGroup;
  formErrors: any;

  errorMessage: string;
  faPlus = faPlus;
  faMinus = faMinus;

  pcFraudTypeCollapseFlag: boolean;
  hcFraudTypeCollapseFlag: boolean;
  gnFraudTypeCollapseFlag: boolean;
  stored = false;
  selectedPcDecodes: number[];
  selectedHcDecodes: number[];
  selectedGnDecodes: number[];
  pcOther: string;
  hcOther: string;
  gnOther: string;
  visibleTabs: string[];
  pcFraudCodes: FormArray;
  hcFraudCodes: FormArray;
  gnFraudCodes: FormArray;

  constructor(private commonService: CommonService,
              private formBuilder: FormBuilder,
              private router: Router,
              public validationService: OfrsIndustryValidationService) {
    this.visibleTabs = this.commonService.getAvailableTabs().slice();
    this.pcFraudTypeCollapseFlag = true;
    this.hcFraudTypeCollapseFlag = true;
    this.gnFraudTypeCollapseFlag = true;
    this.fraudTypes = this.commonService.getOfrsReport().fraudTypes;
    if (this.fraudTypes.fraudTypeIds == null) {
      this.fraudTypes.fraudTypeIds = [];
    }
    if (this.fraudTypes.propertyCasualtyOther == null) {
      this.fraudTypes.propertyCasualtyOther = '';
    }
    if (this.fraudTypes.healthCareOther == null) {
      this.fraudTypes.healthCareOther = '';
    }
    if (this.fraudTypes.generalOther == null) {
      this.fraudTypes.generalOther = '';
    }
  }

  ngOnInit(): void {
    if (this.visibleTabs.indexOf('fraudTypeNav') === -1) {
      this.visibleTabs.push('fraudTypeNav');
    }
    this.commonService.getFraudCodes()
      .subscribe(data => {
        this.propertyCasualtyFraudCodes = data.filter((
          fraudDecode: FraudDecode) => fraudDecode.fraudCategory === 'PROPERTY/CASUALTY');
        this.healthCareFraudCodes = data.filter((
          fraudDecode: FraudDecode) => fraudDecode.fraudCategory ===  'HEALTH CARE');
        this.generalFraudCodes = data.filter((
          fraudDecode: FraudDecode) => fraudDecode.fraudCategory ===  'AGENT');
        this.createFraudTypesForm();
      });
  }

  createFraudTypesForm(): void {
    const pcDecodeControls = this.propertyCasualtyFraudCodes.map(c =>
      this.fraudTypes.fraudTypeIds.includes( c.fraudTypeId ) ?
        new FormControl(c.fraudTypeId) : new FormControl(false));
    this.pcFraudCodes = new FormArray(pcDecodeControls);
    const hcDecodeControls = this.healthCareFraudCodes.map(c =>
      this.fraudTypes.fraudTypeIds.includes( c.fraudTypeId ) ?
        new FormControl(c.fraudTypeId) : new FormControl(false));
    this.hcFraudCodes = new FormArray(hcDecodeControls);
    const gnDecodeControls = this.generalFraudCodes.map(c =>
      this.fraudTypes.fraudTypeIds.includes( c.fraudTypeId ) ?
        new FormControl(c.fraudTypeId) : new FormControl(false));
    this.gnFraudCodes = new FormArray(gnDecodeControls);
    this.formGroup = this.formBuilder.group({
      isNone: [this.fraudTypes.isNone, ],
      pcFraudCodes: this.pcFraudCodes,
      pcFraudTypeOther: [this.fraudTypes.propertyCasualtyOther, [notOnlyWhitespaceValidator(), charLength(70)]],
      hcFraudCodes: this.hcFraudCodes,
      hcFraudTypeOther: [this.fraudTypes.healthCareOther, [notOnlyWhitespaceValidator(), charLength(70)]],
      gnFraudCodes: this.gnFraudCodes,
      gnFraudTypeOther: [this.fraudTypes.generalOther, [notOnlyWhitespaceValidator(), charLength(70)]],
    });
    this.formGroup.valueChanges.subscribe(data => this.onValueChanged(data));

    this.onValueChanged();
  }

  onValueChanged(data?: any) {
    if (!this.formGroup) {return; }
    const form = this.formGroup;
    this.formErrors = this.validationService.setErrors(form);
    this.hasError();
  }

  hasError(): boolean {
    this.errorMessage = '';
    this.selectedPcDecodes = this.formGroup.value.pcFraudCodes
      .map((v, i) => v ? this.propertyCasualtyFraudCodes[i].fraudTypeId : null)
      .filter(v => v !== null);
    this.selectedHcDecodes = this.formGroup.value.hcFraudCodes
      .map((v, i) => v ? this.healthCareFraudCodes[i].fraudTypeId : null)
      .filter(v => v !== null);
    this.selectedGnDecodes = this.formGroup.value.gnFraudCodes
      .map((v, i) => v ? this.generalFraudCodes[i].fraudTypeId : null)
      .filter(v => v !== null);
    this.pcOther = this.formGroup.value.pcFraudTypeOther;
    this.hcOther = this.formGroup.value.hcFraudTypeOther;
    this.gnOther = this.formGroup.value.gnFraudTypeOther;

    const total = this.selectedPcDecodes.length + this.selectedHcDecodes.length + this.selectedGnDecodes.length;
    if ( total === 0 && !this.formGroup.value.isNone) {
      if (this.formGroup.untouched) {
        this.errorMessage = '';
      } else {
        this.errorMessage = 'At least one selection is required';
      }
      return true;
    }
    if (this.formGroup.value.isNone && total > 0) {
      this.errorMessage = 'None is invalid with any other selection';
      return true;
    }

    if (this.selectedPcDecodes.length > 0 && this.selectedPcDecodes.includes(this.propertyCasualtyFraudCodes[this.propertyCasualtyFraudCodes.length - 1].fraudTypeId) && this.pcOther.length === 0 ) {
      this.formErrors.pcFraudTypeOther = 'An explanation is required when Other is selected';
      return true;
    }

    if (this.selectedHcDecodes.length > 0 && this.selectedHcDecodes.includes(this.healthCareFraudCodes[this.healthCareFraudCodes.length - 1].fraudTypeId) && this.hcOther.length === 0 ) {
      this.formErrors.hcFraudTypeOther = 'An explanation is required when Other is selected';
      return true;
    }

    if (this.selectedGnDecodes.length > 0 && this.selectedGnDecodes.includes(this.generalFraudCodes[this.generalFraudCodes.length - 1].fraudTypeId) && this.gnOther.length === 0 ) {
      this.formErrors.gnFraudTypeOther = 'An explanation is required when Other is selected';
      return true;
    }
    return false;
  }

  save(): void {
    this.fraudTypes.fraudTypeIds = [...this.selectedPcDecodes, ...this.selectedHcDecodes, ...this.selectedGnDecodes ];
    this.fraudTypes.isNone = this.formGroup.value.isNone;
    this.fraudTypes.propertyCasualtyOther = this.pcOther;
    this.fraudTypes.healthCareOther = this.hcOther;
    this.fraudTypes.generalOther = this.gnOther;
    this.commonService.getOfrsReport().fraudTypes = this.fraudTypes;
    this.commonService.setAvailableTabs(this.visibleTabs);
    this.stored = true;
    this.router.navigate(['suspects']);
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.stored || !this.formGroup.dirty;
  }

  pcOtherCheckboxStateChanged(): void {
    if (this.formGroup.value.pcFraudCodes[this.propertyCasualtyFraudCodes.length - 1] === false) {
      this.formGroup.get('pcFraudTypeOther').setValue('');
    }
  }

  pcOtherTextChanged(): void {
    if (this.formGroup.value.pcFraudTypeOther.length > 0 && this.formGroup.value.pcFraudCodes[this.propertyCasualtyFraudCodes.length - 1] === false) {
      this.pcFraudCodes.controls[this.propertyCasualtyFraudCodes.length - 1].setValue(true);
    }
  }

  hcOtherCheckboxStateChanged(): void {
    if (this.formGroup.value.hcFraudCodes[this.healthCareFraudCodes.length - 1] === false) {
      this.formGroup.get('hcFraudTypeOther').setValue('');
    }
  }

  hcOtherTextChanged(): void {
    if (this.formGroup.value.hcFraudTypeOther.length > 0 && this.formGroup.value.hcFraudCodes[this.healthCareFraudCodes.length - 1] === false) {
      this.hcFraudCodes.controls[this.healthCareFraudCodes.length - 1].setValue(true);
    }
  }

  gnOtherCheckboxStateChanged(): void {
    if (this.formGroup.value.gnFraudCodes[this.generalFraudCodes.length - 1] === false) {
      this.formGroup.get('gnFraudTypeOther').setValue('');
    }
  }

  gnOtherTextChanged(): void {
    if (this.formGroup.value.gnFraudTypeOther.length > 0 && this.formGroup.value.gnFraudCodes[this.generalFraudCodes.length - 1] === false) {
      this.gnFraudCodes.controls[this.generalFraudCodes.length - 1].setValue(true);
    }
  }
}
