import {Component, Input, OnInit} from '@angular/core';
import {OfrsReport} from '../shared/ofrs-report.model';
import {isEmpty, round} from 'lodash';
import {Party} from '../shared/party.model';
import {Address} from '../shared/address.model';

@Component({
  selector: 'app-html-view',
  templateUrl: './html-view.component.html',
  styleUrls: ['./html-view.component.css']
})
export class HtmlViewComponent implements OnInit {
  reportJson:  string;
  ofrsReport: OfrsReport;

  constructor() {}

  ngOnInit() {
    this.ofrsReport = JSON.parse(localStorage.getItem('state'));
    // If testing locally, you may comment out line below to keep data between code refreshes
    localStorage.removeItem('state');
  }

  /**
   * Converts the filesize in Bytes to KiloBytes and rounds to single decimal point
   * @param fileSize - size of a file in bytes
   */
  convertToKiloBytes(fileSize: number): number {
    if (fileSize == null) {
      return 0;
    }
    return round(fileSize * 0.001, 1) ;
  }

  /**
   * Increases the index from loop value to human readable version
   * @param index - zero indexed number
   */
  incrementIndexForLabel(index: number): number {
    if (index == null) {
      return 0;
    }
    return index + 1;
  }

  /**
   * Takes a 9 length SSN string and reformats to mask the first 5 digits
   * @param id - the SSN identifier
   */
  maskSSNIdentifier(id: string): string {
    // SSN Length = 9
    if (id == null || id.length !== 9) {
      return '';
    }
    return 'XXX-XX-' + id.substr(id.length - 4, 4);
  }

  /**
   * Formats a String into a phone number format
   * @param phoneNumber - 10 length String
   */
  formatPhoneNumber(phoneNumber: String): String {
    // Phone numbers are 10 digits long
    if (phoneNumber == null || phoneNumber === '' || phoneNumber.length !== 10) {
      return '';
    }
    return '(' + phoneNumber.substr(0, 3) + ')-' + phoneNumber.substr(3, 3) + '-' + phoneNumber.substr(6, 4);
  }

  /**
   * Takes an address and displays all the appropriate fields with proper formatting
   * @param address - the Address to modify
   */
  getCleanAddress(address: Address): String {
    if (address == null) {
      return '';
    }
    let cleanAddress: String = '';
    if (address.address1 != null && !isEmpty(address.address1)) {
      cleanAddress =  cleanAddress + ' ' + address.address1;
    }
    if (address.address2 != null && !isEmpty(address.address2)) {
      cleanAddress =  cleanAddress + ' ' + address.address2;
    }
    if (address.city != null && !isEmpty(address.city)) {
      cleanAddress =  cleanAddress + ' ' + address.city;
    }
    if (address.state != null && !isEmpty(address.state)) {
      if (address.address1 != null && !isEmpty(address.address1)
        || address.address2 != null && !isEmpty(address.address2)
        || address.city != null && !isEmpty(address.city)) {
        cleanAddress = cleanAddress + ',';
      }
      cleanAddress =  cleanAddress + ' ' + address.state;
    }
    if (address.zip != null && !isEmpty(address.zip)) {
      cleanAddress =  cleanAddress + ' ' + address.zip;
    }
    if (address.county != null && !isEmpty(address.county)) {
      if (address.address1 != null && !isEmpty(address.address1)
        || address.address2 != null && !isEmpty(address.address2)
        || address.city != null && !isEmpty(address.city)
        || address.state != null && !isEmpty(address.state)
        || address.zip != null && !isEmpty(address.zip)) {
        cleanAddress = cleanAddress + ',';
      }
      cleanAddress =  cleanAddress + ' ' + address.county;
    }
    return cleanAddress;
  }

  /**
   * Creates a list of suspect's partytypes and filters out duplicate suspect partytypes
   * @param suspects - the reported suspects
   */
  getDistinctSuspectPartyTypes(suspects: Party[]): String[] {
    if (suspects == null || suspects.length < 1) {
      return [];
    }
    const results: String[] = [];
    suspects.map((suspect) => results.push(suspect.partyType));
    return results.filter((item, index) => results.indexOf(item) === index);
  }
}
