import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import { Jurisdiction } from './jurisdiction.model';
import { FraudDecode } from './fraudDecode.model';
import { OfrsReport } from './ofrs-report.model';
import { PartyDecode } from './party-decode.model';
import { AddressDecode } from './address-decode.model';
import { EvidenceDecode } from './evidenceDecode.model';
import { InsuranceDecode } from './insurance-decode';
import { ClaimStatusDecode } from './claim-status-decode';
import {AgencyTypeDecode} from './agencyType-decode.model';
import {OfrsReportKey} from './ofrs-report-key.model';
import {environment} from '../../environments/environment';
import {SearchCriteria} from './search-criteria.model';
import {OfrsReportInfo} from './ofrs-report-info.model';
import {Company} from './company.model';

@Injectable({ providedIn: 'root' })
export class CommonService {

  private commonUrl = environment.apiUrl;
  private ofrsReport: OfrsReport;
  private addressDecodes: Observable<AddressDecode[]>;
  private jurisdictions: Observable<Jurisdiction[]>;
  private cocodes: Observable<number[]>;
  private fraudDecodes: Observable<FraudDecode[]>;
  private partyDecodes: Observable<PartyDecode[]>;
  private evidenceDecodes: Observable<EvidenceDecode[]>;
  private insuranceDecodes: Observable<InsuranceDecode[]>;
  private claimStatusDecodes: Observable<ClaimStatusDecode[]>;
  private agencyTypeDecodes: Observable<AgencyTypeDecode[]>;
  private availableTabs: string[] = [];
  private httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  private loginId: string = null;
  private loginCocode: Observable<number> = null;
  private loginCompany: Observable<Company> = null;

  constructor(
    private http: HttpClient) {
      this.ofrsReport = new OfrsReport();
  }

  getLoginId(): string {
    return this.loginId;
  }
  setLoginId(loginId: string): void {
    this.loginId = loginId;
  }

  postOfrsReport(ofrsReport: OfrsReport): Observable<OfrsReportKey> {
    const url = ofrsReport.ofrsReportKey.reportType === 'I' ? `${this.commonUrl}/reports` : `${this.commonUrl}/consumer-reports`;
    return this.http.post<OfrsReportKey>(url, ofrsReport, this.httpOptions);
  }


  getAvailableTabs(): string[] {
    return this.availableTabs;
  }

  setAvailableTabs(value: string[]) {
    this.availableTabs = value;
  }
  /**
   * GET all USA Jurisdictions from the server
   */
  getJurisdictions(): Observable<Jurisdiction[]> {
    if (this.jurisdictions && this.jurisdictions !== null) {
      return this.jurisdictions;
    }
    const url = `${this.commonUrl}/jurisdictions`;
    this.jurisdictions = this.http.get<Jurisdiction[]>(url)
      .pipe(
        catchError(this.handleError('getJurisdictions', []))
      );
    return this.jurisdictions;
  }

  getAgencyTypes(): Observable<AgencyTypeDecode[]> {
    if (this.agencyTypeDecodes && this.agencyTypeDecodes !== null) {
      return this.agencyTypeDecodes;
    }
    const url = `${this.commonUrl}/agency-types`;
    this.agencyTypeDecodes = this.http.get<AgencyTypeDecode[]>(url)
      .pipe(
        catchError(this.handleError('getAgencyTypes', []))
      );
    return this.agencyTypeDecodes;
  }

  /**
   * GET all fraud codes from the server
   */
  getFraudCodes(): Observable<FraudDecode[]> {
    if (this.fraudDecodes && this.fraudDecodes !== null) {
      return this.fraudDecodes;
    }
    const url = `${this.commonUrl}/fraud-types`;
    this.fraudDecodes = this.http.get<FraudDecode[]>(url)
      .pipe(
        catchError(this.handleError('getFraudDecodes', []))
      );
    return this.fraudDecodes;
  }

  /**
   * GET all party decodes from the server
   */
  getPartyDecodes(): Observable<PartyDecode[]> {
    if (this.partyDecodes && this.partyDecodes !== null) {
      return this.partyDecodes;
    }
    const url = `${this.commonUrl}/party-types`;
    this.partyDecodes = this.http.get<PartyDecode[]>(url)
      .pipe(
        catchError(this.handleError('getPartyDecodes', []))
      );
    return this.partyDecodes;
  }

  /**
   * GET all address decodes from the server
   */
  getAddressDecodes(): Observable<AddressDecode[]> {
    if (this.addressDecodes && this.addressDecodes !== null) {
      return this.addressDecodes;
    }
    const url = `${this.commonUrl}/address-types`;
    this.addressDecodes = this.http.get<AddressDecode[]>(url)
      .pipe(
        catchError(this.handleError('getAddressDecodes', []))
      );
    return this.addressDecodes;
  }

  /**
   * GET all evidence decodes from the server
   */
  getEvidenceDecodes(): Observable<EvidenceDecode[]> {
    if (this.evidenceDecodes && this.evidenceDecodes !== null) {
      return this.evidenceDecodes;
    }
    const url = `${this.commonUrl}/evidence-types`;
    this.evidenceDecodes = this.http.get<EvidenceDecode[]>(url)
      .pipe(
        catchError(this.handleError('getEvidenceDecodes', []))
      );
    return this.evidenceDecodes;
  }

  /**
   * GET all insurance decodes from the server
   */
  getInsuranceDecodes(): Observable<InsuranceDecode[]> {
    if (this.insuranceDecodes && this.insuranceDecodes !== null) {
      return this.insuranceDecodes;
    }
    this.insuranceDecodes = this.http.get<InsuranceDecode[]>(`${this.commonUrl}/insurance-types`).pipe(
      catchError(this.handleError('getInsuranceDecodes', []))
    );
    return this.insuranceDecodes;
  }

  /**
   * GET all claim status decodes from the server
   */
  getClaimStatusDecodes(): Observable<ClaimStatusDecode[]> {
    if (this.claimStatusDecodes && this.claimStatusDecodes !== null) {
      return this.claimStatusDecodes;
    }
    this.claimStatusDecodes = this.http.get<ClaimStatusDecode[]>(`${this.commonUrl}/claim-status-types`).pipe(
      catchError(this.handleError('getClaimStatusDecodes', []))
    );
    return this.claimStatusDecodes;
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  getLoginCocode(): Observable<number> {
    return this.loginCocode;
  }

  getLoginCompany(): Observable<Company> {
    return this.loginCompany;
  }

  loadCompanyCocodeAndName(loginId: string): void {
    if (loginId !== this.loginId) {

      this.loginCocode = null;
      this.loginCompany = null;
      if (loginId !== null) {
        this.loginCocode = this.http.get<number>(`${this.commonUrl}/logincocode/` + loginId);
        this.loginCocode.subscribe(
          (value: number) => {
              this.loginCompany = this.http.get<Company>(`${this.commonUrl}/company/` + value);
          },
          error => console.error(error)
        );
      }
      this.loginId = loginId;
    }
  }




  getOfrsReport(): OfrsReport {
    return this.ofrsReport;
  }

  setOfrsReport(ofrsReport: OfrsReport) {
    this.ofrsReport = ofrsReport;
  }

  resetOfrsReport(): OfrsReport {
    this.ofrsReport = new OfrsReport();
    return this.getOfrsReport();
  }

  displayName(entityType: string, first: string, middle: string, last: string,  business: string): string {
    let displayName = '';
    if (entityType === 'Business') {
      if (business && business !== null && business.length > 0) {
        displayName = business;
      }
    } else {
      displayName = this.formName(this.formName(first, middle), last);
    }
    return displayName;
  }

  formName(x: string, y: string): string {
    const showX =  x && x !== null && x.length > 0;
    const showY = y && y !== null && y.length > 0;
    if (showX && showY) {
      return x + ' ' + y;
    }
    if (showX && !showY) {
      return x;
    }
    if (!showX && showY) {
      return y;
    }
    if (!showX && !showY) {
      return '';
    }
  }

  loadOfrsReport(transactionId: number):  Observable<OfrsReport> {
    const url = `${this.commonUrl}/reports/` + transactionId;
    return this.http.get<OfrsReport>(url).pipe(map(r => new OfrsReport(r)));
  }

  search(criteria: SearchCriteria):  Observable<OfrsReportInfo[]> {
    const url = `${this.commonUrl}/search/`;
    return this.http.post<OfrsReportInfo[]>(url, criteria, this.httpOptions);
  }

  downloadPDFReport(transactionId: number): Observable<Blob> {
    let url = `${this.commonUrl}/pdf?transactionId=` + transactionId;
    if (this.ofrsReport && this.ofrsReport.ofrsReportKey && this.ofrsReport.ofrsReportKey.reportType === 'C') {
      url = url + '&signature=' + this.ofrsReport.ofrsReportKey.signature;
    }
    return this.http.get(url, { responseType: 'blob' }).pipe(map((resp: any) => {
        return new Blob([resp], { type: 'application/pdf' });
    }));
  }

}
