import { Directive } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';

@Directive({
  selector: '[noFutureDate]',
  providers: [{provide: NG_VALIDATORS, useExisting: NoFutureDateDirective, multi: true}]
})
export class NoFutureDateDirective implements Validator {

  constructor() { }

  validate(control: AbstractControl): {[key: string]: any} {
    if (control.value === null || control.value === undefined) {
      return null;
    }

    if (!(control.value instanceof Date)) {
      return null;
    }

    const isValid = moment(control.value).isSameOrBefore(moment());
    return isValid ? null : { 'noFutureDate': true };
  }

}

export function noFutureDateValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} => {
    if (control.value === null || control.value === undefined) {
      return null;
    }

    if (!(control.value instanceof Date)) {
      return null;
    }

    const isValid = moment(control.value).isSameOrBefore(moment());
    return isValid ? null : { 'noFutureDate': true };
  };
}
