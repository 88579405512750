<ngx-loading [show]="this.authorizationGuard.isActive"></ngx-loading>
<div  class="full-page">
  <div >
    <ncui-header
      [appTitle]="'OFRS'"
      [appSubtitle]="'Online Fraud Reporting System'"
      [appLogo]="'assets/ofrs-icon.png'"
      [hideAppHeader]="false"
      [loggedInUser]="getName()"
      [realm]="environmentName"
      (login)="userService.login()"
      (logout)="userService.logout()"
      [aboutModalComponentListItems]="aboutDialog"></ncui-header>
    <div id="wrapper">
      <div class="container">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
  <ncui-footer></ncui-footer>
</div>
