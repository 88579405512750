import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {OfrsUserService} from '../ofrs-user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationGuard implements CanActivate {

  isActive = false;

  constructor(private userService: OfrsUserService) { }

  async canActivate(router: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    this.isActive = true;
    try {
      if (this.userService.user) {
        return true;
      }
      await this.userService.login();
      return true;
    } catch (e) {
      console.error(e);
      return false;
    } finally {
      this.isActive = false;
    }
  }

}
