<div class="row">
  <div class="col-2">
    <div class="row">
      <app-navigation [visibleItems]="visibleTabs"></app-navigation>
    </div>
  </div>

  <div class="col-10 pl-sm-5" text-center>
    <div class="alert alert-danger row" *ngIf="errorMessage">{{errorMessage}}</div>
    <div class="col">
      <h2>Fraud Type</h2>
    </div>
    <form [formGroup]="formGroup" *ngIf="propertyCasualtyFraudCodes">
      <div class="col-sm-12">
        <div>
           <p>Select all that apply or&nbsp;&nbsp;&nbsp;<input id="noneFraudType" type="checkbox" formControlName="isNone">&nbsp;None</p>
        </div>
      </div>
      <div class="col-sm-12">
        <accordion>
          <accordion-group (isOpenChange)="pcFraudTypeCollapseFlag = !pcFraudTypeCollapseFlag">
            <span accordion-heading id="pcSpan">
               <fa-icon [icon]="pcFraudTypeCollapseFlag ? faPlus : faMinus"></fa-icon>&nbsp;Property/Casualty Fraud Type
            </span>
            <div id="pcFraudTypes" class="row">
              <label formArrayName="pcFraudCodes" *ngFor="let fraudDecode of propertyCasualtyFraudCodes; let i = index; last as isLast"
                     [style.display]="isLast ? 'inline' : ''">
                <input *ngIf="!isLast" type="checkbox" [formControlName]="i"/>
                <input *ngIf="isLast" id="pcOtherCheckBox" type="checkbox" [formControlName]="i"
                       (change)="pcOtherCheckboxStateChanged()"/>
                {{fraudDecode.fraudName}}
              </label>
              <input id="pcOther" type="text" formControlName="pcFraudTypeOther" (change)="pcOtherTextChanged()"/>
            </div>
            <div *ngIf="formErrors.pcFraudTypeOther" class="alert alert-danger">
              {{formErrors.pcFraudTypeOther}}
            </div>
          </accordion-group>
          <accordion-group (isOpenChange)="hcFraudTypeCollapseFlag = !hcFraudTypeCollapseFlag">
            <span accordion-heading id="hcSpan">
              <fa-icon [icon]="hcFraudTypeCollapseFlag ? faPlus : faMinus"></fa-icon>&nbsp;Health Care Fraud Type
            </span>
            <div id="hcFraudTypes" class="row">
              <label formArrayName="hcFraudCodes" *ngFor="let fraudDecode of healthCareFraudCodes; let i = index; last as isLast"
                     [style.display]="isLast ? 'inline' : ''">
                <input *ngIf="!isLast" type="checkbox" [formControlName]="i"/>
                <input *ngIf="isLast" id="hcOtherCheckBox" type="checkbox" [formControlName]="i"
                       (change)="hcOtherCheckboxStateChanged()"/>
                {{fraudDecode.fraudName}}
              </label>
              <input id="hcOther" type="text" formControlName="hcFraudTypeOther" (change)="hcOtherTextChanged()"/>
            </div>
            <div *ngIf="formErrors.hcFraudTypeOther" class="alert alert-danger">
              {{formErrors.hcFraudTypeOther}}
            </div>
          </accordion-group>
          <accordion-group (isOpenChange)="gnFraudTypeCollapseFlag = !gnFraudTypeCollapseFlag">
            <span accordion-heading id="gnSpan">
                <fa-icon [icon]="gnFraudTypeCollapseFlag ? faPlus : faMinus"></fa-icon>&nbsp;General Fraud Type
            </span>
            <div id="gnFraudTypes" class="row">
              <label formArrayName="gnFraudCodes" *ngFor="let fraudDecode of generalFraudCodes; let i = index; last as isLast"
                     [style.display]="isLast ? 'inline' : ''">
                <input *ngIf="!isLast" type="checkbox" [formControlName]="i"/>
                <input *ngIf="isLast" id="gnOtherCheckBox" type="checkbox" [formControlName]="i"
                       (change)="gnOtherCheckboxStateChanged()"/>
                {{fraudDecode.fraudName}}
              </label>
              <input id="gnOther" type="text" formControlName="gnFraudTypeOther" (change)="gnOtherTextChanged()"/>
            </div>
            <div *ngIf="formErrors.gnFraudTypeOther" class="alert alert-danger">
              {{formErrors.gnFraudTypeOther}}
            </div>
          </accordion-group>
        </accordion>
      </div>
      <br/>
      <div class="form-group row">
        <div class="col-lg-12 text-center">
          <button type="button" id="fraudTypeSaveButton" [disabled]="hasError() || !formGroup.valid" (click)="save()" class="btn btn-success .navbar-right" >Next</button>
        </div>
      </div>
    </form>
  </div>
</div>
