<div class="row">
  <div class="col-2">
    <div class="row">
      <app-navigation [visibleItems]="visibleTabs"></app-navigation>
    </div>
  </div>
  <div class="col-10 pl-sm-5" text-center>
    <div id="generalErrorMsg" class="alert alert-danger row" *ngIf="errorMessage">{{errorMessage}}</div>
    <div class="col">
      <h2>Evidence Type</h2>
    </div>
    <form [formGroup]="formGroup" *ngIf="allEvidenceDecodes">
      <div class="col-sm-12">
        <div>
          <p>Select all that apply or&nbsp;&nbsp;&nbsp;<input id="noneEvidenceType" type="checkbox" formControlName="isNone" (change)="onCheckNone($event )">&nbsp;None</p>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-sm-6">
          <label formArrayName="evidenceControlArray" *ngFor="let evidenceDecode of firstHalfEvidenceDecodes; let i = index" class="col-sm-12">
            <div *ngIf="i < splitIndex"><input type="checkbox" [formControlName]="i">
              {{firstHalfEvidenceDecodes[i].evidenceName}}</div>
          </label>
        </div>
        <div class="col-sm-6">
          <label formArrayName="evidenceControlArray" *ngFor="let evidenceDecode of secondHalfEvidenceDecodes; let i = index" class="col-sm-12">
            <div *ngIf="i < allEvidenceDecodes.length-splitIndex-1"><input type="checkbox" [formControlName]="splitIndex+i">
              {{secondHalfEvidenceDecodes[i].evidenceName}}</div>
            <div *ngIf="i === allEvidenceDecodes.length-1-splitIndex"><input   type="checkbox" [formControlName]="splitIndex+i" (change)="onCheck($event,splitIndex+i )" >
              {{secondHalfEvidenceDecodes[i].evidenceName}} <input  id="evidenceOtherDesc" type="text" [formControlName]="splitIndex+i+1" (change)="onTextChange()"></div>
          </label>
        </div>
      </div>
      <div *ngIf="this.otherDescriptionErrorMessage" class="alert alert-danger">
        {{otherDescriptionErrorMessage}}
      </div>

      <div class="form-group row">
        <div class="col-lg-12 text-center">
          <button type="button" id="evidenceTypeSaveButton" [disabled]="hasError()" (click)="save()" class="btn btn-success .navbar-right" >Next</button>
        </div>
      </div>
    </form>
  </div>
</div>
