import { Component, OnInit } from '@angular/core';
import {CommonService} from '../shared/common.service';
import {Router} from '@angular/router';
import {OfrsUserService} from '../shared/ofrs-user.service';
import {User} from '../shared/user.model';
import {faPlus, faSearch, faFilePdf} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements OnInit {

  readonly faPlus = faPlus;
  readonly faSearch = faSearch;
  readonly faFilePdf = faFilePdf;
  user: User;
  visibleTabs: string[];

  constructor(private commonService: CommonService,
              private userService: OfrsUserService,
              private router: Router) { }

  ngOnInit() {
    this.visibleTabs = this.commonService.getAvailableTabs();
    if (this.visibleTabs.indexOf('welcomeNav') === -1) {
      this.visibleTabs.push('welcomeNav');
    }
    this.user = this.userService.user;
  }

  startReport(): void {
    this.commonService.resetOfrsReport();
    this.commonService.getOfrsReport().getReporter().loginId = this.user.loginId;
    this.router.navigate(['jurisdiction-selection']);
  }
}
