import {Dates} from './util/dates.util';

export class SearchCriteria {
  reportId: Number;
  suspectLastName: string;
  policyNumber: string;
  claimNumber: string;
  submitDateFrom: Date;
  submitDateTo: Date;
  reporterLastName: string;
  constructor(
    reportId?: Number,
    suspectLastName?: string,
    policyNumber?: string,
    claimNumber?: string,
    submitDateFrom?: Date,
    submitDateTo?: Date,
    reporterLastName?: string
  ) {
    this.reportId = reportId || null;
    this.suspectLastName = suspectLastName || '';
    this.policyNumber = policyNumber || '';
    this.claimNumber = claimNumber || '';
    this.submitDateFrom = submitDateFrom || null;
    this.submitDateTo = submitDateTo || null;
    this.reporterLastName = reporterLastName || '';
  }
  toJSON() {
    return Object.assign({}, this, {
      submitDateFrom: Dates.format(this.submitDateFrom),
      submitDateTo: Dates.format(this.submitDateTo)
    });
  }

}
