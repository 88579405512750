import { Component, OnInit } from '@angular/core';
import {CommonService} from '../shared/common.service';
import {Router} from '@angular/router';
import {Party} from '../shared/party.model';
import {faTrash } from '@fortawesome/free-solid-svg-icons';
import {BsModalService, ModalOptions} from 'ngx-bootstrap';
import {Subject} from 'rxjs';
import {ConfirmLeaveComponent} from '../confirm-leave/confirm-leave.component';


@Component({
  selector: 'app-suspects',
  templateUrl: './suspects.component.html',
  styleUrls: ['./suspects.component.css']
})
export class SuspectsComponent implements OnInit {
  suspects: Party[];
  visibleTabs: string[];
  faTrash = faTrash;
  canNavigateWithoutConfirm  = false;
  idxString: string;
  isIndustry: boolean;

  constructor(private commonService: CommonService, private router: Router, private modalService: BsModalService) {
    this.visibleTabs = this.commonService.getAvailableTabs().slice();
    this.suspects = this.commonService.getOfrsReport().suspects;
    this.isIndustry = this.commonService.getOfrsReport().ofrsReportKey.reportType === 'I';
  }
  ngOnInit() {
    if (this.visibleTabs.indexOf('suspectsNav') === -1) {
      this.visibleTabs.push('suspectsNav');
    }
  }

  startNewSuspect(): void {
    this.router.navigate(['suspects/suspect']);
  }
  next(): void {
    this.router.navigate([ this.isIndustry ? 'investigation-summary' : 'fraud-description']);
  }
  displaySuspectName(entityType: string, first: string, middle: string, last: string, business: string): string {
    // if (business && business !== null && business.length > 0) {
    //   return business;
    // }
    // const showMiddle = middle && middle !== null && middle.length > 0;
    // if (showMiddle) {
    //   return `${first} ${middle} ${last}`;
    // }
    // return `${first} ${last}`;
    const displayName = this.commonService.displayName(entityType, first, middle, last, business);
    return displayName.trim().length > 0 ? displayName.trim() : 'Unnamed Suspect';
  }
  removeSuspect(idx: number) {
    const mos = new ModalOptions();
    mos.initialState = {
      title: 'Are you sure?',
      msg: 'Do you really want to remove the suspect?',
      yesBtnName: 'Yes',
      noBtnName: 'No'
    };
    const subject = new Subject<boolean>();
    const modal = this.modalService.show(ConfirmLeaveComponent,  mos);
    modal.content.subject = subject;
    modal.content.subject.asObservable().subscribe(value1 => {
      if (value1 === true) {
        this.canNavigateWithoutConfirm = true;
        this.suspects.splice(idx, 1);
        this.commonService.getOfrsReport().suspects = this.suspects;
      } else {
        return;
      }}
    );
  }
}
