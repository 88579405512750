<div class="row">
  <div class="col-2">
    <!--app-navigation [visibleItems]="visibleTabs"></app-navigation-->
  </div>
  <div class="col-10">
    <div id="errorMsg" *ngIf="errorMessage && errorMessage.length > 0" class="alert alert-danger">{{ errorMessage }}</div>

        <form [formGroup]="formGroup">
          <div class="form-group row">
            <label for="reportId" class="col-sm-4 col-form-label text-right">NAIC Fraud ID: </label>
            <div class="col" class="col-sm-3">
              <input type="text"
                     title="Report Id"
                     id="reportId"
                     name="reportId"
                     formControlName="reportId"
                     class="form-control"/>
            </div>
            <div class="col" class="col-sm-5">
              <div *ngIf="reportId.invalid && (reportId.dirty || reportId.touched)" class="alert alert-danger">
                <div *ngIf="reportId.errors">
                  <div id="reportIdError"  *ngFor="let error of errors(reportId)">{{ error }}</div>
                </div>
              </div>
            </div>

          </div>
          <div class="form-group row">
            <label for="suspectLastName" class="col-sm-4 col-form-label text-right">Subject Involved Party Last Name: </label>
            <div class="col" class="col-sm-3">
              <input type="text"
                     title="Suspect Last Name"
                     id="suspectLastName"
                     name="suspectLastName"
                     formControlName="suspectLastName"
                     class="form-control"/>
              <div *ngIf="suspectLastName.invalid && (suspectLastName.dirty || suspectLastName.touched)" class="alert alert-danger">
                <div *ngIf="suspectLastName.errors">
                  <div id="suspectLastNameError" *ngFor="let error of errors(suspectLastName)">{{ error }}</div>
                </div>
              </div>
            </div>
            <label for="policyNumber" class="col-sm-2 col-form-label text-right">Policy Number: </label>
            <div class="col" class="col-sm-3">
              <input type="text"
                     title="policy Number"
                     id="policyNumber"
                     name="policyNumber"
                     formControlName="policyNumber"
                     class="form-control"/>
              <div *ngIf="policyNumber.invalid && (policyNumber.dirty || policyNumber.touched)" class="alert alert-danger">
                <div *ngIf="policyNumber.errors">
                  <div id="policyNumberError" *ngFor="let error of errors(policyNumber)">{{ error }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label for="reporterLastName" class="col-sm-4 col-form-label text-right">Reporting Person Last Name: </label>
            <div class="col" class="col-sm-3">
              <input type="text"
                     title="Reporter Last Name"
                     id="reporterLastName"
                     name="reporterLastName"
                     formControlName="reporterLastName"
                     class="form-control"/>
              <div *ngIf="reporterLastName.invalid && (reporterLastName.dirty || reporterLastName.touched)" class="alert alert-danger">
                <div *ngIf="reporterLastName.errors">
                  <div id="reporterLastNameError" *ngFor="let error of errors(reporterLastName)">{{ error }}</div>
                </div>
              </div>
            </div>
            <label for="claimNumber" class="col-sm-2 col-form-label text-right">Claim Number: </label>
            <div class="col" class="col-sm-3">
              <input type="text"
                     title="Claim Number"
                     id="claimNumber"
                     name="claimNumber"
                     formControlName="claimNumber"
                     class="form-control"/>
              <div *ngIf="claimNumber.invalid && (claimNumber.dirty || claimNumber.touched)" class="alert alert-danger">
                <div *ngIf="claimNumber.errors">
                  <div id="claimNumberError" *ngFor="let error of errors(claimNumber)">{{ error }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label for="submitDateFrom" class="col-sm-4 col-form-label text-right">Submission Date Range:</label>
            <div class="col-sm-3">
              <input type="text"
                     id="submitDateFrom"
                     name="submitDateFrom"
                     formControlName="submitDateFrom"
                     #dpfrom="bsDatepicker"
                     class="form-control"
                     placeholder="MM/DD/YYYY"
                     bsDatepicker
                     placement="bottom"
                     [bsConfig]="bsConfig">
              <div *ngIf="submitDateFrom.invalid && (submitDateFrom.dirty || submitDateFrom.touched)" class="alert alert-danger">
                <div *ngIf="submitDateFrom.errors">
                  <div id="submitDateFromError" *ngFor="let error of errors(submitDateFrom)">{{ error }}</div>
                </div>
              </div>
            </div>
            <div class="col-sm-1" style="padding-left: 0">
              <button type="button" class="btn btn-secondary" (click)="dpfrom.toggle()" [attr.aria-expanded]="dpfrom.isOpen">
                <fa-icon [icon]="faCalendar"></fa-icon>
              </button>
            </div>
            <div class="col-sm-3">
              <input type="text"
                     id="submitDateTo"
                     name="submitDateTo"
                     formControlName="submitDateTo"
                     #dpto="bsDatepicker"
                     class="form-control"
                     placeholder="MM/DD/YYYY"
                     bsDatepicker
                     placement="bottom"
                     [bsConfig]="bsConfig">
              <div *ngIf="submitDateTo.invalid && (submitDateTo.dirty || submitDateTo.touched)" class="alert alert-danger">
                <div *ngIf="submitDateTo.errors">
                  <div id="submitDateToError" *ngFor="let error of errors(submitDateTo)">{{ error }}</div>
                </div>
              </div>
            </div>
            <div class="col-sm-1" style="padding-left: 0">
              <button type="button" class="btn btn-secondary" (click)="dpto.toggle()" [attr.aria-expanded]="dpto.isOpen">
                <fa-icon [icon]="faCalendar"></fa-icon>
              </button>
            </div>
          </div>
          <div  class="form-group row" *ngIf="formGroup.errors?.beingEndDates && !submitDateFrom.errors && !submitDateTo.errors && (formGroup.touched || formGroup.dirty)" id="serviceDateError">
            <div class="col-sm-4"></div>
            <div id="submitDateRangeError" class="col-sm-8 alert alert-danger">'From' date cannot be after 'To' date</div>
          </div>

          <div class="form-group row">
            <div class="col-sm-4"></div>
            <div class="col-sm-2 text-left">
              <button type="button" id="searchButton" [disabled]="hasError() || !canBeSubmitted()" (click)="search(formGroup.value)" class="btn btn-success .navbar-right" >Search</button>
            </div>
            <div class="col-sm-1">
            </div>
            <div class="col-sm-2 text-right">
              <button type="button" id="cancelButton" (click)="cancel()" class="btn btn-success .navbar-right" >Cancel</button>
            </div>
            <div class="col-sm-3"></div>
          </div>
          <div id="noReportsId" *ngIf="reports && reports.length == 0" class="form-group row"><p>Reports Not Found</p></div>
          <div id="searchResults" *ngIf="reports && reports.length > 0" class="form-group row">
            <p>Reports Found:</p>

            <table id="reports">
              <tr>
                <th *ngFor="let header of headers">
                  {{header}}
                </th>
              </tr>
              <tr *ngFor="let reportInfo of reports; last as isLast">
                <td >
                  <a class="text-primary" (click)="downloadPDF(reportInfo.reportId, reportInfo.transactionId)">{{reportInfo.reportId}}</a>
                </td>
                <td >
                  {{displaySuspectName(reportInfo.suspectEntityType, reportInfo.suspectFirstName, reportInfo.suspectMiddleName, reportInfo.suspectLastName, reportInfo.suspectBusiness)}}
                </td>
                <td >
                  {{reportInfo.policyNumber}}
                </td>
                <td >
                  {{reportInfo.claimNumber}}
                </td>
                <td >
                  {{reportInfo.reporterFirstName}}  {{reportInfo.reporterMiddleName}} {{reportInfo.reporterLastName}}
                </td>
                <td >
                  {{reportInfo.submitDateTime | date: 'short'}}
                </td>
                <td *ngIf="!reportInfo.reportCurrent" ></td>
                <td *ngIf="reportInfo.reportCurrent">
                  <a class="text-primary" (click)="modify(reportInfo.transactionId)">Modify</a>
                </td>
              </tr>
            </table>
          </div>
        </form>
  </div>
</div>

