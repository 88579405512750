import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {CanDeactivate} from '@angular/router';
import {Subject} from 'rxjs';
import {BsModalService, ModalOptions} from 'ngx-bootstrap';
import {ConfirmLeaveComponent} from '../../confirm-leave/confirm-leave.component';

export interface CanComponentDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {

  constructor(private modalService: BsModalService) {}
    canDeactivate(component: CanComponentDeactivate): Observable<boolean> | Promise<boolean> | boolean {
      let canDeactivateAtComponent: Observable<boolean> | Promise<boolean> | boolean = false;
      if (component.canDeactivate) {
        canDeactivateAtComponent = component.canDeactivate();
      }
      if (canDeactivateAtComponent) {
        return true;
      }
      const mos = new ModalOptions();
      mos.initialState = {
        title: 'Are you sure?',
        msg: 'The form has not been saved yet, do you really want to leave page?',
        yesBtnName: 'Yes',
        noBtnName: 'No'
      };
      const subject = new Subject<boolean>();
      const modal = this.modalService.show(ConfirmLeaveComponent, mos);
      modal.content.subject = subject;
      return subject.asObservable();
    }
}
