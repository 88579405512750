<div class="modal-header modal-block-primary">
  <h4 class="modal-title pull-left">{{title}}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body clearfix">
  <div class="modal-text">
    <p>{{msg}}</p>
  </div>
</div>
<div class="modal-footer">
  <button id="actionNoBtn" class="btn btn-secondary" (click)="action(false)">{{noBtnName}}</button>
  <button id="actionYesBtn" class="btn btn-primary right" (click)="action(true)">{{yesBtnName}}</button>
</div>
