import {Component} from '@angular/core';
import {OfrsUserService} from './shared/ofrs-user.service';
import {OAuthService} from 'angular-oauth2-oidc';
import {environment} from '../environments/environment';
import {TimeoutComponent} from './timeout/timeout.component';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {timer} from 'rxjs';
import {Router} from '@angular/router';
import {AuthorizationGuard} from './shared/guard/authorization.guard';
import {name, version, buildDate} from '../environments/version';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  readonly aboutDialog = [{
    name: name,
    version: version,
    buildDate: buildDate
  }];

  title = 'OFRS';
  environmentName = environment.name;
  timeoutWarningTime = 6300000; // 30;
  maxBeforeLogout = 7080000; // 480;
  loggedIn = false;
  modalRef: BsModalRef;
  config = {
    backdrop: true,
    ignoreBackdropClick: true
  };


  constructor(private router: Router,
              private oauthService: OAuthService,
              private modalService: BsModalService,
              public userService: OfrsUserService,
              public authorizationGuard: AuthorizationGuard) { }

  getName(): any {
    return { name: this.userService ? this.userService.user ? this.userService.user.firstName : null : null} ;
  }
  private getEvents(): void {
    let contractCount = 0;
    const contractTimer = timer(1000, 1000);
    contractTimer.subscribe(
      value => {
        contractCount = value;
        if (contractCount === this.timeoutWarningTime) {
          this.modalRef = this.modalService.show(TimeoutComponent, this.config);
        }
        if (contractCount === this.maxBeforeLogout) {
          this.oauthService.logOut();
        }
      }
    );
  }
}

