import { Address } from './address.model';
import {Service} from './service.model';
import {Procedure} from './procedure.model';
import {Dates} from './util/dates.util';

export class ClaimInformation {

  insuranceTypeId: number;
  claimStatus: string;
  policyNumber: string;
  claimNumber: string;
  companyCaseNumber: string;
  civilLitigationPending: string;
  lossDate: Date;
  approxLossDate: string;
  location: Address;
  services: Service[];
  procedures: Procedure[];
  approxReserveAmt: number;
  billedAmt: number;
  suspectedFraudAmt: number;
  approxPaidAmt: number;
  paidDate: Date;
  approxSettleAmt: number;
  settlePaidDate: Date;
  lossRangeId: number;

  constructor(obj?: any) {
    obj = obj || {};
    this.insuranceTypeId = obj.insuranceTypeId || '';
    this.claimStatus = obj.claimStatus || '';
    this.policyNumber = obj.policyNumber || '';
    this.claimNumber = obj.claimNumber || '';
    this.companyCaseNumber = obj.companyCaseNumber || '';
    this.civilLitigationPending = obj.civilLitigationPending || '';
    this.lossDate = Dates.parse(obj.lossDate);
    this.approxLossDate = obj.approxLossDate || '';
    obj.location = obj.location || {};
    this.location = new Address(
      obj.location.addressType,
      obj.location.addressTypeOtherDesc,
      obj.location.address1,
      obj.location.address2,
      obj.location.city,
      obj.location.county,
      obj.location.state,
      obj.location.zip);
    this.services = Array.isArray(obj.services) && obj.services
      .filter(v => v != null).map(v => new Service(v)) || [];
    this.procedures = Array.isArray(obj.procedures) && obj.procedures
      .filter(v => v != null).map(v => new Procedure(v)) || [];
    this.approxReserveAmt = obj.approxReserveAmt || null;
    this.billedAmt = obj.billedAmt || null;
    this.suspectedFraudAmt = obj.suspectedFraudAmt || null;
    this.approxPaidAmt = obj.approxPaidAmt || null;
    this.paidDate = Dates.parse(obj.paidDate);
    this.approxSettleAmt = obj.approxSettleAmt || null;
    this.settlePaidDate = Dates.parse(obj.settlePaidDate);
    this.lossRangeId = obj.lossRangeId || null;
    if (this.location.address1) {
      this.location.addressType = 'None';
    }
  }

  toJSON() {
    return Object.assign({}, this, {
      lossDate: Dates.format(this.lossDate),
      paidDate: Dates.format(this.paidDate),
      settlePaidDate: Dates.format(this.settlePaidDate)
    });
  }
}
