import { FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export const atLeastOnePhoneValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  const homePhone = control.get('homePhone');
  const cellPhone = control.get('cellPhone');
  const busPhone = control.get('busPhone');
  const isEmpty = v => v == null || v.value == null || v.value.length === 0;
  const noPhone = isEmpty(homePhone) && isEmpty(cellPhone) && isEmpty(busPhone);
  return noPhone ? { 'atLeastOnePhone': true} : null;
};
