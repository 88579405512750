<div class="row">
  <div class="col-2">
    <div class="row">
      <app-navigation [visibleItems]="visibleTabs"></app-navigation>
    </div>
  </div>
  <div class="col-10 pl-sm-5">
    <h2>Alias Details</h2>
    <p class="text-center"> At least one field is required to save page</p>
    <form [formGroup]="detailForm" >
      <accordion>
        <accordion-group (isOpenChange)="akaInfoCollapseFlag = !akaInfoCollapseFlag">
              <span accordion-heading id="akaSpan">
                  <fa-icon [icon]="akaInfoCollapseFlag ? faPlus : faMinus"></fa-icon>&nbsp;Alias Information
                </span>
      <div class="form-group row">
        <div class="col-sm-11 ml-auto">
          <div *ngIf="!isEntityTypePerson" class="form-group row">
            <label for="businessName" class="col-form-label col-sm-3 text-right">Business Name:</label>
            <div class="col-sm-9">
              <input type="text" title="Business Name" [formControl]="detailForm.controls['businessName']" class="form-control" id="businessName" name="businessName" />
              <div *ngIf="formErrors.businessName" class="alert alert-danger">
                {{formErrors.businessName}}
              </div>
            </div>
          </div>

          <div *ngIf="isEntityTypePerson" class="form-group row">
            <label for="firstName" class="col-form-label col-sm-3 text-right">First Name:</label>
            <div class="col-sm-9">
              <input type="text" title="First Name" [formControl]="detailForm.controls['firstName']"  class="form-control" id="firstName" name="firstName" />
              <div *ngIf="formErrors.firstName" class="alert alert-danger">
                {{formErrors.firstName}}
              </div>
            </div>
          </div>
          <div *ngIf="isEntityTypePerson" class="form-group row">
            <label for="middleName" class="col-form-label col-sm-3 text-right">Middle Name:</label>
            <div class="col-sm-9">
              <input type="text" title="Middle Name" [formControl]="detailForm.controls['middleName']"  class="form-control" id="middleName" name="middleName" />
              <div *ngIf="formErrors.middleName" class="alert alert-danger">
                {{formErrors.middleName}}
              </div>
            </div>
          </div>

          <div *ngIf="isEntityTypePerson" class="form-group row">
            <label for="lastName" class="col-form-label col-sm-3 text-right">Last Name:</label>
            <div class="col-sm-9">
              <input type="text" title="Last Name" [formControl]="detailForm.controls['lastName']"  class="form-control" id="lastName" name="lastName" />
              <div *ngIf="formErrors.lastName" class="alert alert-danger">
                {{formErrors.lastName}}
              </div>
            </div>
          </div>

          <div *ngIf="isEntityTypePerson" class="form-group row">
            <label class="col-form-label col-sm-3 text-right">Gender:</label>
            <div class="col-sm-9">
              <input id="gMale" type="radio" value="M" formControlName="gender"> Male
              <input id="gFemale" type="radio" value="F" formControlName="gender"> Female
              <input id="gUnknown" type="radio" value="U" formControlName="gender"> Unknown
            </div>
          </div>

          <div *ngIf="isEntityTypePerson" class="form-group row">
            <label for="ssn" class="col-form-label col-sm-3 text-right">SSN:</label>
            <div class="col-sm-9">
              <input type="text" title="Social Security Number" [formControl]="detailForm.controls['ssn']" (input)="formatSsn()" placeholder="XXX-XX-XXXX" class="form-control" id="ssn" name="ssn" />
              <div *ngIf="formErrors.ssn" class="alert alert-danger">
                {{formErrors.ssn}}
              </div>
            </div>
          </div>

          <div *ngIf="isEntityTypePerson" class="form-group row">
            <label for="birthDate" class="col-form-label col-sm-3 text-right">Date of Birth:</label>
            <div class="col-sm-7">
              <input id="birthDate" type="text" class="form-control mb-3" [minDate]="minDate" [maxDate]="maxDate" [bsConfig]="bsConfig" placeholder="MM/DD/YYYY" bsDatepicker formControlName="birthDate" #d="bsDatepicker" triggers=""/>
              <pre *ngIf="detailForm.value.birthDate" class="code-preview">{{detailForm.value.birthDate | date}}</pre>
            </div>
            <div class="col-sm-2" style="padding-left: 0">
              <button type="button" class="btn btn-secondary" (click)="d.toggle()"  [attr.aria-expanded]="d.isOpen">
                <fa-icon [icon]="faCalendar"></fa-icon>
              </button>
            </div>
          </div>

          <div *ngIf="isEntityTypePerson && isIndustry" class="form-group row">
            <label for="driverLicenseNumber" class="col-form-label col-sm-3 text-right">Driver License Number:</label>
            <div class="col-sm-4">
              <input type="text" title="Driver License Number" [formControl]="detailForm.controls['driverLicenseNumber']"  class="form-control" id="driverLicenseNumber" #driverLicenseNumber name="driverLicenseNumber" />
              <div *ngIf="formErrors.driverLicenseNumber" class="alert alert-danger">
                {{formErrors.driverLicenseNumber}}
              </div>
            </div>
            <div>
              <!--<input title="Driver License State" [formControl]="detailForm.controls['driverLic ']"  class="form-control" id="driverLicState" name="driverLicState" />-->
              <select title="Driver License State" [required]="driverLicenseNumber.value.length>0" [formControl]="detailForm.controls['driverLicenseState']" class="form-control" id="driverLicenseState" name="driverLicenseState">
                <option [ngValue]='' disabled hidden>Choose One</option>
                <option *ngFor="let juris of jurisdictions" [value]="juris.jurisdictionId">{{juris.jurisdictionId}}</option>
              </select>
            </div>
          </div>

          <div *ngIf="isEntityTypePerson" class="form-group row">
            <label for="occupation" class="col-form-label col-sm-3 text-right">Occupation:</label>
            <div class="col-sm-9">
              <input type="text" title="Occupation" [formControl]="detailForm.controls['occupation']"  class="form-control" id="occupation" name="occupation" />
              <div *ngIf="formErrors.occupation" class="alert alert-danger">
                {{formErrors.occupation}}
              </div>
            </div>
          </div>

          <div *ngIf="isEntityTypePerson && isIndustry" class="form-group row">
            <label for="tin" class="col-form-label col-sm-3 text-right">TIN:</label>
            <div class="col-sm-9">
              <input type="text" title="TIN" [formControl]="detailForm.controls['tin']"  class="form-control" id="tin" name="tin" />
              <div *ngIf="formErrors.tin" class="alert alert-danger">
                {{formErrors.tin}}
              </div>
            </div>
          </div>

          <div *ngIf="isEntityTypePerson && isIndustry" class="form-group row">
            <label for="ein" class="col-form-label col-sm-3 text-right">EIN:</label>
            <div class="col-sm-9">
              <input type="text" title="EIN" [formControl]="detailForm.controls['ein']"  class="form-control" id="ein" name="ein" />
              <div *ngIf="formErrors.ein" class="alert alert-danger">
                {{formErrors.ein}}
              </div>
            </div>
          </div>

          <div *ngIf="isEntityTypePerson && isIndustry" class="form-group row">
            <label for="npi" class="col-form-label col-sm-3 text-right">NPI:</label>
            <div class="col-sm-9">
              <input type="text" title="NPI" [formControl]="detailForm.controls['npi']"  class="form-control" id="npi" name="npi" />
              <div *ngIf="formErrors.npi" class="alert alert-danger">
                {{formErrors.npi}}
              </div>
            </div>
          </div>

          <app-address
            [formGroup]="detailForm"
            [errorMessages]="validationService.getValidationMessages()"
            [addressDecodes]="addressDecodes"
            [jurisdictions]="jurisdictions">
          </app-address>
          <fieldset>
            <legend>Contact Info</legend>
            <app-contact-electronic-info
              [formGroup]="detailForm"
              [errorMessages]="validationService.getValidationMessages()">
            </app-contact-electronic-info>
          </fieldset>
        </div>
      </div>
      </accordion-group>
      <!--employer section-->
          <accordion-group *ngIf="isEntityTypePerson && isIndustry" (isOpenChange)="employerInfoCollapseFlag = !employerInfoCollapseFlag">
              <span accordion-heading id="employerSpan">
                  <fa-icon [icon]="employerInfoCollapseFlag ? faPlus : faMinus"></fa-icon>&nbsp;Alias Employer Information
                </span>
      <div class="form-group row">
        <div class="col-sm-11 ml-auto">
          <div class="form-group row">
            <label for="employerName" class="col-form-label col-sm-3 text-right">Employer Name:</label>
            <div class="col-sm-9">
              <input type="text" title="Employer Name" [formControl]="detailForm.controls['employerName']"  class="form-control" id="employerName" name="employerName" />
              <div *ngIf="formErrors.employerName" class="alert alert-danger">
                {{formErrors.employerName}}
              </div>
            </div>
          </div>

          <app-address
            [isAddressTypeHidden]=true
            [prefix]="'employer'"
            [formGroup]="detailForm"
            [errorMessages]="validationService.getValidationMessages()"
            [addressDecodes]="addressDecodes"
            [jurisdictions]="jurisdictions">
          </app-address>

          <div class="form-group row">
            <label for="employerPhone" class="col-form-label col-sm-3 text-right">Business Phone:</label>
            <div class="col-sm-4">
              <input type="text" title="Employer Phone" [formControl]="detailForm.controls['employerPhone']"  class="form-control" id="employerPhone" name="employerPhone" />
              <div *ngIf="formErrors.employerPhone" class="alert alert-danger">
                {{formErrors.employerPhone}}
              </div>
            </div>
            <label for="employerPhoneExt" class="col-form-label col-sm-1 text-right" style="padding: 0">Ext:</label>
            <div class="col-sm-4">
              <input type="text" title="Employer Phone Extention" [formControl]="detailForm.controls['employerPhoneExt']"  class="form-control" id="employerPhoneExt" name="employerPhoneExt" />
              <div *ngIf="formErrors.employerPhoneExt" class="alert alert-danger">
                {{formErrors.employerPhoneExt}}
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label for="employerFax" class="col-form-label col-sm-3 text-right">Fax Number:</label>
            <div class="col-sm-9">
              <input type="text" title="Employer Fax" [formControl]="detailForm.controls['employerFax']"  class="form-control" id="employerFax" name="employerFax" />
              <div *ngIf="formErrors.employerFax" class="alert alert-danger">
                {{formErrors.employerFax}}
              </div>
            </div>
          </div>
        </div>
      </div>
          </accordion-group>

      <!--vehicle section-->
        <accordion-group (isOpenChange)="vehicleInfoCollapseFlag = !vehicleInfoCollapseFlag" *ngIf="isIndustry">
              <span accordion-heading id="vehicleSpan">
                  <fa-icon [icon]="vehicleInfoCollapseFlag ? faPlus : faMinus"></fa-icon>&nbsp;Vehicle Information
                </span>
      <div class="form-group row">
        <div class="col-sm-11 ml-auto">
          <div class="form-group row">
            <label for="vehicleYear" class="col-form-label col-sm-3 text-right">Vehicle Year:</label>
            <div class="col-sm-9">
              <input type="text" title="Vehicle Year" [formControl]="detailForm.controls['vehicleYear']"  class="form-control" id="vehicleYear" name="vehicleYear" />
              <div *ngIf="formErrors.vehicleYear" class="alert alert-danger">
                {{formErrors.vehicleYear}}
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label for="vehicleMake" class="col-form-label col-sm-3 text-right">Vehicle Make:</label>
            <div class="col-sm-9">
              <input type="text" title="Vehicle Make" [formControl]="detailForm.controls['vehicleMake']"  class="form-control" id="vehicleMake" name="vehicleMake" />
              <div *ngIf="formErrors.vehicleMake" class="alert alert-danger">
                {{formErrors.vehicleMake}}
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label for="vehicleModel" class="col-form-label col-sm-3 text-right">Vehicle Model:</label>
            <div class="col-sm-9">
              <input type="text" title="Vehicle Model" [formControl]="detailForm.controls['vehicleModel']"  class="form-control" id="vehicleModel" name="vehicleModel" />
              <div *ngIf="formErrors.vehicleModel" class="alert alert-danger">
                {{formErrors.vehicleModel}}
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label for="vehicleVin" class="col-form-label col-sm-3 text-right">VIN:</label>
            <div class="col-sm-9">
              <input type="text" title="VIN" [formControl]="detailForm.controls['vehicleVin']"  class="form-control" id="vehicleVin" name="vehicleVin" />
              <div *ngIf="formErrors.vehicleVin" class="alert alert-danger">
                {{formErrors.vehicleVin}}
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label for="vehicleLicPlate" class="col-form-label col-sm-3 text-right">License Plate:</label>
            <div class="col-sm-5">
              <input type="text" title="License Plate" [formControl]="detailForm.controls['vehicleLicPlate']"  class="form-control" id="vehicleLicPlate" name="vehicleLicPlate" />
              <div *ngIf="formErrors.vehicleLicPlate" class="alert alert-danger">
                {{formErrors.vehicleLicPlate}}
              </div>
            </div>
            <div class="col-sm-4">
              <select title="Vehicle License State"  [formControl]="detailForm.controls['vehicleLicState']"   class="form-control" id="vehicleLicState" name="vehicleLicState">
                <option [ngValue]='' disabled hidden>Choose One</option>
                <option *ngFor="let juris of jurisdictions" [value]="juris.jurisdictionId">{{juris.jurisdictionId}}</option>
              </select>
              <div *ngIf="formErrors.vehicleLicState" class="alert alert-danger">
                {{formErrors.vehicleLicState}}
              </div>
            </div>
          </div>
        </div>
      </div>
        </accordion-group>
      </accordion>
      <!--action section-->
      <div class="alert alert-danger row" *ngIf="errorMessage">{{errorMessage}}</div>
      <br/>
      <div class="form-group row">
        <div class="col-sm-3 text-right">
          <button type="button" id="CancelAkaButton" (click)="cancelAka(detailForm.value)" class="btn btn-success .navbar-right"> Cancel </button>
        </div>
        <div *ngIf="akaIdxString === null" class="col-sm-3 text-right">
          <button type="button" id="addAkaButton" (click)="addAka(detailForm.value)" class="btn btn-success .navbar-right" [disabled]="notPopulated || !detailForm.valid"> Add </button>
        </div>
        <div *ngIf="akaIdxString" class="col-sm-3 text-right">
          <button type="button" id="updateAkaButton" (click)="addAka(detailForm.value)" class="btn btn-success .navbar-right" [disabled]="notPopulated || !detailForm.valid"> Update </button>
        </div>
        <div *ngIf="akaIdxString" class="col-sm-3 text-right">
          <button type="button" id="removeAkaButton" (click)="removeAka(detailForm.value)" class="btn btn-danger .navbar-right"> Remove </button>
        </div>
      </div>
    </form>
  </div>
</div>
