
export class Address {
    // the address Type is the address code name, not the address code
    public addressType: string;
    public addressTypeOtherDesc: string;
    public address1: string;
    public address2: string;
    public city: string;
    public county: string;
    public state: string;
    public zip: string;

    constructor(addressType?: string, addressTypeOtherDesc?: string, address1?: string, address2?: string, city?: string, county?: string, state?: string,
                zip?: string) {
        this.addressType = addressType || '';
        this.addressTypeOtherDesc = addressTypeOtherDesc || '';
        this.address1 = address1 || '';
        this.address2 = address2 || '';
        this.city = city || '';
        this.county = county || '';
        this.state = state || '';
        this.zip = zip || '';
    }
}
