import {cloneDeep} from 'lodash';

export class EvidenceTypes {
  isNone: boolean;
  evidenceTypeIds: number[];
  evidenceOtherDesc: string;

  constructor(obj?: any) {
    Object.assign(this, cloneDeep(obj));
    this.isNone = obj && (obj.evidenceTypeIds == null || obj.evidenceTypeIds.length === 0) ? true : false;
    this.evidenceTypeIds = this.evidenceTypeIds || [];
    this.evidenceOtherDesc = this.evidenceOtherDesc || '';
  }

}

