import {Address} from './address.model';

export class Employer {
  name: string;
  address: Address;
  busPhone: string;
  busPhoneExt: string;
  fax: string;
  constructor() {
    this.name = '';
    this.address = null;
    this.busPhone = '';
    this.busPhoneExt = '';
    this.fax = '';
  }
}
