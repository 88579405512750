<div [formGroup]="formGroup">
  <div class="form-group row">
    <label for="{{getId('email')}}" class="col-form-label col-sm-3 text-right">Email Address: <span *ngIf="isRequired('email')" class="red-text">*</span></label>
    <div class="col-sm-9">
      <input type="text" class="form-control" id="{{getId('email')}}" name="{{getId('email')}}" placeholder="Enter Email..." formControlName="email" [required]="isRequired('email')">
      <div *ngIf="email.invalid && (email.dirty || email.touched)" class="alert alert-danger">
        <ng-container *ngIf="email.errors.email">
          {{getErrorMessage('email', 'email')}}
        </ng-container>
        <ng-container *ngIf="email.errors.required">
          {{getErrorMessage('email', 'required')}}
        </ng-container>
        <ng-container *ngIf="email.errors.maxlength">
          {{getErrorMessage('email', 'maxlength')}}
        </ng-container>
      </div>
    </div>
  </div>

  <div class="form-group row">
    <label for="{{getId('homePhone')}}" class="col-form-label col-sm-3 text-right">Home Phone: <span *ngIf="isRequired('homePhone')" class="red-text">*</span></label>
    <div class="col-sm-9">
      <input type="text" class="form-control" id="{{getId('homePhone')}}" name="{{getId('homePhone')}}" placeholder="(555) 555-5555" formControlName="homePhone" [required]="isRequired('homePhone')">
      <div *ngIf="homePhone.invalid && (homePhone.dirty || homePhone.touched)" class="alert alert-danger">
        <ng-container *ngIf="homePhone.errors.pattern">
          {{getErrorMessage('homePhone', 'pattern')}}
        </ng-container>
      </div></div>
  </div>

  <div class="form-group row">
    <label for="{{getId('cellPhone')}}" class="col-form-label col-sm-3 text-right">Mobile Phone: <span *ngIf="isRequired('cellPhone')" class="red-text">*</span></label>
    <div class="col-sm-9">
      <input type="text" class="form-control" id="{{getId('cellPhone')}}" name="{{getId('cellPhone')}}" placeholder="(555) 555-5555" formControlName="cellPhone" [required]="isRequired('cellPhone')">
      <div *ngIf="cellPhone.invalid && (cellPhone.dirty || cellPhone.touched)" class="alert alert-danger">
        <ng-container *ngIf="cellPhone.errors.pattern">
          {{getErrorMessage('cellPhone', 'pattern')}}
        </ng-container>
      </div></div>
  </div>

  <div class="form-group row">
    <label for="{{getId('busPhone')}}" class="col-form-label col-sm-3 text-right">Business Phone: <span *ngIf="isRequired('busPhone')" class="red-text">*</span></label>
    <div class="col-sm-4">
      <input type="text" class="form-control" id="{{getId('busPhone')}}" name="{{getId('busPhone')}}" placeholder="(555) 555-5555" formControlName="busPhone" [required]="isRequired('busPhone')">
      <div *ngIf="busPhone.invalid && (busPhone.dirty || busPhone.touched)" class="alert alert-danger">
        <ng-container *ngIf="busPhone.errors.pattern">
          {{getErrorMessage('busPhone', 'pattern')}}
        </ng-container>
      </div>
    </div>

    <label for="{{getId('busPhoneExt')}}" class="col-form-label col-sm-1 text-right">Ext: <span *ngIf="isRequired('busPhoneExt')" class="red-text">*</span></label>
    <div class="col-sm-4">
      <input type="text" class="form-control" id="{{getId('busPhoneExt')}}" name="{{getId('busPhoneExt')}}" placeholder="555" formControlName="busPhoneExt" [required]="isRequired('busPhoneExt')">
      <div *ngIf="busPhoneExt.invalid && (busPhoneExt.dirty || busPhoneExt.touched)" class="alert alert-danger">
        <ng-container *ngIf="busPhoneExt.errors.NaN">
          {{getErrorMessage('busPhoneExt', 'NaN')}}
        </ng-container>
        <ng-container *ngIf="busPhoneExt.errors.whitespace">
          {{getErrorMessage('busPhoneExt', 'whitespace')}}
        </ng-container>
        <ng-container *ngIf="busPhoneExt.errors.maxlength">
          {{getErrorMessage('busPhoneExt', 'maxlength')}}
        </ng-container>
      </div>
    </div>
  </div>


  <div class="form-group row">
    <label for="{{getId('fax')}}" class="col-form-label col-sm-3 text-right">Fax Number: <span *ngIf="isRequired('fax')" class="red-text">*</span></label>
    <div class="col-sm-9">
      <input type="text" class="form-control" id="{{getId('fax')}}" name="{{getId('fax')}}" placeholder="(555) 555-5555" formControlName="fax" [required]="isRequired('fax')">
      <div *ngIf="fax.invalid && (fax.dirty || fax.touched)" class="alert alert-danger">
        <ng-container *ngIf="fax.errors.pattern">
          {{getErrorMessage('fax', 'pattern')}}
        </ng-container>
      </div>
    </div>
  </div>
  <div *ngIf="formGroup.errors?.atLeastOnePhone && (formGroup.touched || formGroup.dirty)" class="alert alert-danger">
    Please enter at least one phone number.
  </div>
</div>
