import {cloneDeep} from 'lodash';

export class FraudTypes {

  isNone: boolean;
  fraudTypeIds: number[];
  propertyCasualtyOther: string;
  healthCareOther: string;
  generalOther: string;

  constructor(obj?: any) {
    Object.assign(this, cloneDeep(obj));
    this.isNone = obj && (obj.fraudTypeIds == null || obj.fraudTypeIds.length === 0) ? true : false;
    this.fraudTypeIds = this.fraudTypeIds || [];
    this.propertyCasualtyOther = this.propertyCasualtyOther || '';
    this.healthCareOther = this.healthCareOther || '';
    this.generalOther = this.generalOther || '';
  }

}
